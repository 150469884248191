import CustomTextBox from "app/components/atoms/customTextBox";
import CustomDate from "app/components/atoms/customDate";
import CustomRadio from "app/components/atoms/customRadio";
import CustomSelect from "app/components/atoms/customSelect";
import MultipleSelect from "../atoms/multiDropdown/MultipleSelect.mui";

export const memershipStatusUiSchema = {
  "ui:title": "",
  ctpt_membershipnumber: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_category: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  statecode: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_onhold: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_onholdreason: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_datejoined: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  }
};


