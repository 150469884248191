import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContactEntitlePayload } from "app/services/payloads";
import { get, postEntitle, getEntitle } from "app/services/api";
import {
  routerMapping,
  routerMappingOG,
  entitlementList,
} from "../data/entitlementMap";
import { config } from "utilities/config";
import _ from "lodash";
import { usePreLoader } from "app/services/hooks/usePreLoader";
import { post } from "app/services/api";

export const UserContext = React.createContext();

export function UserProvider({ children }) {
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const [allEntitlement, setAllEntitlement] = useState([]);
  const [userRole, setuserRole] = useState([]);
  const [permissionSet, setPermissionSet] = useState([]);
  var entitleRes;
  const ue = useSelector((state) => state.entitleMents);
  const apiContactEntitleURL = config.entitlement.entitle.getContactEntitle;
  const apiGetProfileId = config.teleportal.profile.get;
  const apiEntitleURL = config.entitlement.entitle.getEntitle;
  var contactPayload;
  const [userEntitlement, setuserEntitlement] = useState(routerMappingOG);
  const { enableLoader } = usePreLoader();
  let tempAllEntitlement = [];

  const getProfileEntitle = () => {
    enableLoader(true, "Please wait, loading entitlements...");
    localStorage.removeItem("contactId");

    get(apiGetProfileId)
      .then((res) => {
        contactPayload = {
          ...getContactEntitlePayload,
          contactid: res.data.contactid,
        };

        dispatch({ type: "SET_CONTACT", payload: res.data.contactid });
        if (contactPayload.contactid === "") {
          dispatch({ type: "SET_ROLE", payload: "-1" });
        }
        dispatch({ type: "SET_FORMS", payload: res.data.forms });
        // dispatch({
        //   type: "SET_ENTITLEMENTS",
        //   payload: [{ info: "dummy value" }],
        // }); //TODO: should removed while entitlement config

        //return; 
        //uncomment this to remove entitlements

        getEntitle(apiEntitleURL)
          .then((entitleResult) => {
            entitleRes = entitleResult.data;
            tempAllEntitlement = entitleResult.data;
            setAllEntitlement(entitleResult.data);

            //setAllEntitlement(allEntitlement => ([...allEntitlement, entitleResult.data]));
            dispatch({ type: "SET_ENTITLE", payload: entitleResult.data });
            getContactEntitlement();
          })
          .catch(console.log);

        if (res.data.forms.length !== 0) {
          const formsList = JSON.stringify(res.data.forms);
          localStorage.setItem("frms", formsList);
        } else {
          localStorage.setItem("frms", null);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        enableLoader(false);
      });
  };

  const getContactEntitlement = () => {
    postEntitle(apiContactEntitleURL, contactPayload)
      .then((res) => {
        getCurrencysymbol();
        if (res.data.length !== 0) {
          var roleSet = res.data.roles[0];
          dispatch({ type: "SET_ROLE", payload: roleSet });
          //check if you need this
          //setuserRole(res.data.roles[0]);

          //dispatch user role here
          //setPermissionSet(allEntitlement.roles.filter((x) => x.id == roleSet.id));

          // var permission = entitleRes.roles[0].permissionsets;
          // console.log(permission);
          // var filterPermission = permission.filter((item) => {
          //     return item.id === roleSet.id && item.name === roleSet.name;
          // });
          //dispatch({ type: "SET_ROLE", payload: filterPermission[0] });

          //hardcoded data
          setPermissionSet(
            tempAllEntitlement.roles.filter((x) => x.id == roleSet.id) || []
          );
          let ent =
            tempAllEntitlement.roles.filter((x) => x.id == roleSet.id) || [];
          let rm = _.cloneDeep(routerMappingOG);
          rm.forEach((item) => {
            item.permissionsets.push(
              ent[0].permissionsets.find((x) => {
                if (x.assetkey.length > 0 && x.assetkey == item.key) {
                  return x;
                }
              })
            );
          });
          setuserEntitlement(rm);
          dispatch({ type: "SET_ENTITLEMENTS", payload: rm });
          enableLoader(false);
        } else {
          console.log("no entitlement data");
          let rm = routerMappingOG;
          // let ent = entitlementList.roles.filter(
          //   (x) => x.id == "8dff6f5a-7110-ec11-b6e6-000d3ade86ac"
          // );
          // rm.forEach((item) => {
          //   item.permissionsets.push(
          //     ent[0].permissionsets.find((x) => {
          //       if (x.assetkey == item.key) {
          //         return x;
          //       }
          //     })
          //   );
          // });
          // console.log("Rm:", rm);
          dispatch({ type: "SET_ENTITLEMENTS", payload: rm });
          setuserEntitlement(rm);
          enableLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCurrencysymbol = () => {
    const apiURL = config.teleportal.submitForm.get;
    const payload = {
      properties: ["currencyname", "currencysymbol", "transactioncurrencyid"],
      formattedFields: [],
      filter: {
        globalfilter: "",
        globalAndOperator: false,
        fieldAndOperator: false,
        filterfields: [],
      },
      Aggregate: {
        AggregateFields: [],
      },
      recordtype: "transactioncurrencies",
      idfield: "transactioncurrencyid",
      continuationToken: "",
      pazesize: "10",
    };

    post(apiURL, payload)
      .then((res) => {
        if (res.data) {
          dispatch({ type: "SET_CURRENCY", payload: res.data.data });
        }
      })
      .catch(console.log);
  };

  useEffect(() => {
    //console.log('ENTITLEMENTS---'+ue)
    if (token !== "" && ue.length == 0) {
      getProfileEntitle();
    }
  }, [token]);

  return (
    <UserContext.Provider value={userEntitlement}>
      {/* <EntitleComponent /> */}
      {children}
    </UserContext.Provider>
  );
}
