import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import SWFField from "../swfField/SWFField.mui";
import FieldHelp from "../fieldHelp/FieldHelp.mui";

export default function CustomCheckBox(props) {
  const { getProps } = props;
  const [state, setState] = React.useState({});

  const checkboxChecked = getProps.value !== undefined ? getProps.value : false;

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    let obj = { ...state, [event.target.name]: event.target.checked };
    if (!getProps.schema.items) {
      getProps.onChange(event.target.checked);
    } else {
      getProps.onChange(JSON.stringify(obj));
    }
  };

  const label = (
    <span
      dangerouslySetInnerHTML={{
        __html: `${getProps.label}`,
      }}
    />
  );

  return (
    <SWFField row="true">
      <FieldHelp
        helpButtonText={getProps.schema.tootltipheading}
        helpSectionText={getProps.schema.tootltipText}
      />
      {getProps.schema.showHorizontalSeparator && (
        <hr
          style={{
            width: "100%",
            height: "1px",
            color: "grey",
            marginTop: "1rem",
          }}
        />
      )}
      {getProps.schema.heading && (
        <h2
          style={{
            fontSize: "1.14rem",
            fontWeight: "bold",
            color: "rgba(0, 0, 0, 0.54)",
          }}
        >
          {getProps.schema.heading}
        </h2>
      )}
      {!getProps.schema.items && getProps.schema.items === undefined ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={checkboxChecked}
              style={{
                alignSelf: "flex-start",
                marginRight: "4px",
              }}
              id={getProps.id}
              onChange={handleChange}
              name="checkedA"
              data-testid={getProps.id}
              required={getProps.schema.requiredCheck}
              disabled={getProps.schema.disabled}
            />
          }
          htmlFor={getProps.id}
          label={label}
          defaultChecked={getProps.value}
          required={getProps.schema.requiredCheck}
        />
      ) : (
        getProps.schema.items.enum.map((element, index) => {
          return (
            <FormGroup key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      alignSelf: "flex-start",
                      marginRight: "4px",
                    }}
                    id={"CheckBox-" + index}
                    onChange={handleChange}
                    name={element}
                    data-testid={"CheckBox-" + index}
                    required={getProps.schema.requiredCheck}
                    key={index}
                  />
                }
                htmlFor={"CheckBox-" + index}
                label={element}
                required={getProps.schema.requiredCheck}
                key={index}
              />
            </FormGroup>
          );
        })
      )}
    </SWFField>
  );
}
