import { post } from "app/services/api";
import { config } from "utilities/config";
import {
  getContactDetailsAPIPayload,
  getConfig,
  getServiceDetailsOptionsAPIPayload,
} from "./model/contactDetailsPayload";
import { MEMBERSHIP_TYPE_MAP } from "./model/constants";
import moment from "moment";

const fetchContactDetailsAPI = config.teleportal.submitForm.getId;

const formatResponseObject = (response) => {
  const formattedResponse = {};

  response?.forEach(({ name, value, id }) => {
    if (name) {
      formattedResponse[name] = name === "ctpt_datejoined" ? id : value;
    }
  });

  return formattedResponse;
};

export const getContactDetails = async (contactId) => {
  try {
    const { data: response } = await post(
      fetchContactDetailsAPI,
      getContactDetailsAPIPayload(contactId)
    );
    const contactDetails = formatResponseObject(response?.data);

    return {
      email: contactDetails.emailaddress1,
      firstname: contactDetails.firstname,
      mobile: contactDetails.mobilephone,
      postalAddress: contactDetails.address1_composite,
      membershipType: MEMBERSHIP_TYPE_MAP[contactDetails.ctpt_contacttype],
      membershipNumber: contactDetails.ctpt_membershipnumber,
      memberSince:
        contactDetails.ctpt_datejoined &&
        moment(contactDetails.ctpt_datejoined).format("MMM Do YYYY"),
      renewalDue: `1st Jan ${new Date().getFullYear() + 1}`,
      accountStatus: contactDetails.statecode === "1" ? "Inactive" : "Active",
      profileupdateDate: contactDetails.ctpt_profiledetailsconfirmedaccurateon,
      isAccountOnHold: contactDetails.ctpt_onhold,
      terminationType: contactDetails.ctpt_terminationtype,
      terminatedOn: contactDetails.ctpt_dateterminated,
    };
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const getConigitems = () => {
  let value;
  post(config.teleportal.submitForm.getId, getConfig)
    .then((res) => {
      value = res.data.data[0].value;
      console.log("value--" + res.data.data[0].value);
    })
    .catch(console.log);

  return value;
};

const formatServiceRequestsResponse = (response) => {
  return response.reduce((acc, { id, data }) => {
    const val = data.reduce((acc, { name, value }) => {
      acc[`${name}`] = value;
      return acc;
    }, {});
    if (!val.ctpt_displayflag) {
      return acc;
    }
    return acc.concat({
      name: val.ctpt_name,
      link: "/queries?serviceRequestId=" + id,
    });
  }, []);
};

export const getServiceRequestOptions = async () => {
  try {
    const { data: response } = await post(
      "/api/GetAllAsync",
      getServiceDetailsOptionsAPIPayload
    );
    const serviceDetails = formatServiceRequestsResponse(response?.data);

    return serviceDetails;
  } catch (err) {
    console.log(err);
    return {};
  }
};
