import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { identitySchema } from "app/components/mvp/formWidget/model/identity";
import { identityUiSchema } from "app/components/uiSchema/identityUiSchema";
import { IdentityUpdatePayload } from "app/services/payloadSubmit/identityPayload";
import { config } from "utilities/config";
import { post } from "app/services/api";
import FormWidget from "app/components/mvp/formWidget";
import { customValidate } from "./model/validation";

function IdentityDetails(props) {
  // const userEntitlement = useSelector(state => state.entitleMents)
  // const userRole = useSelector(state => state.role)
  // let permissions =
  //   userEntitlement && userEntitlement.find(x => x.route == props.match.path)
  const contactid = useSelector((state) => state.contactid);
  const [contactFormData, setContactFormData] = useState({});
  const dispatch = useDispatch();

  const contact = {
    widgetType: "updateContact",
    formSchema: identitySchema,
    uiSchema: identityUiSchema,
    formData: contactFormData,
    apiURL: config.teleportal.submitForm.Update,
    payloadSchema: {},
    entityType: "ctpt_identities",
    formUischema: "",
    button1Label: "Save",
    button2Label: "Go Back",
    payloadSubmit: IdentityUpdatePayload,
    contactId: contactid,
    goBackPage: "/profile",
    button2GoBack: true,
    historyUrl: "",
    validate: customValidate,
  };

  const [contactOptions, setContactOptions] = useState(contact);

  useEffect(() => {
    if (contactid.length > 0) {
      getContactDetails();
    }
  }, [contactid]);

  const updateFormData = (entityType, formData) => {
    contact.formData = formData;
    contact.initialFormData = formData;
    setContactOptions(contact);
    setContactFormData(formData);
  };

  const getContactDetails = () => {
    let api;
    let payloads;
    //let ctpt_Specialist_Contact_Contact=[]
    const formData = {};
    api = config.teleportal.submitForm.get;
    payloads = {
      properties: ["ctpt_identitynumber", "ctpt_identityid"],
      recordType: "ctpt_identities",
      formattedFields: ["ctpt_type"],
      filter: {
        globalfilter: "",
        globalAndOperator: false,
        fieldAndOperator: false,
        filterfields: [
          {
            field: "_ctpt_contactid_value",
            searchvalue: contactid,
            type: "guid",
          },
        ],
      },
      Aggregate: {
        AggregateFields: [],
      },
      idfield: "ctpt_identityid",
      continuationToken: "",
      pazesize: "10",
    };

    post(api, payloads)
      .then((res) => {
        // res.data.data[0].data.forEach(x => {
        //   formData[x.name] = x.value
        // })
        formData[res.data.data[0].data[0].name] =
          res.data.data[0].data[0].value;
        formData[res.data.data[0].data[1].name] =
          res.data.data[0].data[1].value;
        formData[res.data.data[0].data[2].name] = res.data.data[0].data[2].id;
        updateFormData({}, formData);
      })
      .catch(console.log);
  };

  const getResponse = (res) => {
    if (res.data.id) {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record updated successfully",
          code: "",
          display: "show",
          severity: "success",
        },
      });
    } else {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record update failed",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    }
  };

  return (
    <Grid container spacing={0} justify="center">
      <Grid item xs={12} md={9}>
        <FormWidget
          options={contactOptions}
          updateFormData={updateFormData}
          successResponse={getResponse}
        />
      </Grid>
    </Grid>
  );
}

export default IdentityDetails;
