import { post } from "app/services/api";
import { config } from "utilities/config";
import { getlistPayload } from "app/pages/applications/model/payload";

export const checkOpenApp = async (appType, contactid) => {
  const { data: response } = await post(config.teleportal.custom.fetch, {
    ...getlistPayload,
    contactid,
  });
  if (response?.data) {
    if (
      response.data.filter((obj) =>
        appType
          ? obj.name.includes(appType) && obj.status !== "Fail"
          : obj.status !== "Fail"
      ).length > 0
    ) {
      return 1;
    } else {
      return 2;
    }
  }
};
