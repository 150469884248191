//Import React Library
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { AuthenticatedTemplate } from "@azure/msal-react";

//Import Material-UI Library
import { ThemeProvider } from "@material-ui/core/styles";

//Loading Data and Style..
import { globalTheme } from "style/globalTheme";
import { useStyles } from "./style";
//Loading components
import { config } from "utilities/config";

import ListComponent from "app/components/mvp/listWidget";
import { aggregatecolumns } from "./model/column";
import { getcolumns } from "./model/column";
import { getqualificationlistPayload } from "./model/payload";
import { getQualificationSchema } from "./model/schema";
import { getQualificationUiSchema } from "./model/UiSchema";
import { CreaterequestPayload } from "./model/createPayload";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

function Qualifications(props) {
  //const userEntitlement = useContext(UserContext)
  // const userEntitlement = useSelector((state) => state.entitleMents);
  // const classes = useStyles();
  // const role = useSelector((state) => state.role);
  // const getcontactName = useSelector((state) => state.user);
  // const getcontactId = useSelector((state) => state.contactid);

  const classes = useStyles();
  const getcontactId = useSelector((state) => state.contactid);
  const history = useHistory();
  const getcontactName = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);

  const onClickViewDetails = (params) => {
    window.location.href =
      "/profile/viewqualificationdetails?id=" +
      params.row.mshied_previouseducationid;
  };

  const columns = [
    { field: "_mshied_degreeid__value", flex: 1, headerName: "Degree",
      renderCell: (params) => {
        return (
          <span>{params.row._mshied_degreeid__value=="Other"?params.row.ctpt_otherdegreeid_:params.row._mshied_degreeid__value}</span>
        );
      }, 
    },
    { field: "_ctpt_discipline_value", flex: 1, headerName: "Discipline",
      renderCell: (params) => {
        return (
          <span>{params.row._ctpt_discipline_value=="Other"?params.row.ctpt_otherdiscipline:params.row._ctpt_discipline_value}</span>
        );
      }, 
    },
    {
      field: "_ctpt_institutionname_value",
      flex: 1.5,
      headerName: "Institution Name",
      renderCell: (params) => {
        return (
          <span>{params.row._ctpt_institutionname_value=="Other"?params.row.ctpt_otherinstitutionname:params.row._ctpt_institutionname_value}</span>
        );
      }, 
    },
    { field: "mshied_graduationdate", flex: 1, headerName: "Date Obtained" },
    { field: "ctpt_yearofqualification", flex: 1, headerName: "Year of Qualification" },
    {
      field: "action",
      headerName: <p class={classes.paidListHeader}>Action</p>,
      flex: 1,
      renderCell: (params) => {
        return (
          <div class={classes.actionsButtons}>
            <Button
              variant="outlined"
              color="primary"
              className="primaryBtn"
              onClick={() => {
                onClickViewDetails(params);
              }}
              disabled={false}
            >
              View Details
            </Button>
          </div>
        );
      },
    },
  ];

  // const columns = [
  //   { field: "ctpt_currentyearofstudy", flex: 1, headerName: "Current year of study"},
  //   { field: "mshied_dateofenrollment", flex: 1, headerName: "Date of enrollment" }
  // ];

  const options = {
    apiURL: config.teleportal.submitForm.get,
    columns: columns,
    searchPayload: "searchFilter",
    getlistPayload: getqualificationlistPayload(getcontactId),
    deleteListPayload: {},
    listHeading: "Qualifications",
    formwidgetOptions: {
      widgetType: "add",
      formSchema: getQualificationSchema,
      uiSchema: getQualificationUiSchema,
      apiURL: config.teleportal.submitForm.Create,
      entityType: "mshied_previouseducations",
      formUischema: "",
      button1Label: "Add",
      button2Label: "Cancel",
      button3Label: "Clear",
      payloadSubmit: CreaterequestPayload,
      columns: [],
      contactId: getcontactId,
      contactName: getcontactName.name,
      historyUrl: "",
      hasMultiplecall: true,
      hasModel: true,
    },
    nonRecord: false,
    role: role,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: false,
    filterField: "",
    filterValue: [],
    aggregateField: "",
    hideSearch: true,
    pageSize: 10,
    hasPayloadfilter: true,
    hideAdd: false,
    controlLists: false,
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div id="cTTP_applicationList" class={classes.container}>
          <div class={classes.listSection}>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              onClick={() => {
                history.push("/profile");
              }}
              style={{ marginLeft: "1.5rem" }}
            >
              Go Back
            </Button>
            <ListComponent options={options} />
          </div>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default Qualifications;
