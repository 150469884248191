import Upload from "app/components/atoms/upload";
import CustomTextBox from "app/components/atoms/customTextBox";
import CustomCheckBox from "app/components/atoms/customCheckBox";
import CustomRadio from "app/components/atoms/customRadio";
import CustomDate from "app/components/atoms/customDate";
import CustomSelect from "app/components/atoms/customSelect";
import CustomButton from "app/components/atoms/customButton";
import MultipleSelect from "app/components/atoms/multiDropdown/MultipleSelect.mui";

const CustomUpload = ({ getProps }) => {
  return (
    <Upload
      recordType={getProps.schema.containerName}
      recordPath={getProps.schema.recordPath}
      getProps={getProps}
    />
  );
};

export const uiSchema = {
  "ui:title": "",
  title: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  surname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  firstName: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  secondName: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  dateofbirth: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  proofOfResignation: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  resignationreasonconditional: {
    
    reasonforresignation: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    othersspecify: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  codeOfEthicsLink: {
    "ui:widget": (props) => {
      return (
        <a href={props.schema.uri} target="_blank">
          {props.label}
        </a>
      );
    },
  },
  constitutionAndBylawsLink: {
    "ui:widget": (props) => {
      console.log("constitutionAndBylawsLink", props);
      return (
        <a href={props.schema.uri} target="_blank">
          {props.label}
        </a>
      );
    },
  },
  iHaveReadAllInfo: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
};
