import { post } from "app/services/api";
import { config } from "utilities/config";

export const CreaterequestPayload = (contactID) => (JsonData) => {
  const details = JsonData.data;
  return {
    recordtype: "ctpt_studenttrainingagreements",

    data: [
      {
        name: "ctpt_Student",
        value: contactID,
        type: "lookup",
        relatedentity: "contacts",
      },
      {
        name: "ctpt_name",
        value: details[0].value,
        type: "string",
      },

      {
        name: "ctpt_startdate",
        value: details[1].value,
        type: "string",
      },
      {
        name: "ctpt_duration",
        value: details[2].value,
        type: "string",
      },
      {
        name: "ctpt_enddate",
        value: details[3].value,
        type: "string",
      },
    ],
  };
  //return payload
};

export const CreatedocumentPayload = {
  recordtype: "ctpt_supportingdocuments",
  data: [
    {
      name: "ctpt_name",
      value: "",
      type: "string",
    },
    {
      name: "ctpt_documenttype",
      value: 100000001,
      type: "picklist",
    },
    {
      name: "ctpt_case",
      value: "",
      type: "lookup",
      relatedentity: "ctpt_cases",
    },
    {
      name: "ctpt_documenturl",
      value: "",
      type: "string",
    },
  ],
};

const getCaseDetailPayload = (caseID) => ({
  properties: ["ctpt_studenttrainingagreementid", "ctpt_name"],
  formattedFields: [],
  recordType: "ctpt_studenttrainingagreements",
  id: caseID,
});

export const CreatedocPayload = (contactName, caseId, docUrl, JsonData) => {
  return post(config.teleportal.submitForm.getId, getCaseDetailPayload(caseId))
    .then((res) => {
      if (res.data) {
        const agreementNum = res.data?.data?.find(
          (obj) => obj.name === "ctpt_studenttrainingagreementid"
        )?.value;

        const agreementTypeMap = JSON.parse(
          localStorage.getItem("dynamicPickList") || "{}"
        );
        const agreementType =
          Object.keys(agreementTypeMap)?.length > 0
            ? agreementTypeMap[JsonData.data?.[0]?.value] || ""
            : "";

        console.log("JsonData--" + JSON.stringify(JsonData));

        return {
          recordtype: "ctpt_supportingdocuments",
          data: [
            {
              name: "ctpt_name",
              value: agreementNum
                ? `SD-${agreementNum?.toString()?.trim()}${
                    agreementType ? "-" + agreementType : ""
                  }`
                : contactName,
              type: "string",
            },
            {
              name: "ctpt_StudentTrainingAgreementId",
              value: caseId,
              type: "lookup",
              relatedentity: "ctpt_studenttrainingagreements",
            },
            {
              name: "ctpt_documenttype",
              value: 100000001,
              type: "picklist",
            },
            {
              name: "ctpt_documenturl",
              value: docUrl,
              type: "string",
            },
          ],
        };
      }
    })
    .catch((err) => {
      console.log("Error fetching case details", err);
      return {};
    });
};

export const createDocAttachmentPayload = (base64Doc, supportingDocRes) => {
  if (!(base64Doc?.data && base64Doc?.fileName && supportingDocRes?.id)) {
    return null;
  }
  return {
    recordtype: "annotations",
    data: [
      {
        name: "subject",
        value: "Case supporting doc attachment",
        type: "string",
      },
      {
        name: "filename",
        value: base64Doc.fileName,
        type: "string",
      },
      {
        name: "documentbody",
        value: base64Doc.data,
        type: "string",
      },
      {
        name: "objectid_ctpt_supportingdocument",
        value: supportingDocRes.id,
        type: "lookup",
        relatedentity: "ctpt_supportingdocuments",
      },
      {
        name: "mimetype",
        value: base64Doc.fileType,
        type: "string",
      },
    ],
  };
};
