import { makeStyles } from "@material-ui/core/styles";
import { brandPalette } from "brand/theme/brandTheme";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
      overflow: "visible",
    },
  },
  Paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(10),
    textAlign: "center",
    color: theme.palette.text.secondary,
    minHeight: "1000px",
  },
  heroImg: {
    [theme.breakpoints.down("xs")]: { 
      marginTop: "-3rem",
      background: "url(/brand/DESKTOP-HOMEPAGE6.jpg) top left no-repeat",
      backgroundSize: "cover"
     },
    maxHeight: "500px",
    minHeight: "500px",
    position: "absolute",
    zIndex: "1",
    left: "0px",
    right: "0px",
    width: "100%",
    //backgroundPosition: "50%",
    backgroundSize: "contain",
    // background: "url(/brand/slide4.jpeg) top left no-repeat",
    background: "url(/brand/DESKTOP-HOMEPAGE6.jpg) top left no-repeat",
  },
  paperMargin: {
    marginTop: "40px",
  },
  leftBlock: {
    margin: theme.spacing(10, "auto"),
    padding: theme.spacing(10),
  },
  textHead1: {
    margin: theme.spacing(10, "auto"),
    textAlign: "left",
  },
  textHead: {
    margin: theme.spacing(10, "auto"),
    textAlign: "left",
  },
  textSpacing: {
    padding: "30px",
  },
  textBox: {
    padding: "30px",
    height: "33vh",
    minHeight: "324px",
  },
  Divider: {
    margin: "20px",
  },
  buttonElem: {
    justifyContent: "center",
    margin: theme.spacing(10, "auto"),
  },
  buttonBand: {
    height: "6rem",
    backgroundColor: "#3C3C3C",
    position: "relative",
    zIndex: "2",
    opacity: ".9",
    marginTop: "273px",
    [theme.breakpoints.down("xs")]: { marginTop: "300px", opacity: "1" },
  },
  bodyCopy2: {
    fontSize: "24px",
    paddingLeft: "15px",
    // fontWeight: "bold",
    // textTransform: "uppercase",
    letterSpacing: "1px",
    color: "white",
  },
  buttonBig: {
    width: "10rem",
    height: "3rem",
    borderRadius: "2rem",
    margin: theme.spacing("auto"),
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
      margin: theme.spacing(5, "auto"),
    },
    borderWidth: "2px",
  },
  primary: {
    "&:hover": {
      color: "white",
      backgroundColor: brandPalette["Analogous-1"]["200"],
    },
    fontWeight: "bold",
    //backgroundColor: theme.palette.secondary.main,
  },
  secondary: {
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      color: "white",
      border: `2px solid ${brandPalette["Analogous-1"]["300"]}`,
      backgroundColor: "transparent",
      opacity: "1",
    },
  },
  textBand: {
    padding: theme.spacing(0),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  navlist: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  homePageSubTitle: {
    backgroundColor: theme.palette.grey[500],
    opacity: "0.8",
  },
  bannerContainer: {
    background: "url(/brand/DESKTOP-HOMEPAGE6.jpg)",
    //backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    backgroundSize: "cover",
    height: "16rem",
    //width: "100%",
    borderRadius: "3px",
    margin: "1rem",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      backgroundPositionX: "center",
    },
  },
  banner: {
    width: "100%",
    height: "200%",
    display: "none",
  },
  imageCards: {
    marginTop: "1.7rem",
  },
  heading: {
    //color: "red",
  },
  tileCardContainer: {
    backgroundColor: theme.palette.midlight,
    display: "flex",
    flex: 1,
    maxWidth: "22rem",
    justifyContent: "flex-start",
    // flexWrap: "wrap",
    // flex: "1 0 30%",
  },
  description: {
    marginTop: "-.2rem !important",
    marginBottom: "1.2rem",
    maxWidth: "50rem",
  },
  showflex: {
    display: "block",
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    fontSize: "15px",
    padding: "10px",
  },
  profileHeading: {
    backgroundColor: theme.palette.secondary.main,
  },
  hideflex: {
    display: "none",
  },
  profileBlock: {
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    fontSize: "15px",
  },
  cards: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(16),
    backgroundColor: "#E9F6FC",
    // backgroundImage:
    //   "linear-gradient(to right bottom, rgba(255, 175, 189, .3), rgba(255, 195, 160, .3))",
    // clipPath: "polygon(0 0, 100% 0, 100% 75vh, 0 100%)",
  },
  imageCard: {
    marginBottom: theme.spacing(16),
  },
}));
