import CustomTextBox from "app/components/atoms/customTextBox";
import CustomSelect from "app/components/atoms/customSelect";
import CustomDate from "app/components/atoms/customDate";
import Upload from "app/components/atoms/upload";

export const getProfessionalOrganisationUiSchema = {
  "ui:title": "",
  _ctpt_organisationname_value: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  _ctpt_organisatiocountry_value: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  _ctpt_registrationcategory_value: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  ctpt_yearofelection: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_registrationnumber: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
};

export const viewEmailDetailsUiSchema = {
  "ui:title": "",
  ctpt_emailaddress: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
};
