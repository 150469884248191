import { CAPS_LOCK_WARNING } from "constants/settings";

export const getEmploymentHistorySchema = {
  title: "Add Employment Details ",
  logicalname: "Contact2",
  type: "object",
  description: CAPS_LOCK_WARNING,
  properties: {
    // _ctpt_employeeid_value: {
    //     title: "Employee ID",
    //     type: "string",
    //     schemaname: "_ctpt_employeeid_value",
    //     datatype: "string"
    // },
    // ctpt_jobdescription: {
    //   title: "Job Description",
    //   type: "string",
    //   schemaname: "ctpt_jobdescription",
    //   datatype: "string",
    //   requiredCheck: true,
    // },
    ctpt_jobtitle: {
      title: "Position Held",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
    },
    ctpt_company: {
      title: "Company",
      type: "string",
      schemaname: "_ctpt_employerid_value",
      datatype: "string",
    },
    ctpt_typeofemployment: {
      title: "Employment Sector",
      type: "string",
      schemaname: "ctpt_typeofemployment",
      datatype: "string",
      requiredCheck: true,
      enum: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      enumNames: [
        "Construction",
        "Consulting",
        "Consulting, Construction",
        "Education/Research",
        "Government/Public",
        "Municipal",
        "Parastatal/State Owned",
        "Private",
        "Other",
        "Unspecified",
      ],
    },
    ctpt_startdate: {
      title: "Start Date",
      type: "string",
      schemaname: "ctpt_startdate",
      datatype: "string",
      requiredCheck: true,
    },
    ctpt_enddate: {
      title: "End Date",
      type: "string",
      schemaname: "ctpt_enddate",
      datatype: "string",
      requiredCheck: false,
    },
  },
};

export const getEmploymentHistoryDetailsSchema = {
  title: "Edit Employment Details",
  logicalname: "employmenthistory",
  type: "object",
  description: CAPS_LOCK_WARNING,
  properties: {
    // ctpt_jobdescription: {
    //   title: "Job Description",
    //   type: "string",
    //   schemaname: "ctpt_jobdescription",
    //   datatype: "string",
    //   requiredCheck: true,
    // },
    ctpt_jobtitle: {
      title: "Position Held",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
    },
    ctpt_company: {
      title: "Company",
      type: "string",
      schemaname: "_ctpt_employerid_value",
      datatype: "string",
    },
    ctpt_typeofemployment: {
      title: "Employment Sector",
      type: "string",
      schemaname: "ctpt_typeofemployment",
      datatype: "string",
      enum: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      enumNames: [
        "Construction",
        "Consulting",
        "Consulting, Construction",
        "Education/Research",
        "Government/Public",
        "Municipal",
        "Parastatal/State Owned",
        "Private",
        "Other",
        "Unspecified",
      ],
      requiredCheck: true,
    },
    ctpt_startdate: {
      title: "Start Date",
      type: "string",
      schemaname: "ctpt_startdate",
      datatype: "string",
      requiredCheck: true,
    },
    ctpt_enddate: {
      title: "End Date",
      type: "string",
      schemaname: "ctpt_enddate",
      datatype: "string",
      requiredCheck: false,
    },
  },
};
