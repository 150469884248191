import CustomSelect from "app/components/atoms/customSelect";

export const getDivisionListUiSchema = {
    "ui:title": "",
    ctpt_divisionid: {
        "ui:widget": props => {
            return <CustomSelect getProps={props} />;
        }
    },

};
