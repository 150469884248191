//Import React Library
import React, { useEffect, useState, useContext } from "react"; 
import { AuthenticatedTemplate } from "@azure/msal-react";

//Import Material-UI Library
import { ThemeProvider } from "@material-ui/core/styles";

//Loading Data and Style..
import { globalTheme } from "style/globalTheme";

//Loading components
import TabWidget from "app/components/molecules/tab/TabWidget.mui";
import IdentityDetails from "./identitydetails";
import IdentityUpload from "./identityupload";
import { useProfileStyles } from "./style";


function Identity(props) {
  const classes = useProfileStyles();
  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div class={classes.background1}></div>
        <div id="cTTP_applicationList" class={classes.container}>
          <TabWidget
            options={{
              defaultTabID: 1,
              tabNavJson: [
                {
                  label: "Identity Details",
                  id: 1,
                  entity: (
                    <div class={classes.listSection}>
                      <IdentityDetails />
                    </div>
                  ),
                },
                {
                  label: "Attachments",
                  id: 2,
                  entity: (
                    <div class={classes.paidInvoiceList}>
                      <IdentityUpload />
                    </div>
                  ),
                }
              ],
            }}
          />
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default Identity;
