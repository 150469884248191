import Upload from "app/components/atoms/upload";
import CustomTextBox from "app/components/atoms/customTextBox";
import CustomCheckBox from "app/components/atoms/customCheckBox";
import CustomSelect from "app/components/atoms/customSelect";

const CustomUpload = ({ getProps }) => {
  return (
    <Upload
      recordType={getProps.schema.containerName}
      recordPath={getProps.schema.recordPath}
      getProps={getProps}
    />
  );
};

export const uiSchema = {
  "ui:title": "",
  nameOfTheEmployer: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  typeOfOrganisation: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  line1: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  line2: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  city: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  district: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  postalCode: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  country: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  telephone: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  mobile: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  email: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  // qualifiedPerson: {
  //   "ui:widget": (props) => {
  //     return <CustomTextBox getProps={props} />;
  //   },
  // },
  // bicaMembernumber: {
  //   "ui:widget": (props) => {
  //     return <CustomTextBox getProps={props} />;
  //   },
  // },
  // title: {
  //   "ui:widget": (props) => {
  //     return <CustomTextBox getProps={props} />;
  //   },
  // },
  qprt: {
    bicaMembernumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    addAnotherBusiness: {
      items: {
        bicaMembernumber: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
      }
    }
  },

  branchName: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  numberOfPartner: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  numberOfAccountStaff: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  numberOfTechnicalStaff: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  total: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  companyProfile: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  latestFinancial: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  bursTax: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  certificateIncorp: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  applyAdmissionCheckBox: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
}

