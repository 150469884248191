import Upload from "app/components/atoms/upload";
import CustomTextBox from "app/components/atoms/customTextBox";
import CustomCheckBox from "app/components/atoms/customCheckBox";
import CustomRadio from "app/components/atoms/customRadio";
import CustomDate from "app/components/atoms/customDate";
import CustomSelect from "app/components/atoms/customSelect";
import CustomButton from "app/components/atoms/customButton";
import MultipleSelect from "app/components/atoms/multiDropdown/MultipleSelect.mui";

const CustomUpload = ({ getProps }) => {
  return (
    <Upload
      recordType={getProps.schema.containerName}
      recordPath={getProps.schema.recordPath}
      getProps={getProps}
    />
  );
};

export const uiSchema = {
  "ui:title": "",
  proofOfRetirement: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  retirementDescription: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
};
