const initialState = {
  token: "",
  isAuth: false,
  user: {},
  entitle: {},
  role: {},
  status: {
    message: "",
    code: "",
    severity: "success",
    display: "hide",
  },
  forms: [
    {
      id: "",
      name: "individualMembership",
      submissionid: "",
      status: "",
    },
  ],
  schema: {},
  contactid: "",
  contactResponse: "",
  reference: undefined,
  submissionId: "",
  redirectUrls: {
    payment: "/",
  },
  paysummary: null,
  docUrl: "",
  contactDet: {},
  base64Doc: { data: "", fileName: "", fileType: "" },
  entitleMents: [],
  showLoader: false,
  loadingMessage: "",
  accountStatus: null,
  currencySymbol: {},
  isAccountOnHold: false,
  isTerminated: false,
};

const ACTION_TYPE_TO_PAYLOAD_MAP = {
  SET_ENTITLE: (payload) => ({ entitle: payload }),
  SET_ROLE: (payload) => ({ role: payload }),
  SET_TOKEN: (payload) => ({ token: payload }),
  SET_USER: (payload) => ({ user: payload }),
  SET_SONICDATA: (payload) => ({ schema: payload }),
  SET_STATUS: (payload) => ({ status: payload }),
  SET_FORMS: (payload) => ({ forms: payload }),
  SET_CONTACT: (payload) => ({ contactid: payload }),
  SET_CONTACT_RESPONSE: (payload) => ({ contactResponse: payload }),
  SET_REPEATER: (payload) => ({ reference: payload }),
  SET_SUBID: (payload) => ({ submissionId: payload }),
  SET_URLS: (payload) => ({ redirectUrls: payload }),
  SET_PAYSUMMARY: (payload) => ({ paysummary: payload }),
  SET_DOC: (payload) => ({ docUrl: payload }),
  SET_BASE64_DOC: (payload) => ({ base64Doc: payload }),
  SET_CONTACTDET: (payload) => ({ contactDet: payload }),
  SET_ENTITLEMENTS: (payload) => ({ entitleMents: payload }),
  SET_SHOW_LOADER: (payload) => ({
    showLoader: payload.status,
    loadingMessage: payload.message,
  }),
  SET_ACCOUNT_STATUS: (payload) => ({ accountStatus: payload }),
  SET_CURRENCY: (payload) => ({ currency: payload }),
  SET_ISACCOUNTONHOLD: (payload) => ({ isAccountOnHold: payload }),
  SET_ISTERMINATED: (payload) => ({ isTerminated: payload }),
};

function rootReducer(state, action) {
  if (action.type === "CLEAR_STORE") {
    return initialState;
  }
  return {
    ...(state || initialState),
    ...(ACTION_TYPE_TO_PAYLOAD_MAP[action.type]?.(action.payload) || {}),
  };
}

export default rootReducer;
