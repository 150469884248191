export const upgradeTilesData = [
  // {
  //   title: "Accounting Technician to Associate Level",
  //   linkText: "Upgrade Now",
  //   linkURI: "/application/upgradeTechnician",
  //   allowmemberType: "Accounting Technician",
  // },
  // {
  //   title: "Associate to Fellow",
  //   linkText: "Upgrade Now",
  //   linkURI: "/application/upgradeAssociate",
  //   allowmemberType: "Associate Chartered Accountant",
  // },
  // {
  //   title: "Accounting Technician to Fellow",
  //   linkText: "Upgrade Now",
  //   linkURI: "/application/upgradeFellow",
  //   allowmemberType: "Accounting Technician",
  // },
  {
    title: "Student Member to Associate Member",
    linkText: "Upgrade Now",
    linkURI: "/application/upgradeStudentApplication",
    allowmemberType: "Student",
  },
  {
    title: "Associate Member to Corporate Member",
    linkText: "Upgrade Now",
    linkURI:  "/application/upgradeAssociateApplication",
    allowmemberType: "Associate",
  },
  
];
