import { CAPS_LOCK_WARNING } from "constants/settings";

export const getQualificationSchema = {
  title: "Add Qualification ",
  logicalname: "Contact2",
  type: "object",
  additionalDescription: CAPS_LOCK_WARNING,
  properties: {
    degreeConditional: {
      $ref: "#/definitions/degree",
    },
    disciplineConditional: {
      $ref: "#/definitions/discipline",
    },
    institutionConditional: {
      $ref: "#/definitions/institution",
    },
    ctpt_country: {
      title: "Country",
      schemaname: "ctpt_country",
      type: "string",
      datatype: "array",
      requiredCheck: true,
      helpText: "",
      isDynamic: true,
      apiURL: "/api/GetAllAsync",
      payload: {
        properties: ["ctpt_name", "ctpt_countryid", "ctpt_displayflag"],
        filter: {
          globalfilter: "",
          globalAndOperator: false,
          fieldAndOperator: true,
          filterfields: [
            {
              field: "statecode",
              searchvalue: 0,
              type: "guid",
            },
          ],
        },
        Aggregate: {
          AggregateFields: [],
        },
        sortfield: {
          field: "ctpt_name",
          sorttype: "asc",
        },
        recordtype: "ctpt_countries",
        idfield: "ctpt_countryid",
        continuationToken: "",
        pazesize: "1000",
      },
      enum: [],
      enumNames: [],
    },
    mshied_graduationdate: {
      title: "Graduation Date",
      type: "string",
      schemaname: "mshied_graduationdate",
      datatype: "string",
    },
    ctpt_yearofqualification: {
      title: "Year of Qualification",
      type: "string",
      schemaname: "ctpt_yearofqualification",
      datatype: "string",
      views: ["year"],
      format: "yyyy",
    },
  },
  definitions: {
    degree: {
      title: "",
      type: "object",
      properties: {
        mshied_DegreeId_: {
          title: "Name of Degree/Diploma/Qualification program",
          type: "string",
          schemaname: "mshied_DegreeId_",
          datatype: "array",
          requiredCheck: true,
          tootltipText:
            "Inline help text can appear in association with a field. It expands to show on selecting ?Help? and can be collapsed by selecting ?Close?",
          helpText: "",
          isDynamic: true,
          apiURL: "/api/GetAllAsync",
          payload: {
            properties: [
              "mshied_name",
              "mshied_programlevelid",
              "ctpt_displayflag",
            ],
            filter: {
              globalfilter: "",
              globalAndOperator: false,
              fieldAndOperator: true,
              filterfields: [
                {
                  field: "statecode",
                  searchvalue: 0,
                  type: "guid",
                },
              ],
            },
            Aggregate: {
              AggregateFields: [],
            },
            sortfield: {
              field: "mshied_name",
              sorttype: "asc",
            },
            recordtype: "mshied_programlevels",
            idfield: "mshied_programlevelid",
            continuationToken: "",
            pazesize: "1000",
          },
          enum: [],
          enumNames: [],
        },
      },
      dependencies: {
        mshied_DegreeId_: {
          oneOf: [
            {},
            {
              properties: {
                mshied_DegreeId_: {
                  enum: ["fe67ad1e-b5fb-ec11-82e5-00224881f7ec"],
                },
                ctpt_otherdegreeid_: {
                  title: "Specify",
                  type: "string",
                  requiredCheck: true,
                  datatype: "string",
                  helpText: "",
                  defaultValue: "",
                  pattern: "",
                },
              },
            },
          ],
        },
      },
    },
    discipline: {
      title: "",
      type: "object",
      properties: {
        ctpt_Discipline: {
          title: "Discipline",
          schemaname: "ctpt_Discipline",
          type: "string",
          datatype: "array",
          requiredCheck: true,
          helpText: "",
          isDynamic: true,
          apiURL: "/api/GetAllAsync",
          payload: {
            properties: ["ctpt_name", "ctpt_disciplineid", "ctpt_displayflag"],
            filter: {
              globalfilter: "",
              globalAndOperator: false,
              fieldAndOperator: true,
              filterfields: [
                {
                  field: "statecode",
                  searchvalue: 0,
                  type: "guid",
                },
              ],
            },
            Aggregate: {
              AggregateFields: [],
            },
            sortfield: {
              field: "ctpt_name",
              sorttype: "asc",
            },
            recordtype: "ctpt_disciplines",
            idfield: "ctpt_disciplineid",
            continuationToken: "",
            pazesize: "1000",
          },
          enum: [],
          enumNames: [],
        },
      },
      dependencies: {
        ctpt_Discipline: {
          oneOf: [
            {},
            {
              properties: {
                ctpt_Discipline: {
                  enum: ["5db0d663-bafb-ec11-82e5-00224881f7ec"],
                },
                ctpt_otherdiscipline: {
                  title: "Specify",
                  type: "string",
                  requiredCheck: true,
                  datatype: "string",
                  helpText: "",
                  defaultValue: "",
                  pattern: "",
                },
              },
            },
          ],
        },
      },
    },
    institution: {
      title: "",
      type: "object",
      properties: {
        ctpt_InstitutionName: {
          title: "Institution Name",
          schemaname: "ctpt_InstitutionName",
          type: "string",
          datatype: "array",
          requiredCheck: true,
          tootltipText:
            "Inline help text can appear in association with a field. It expands to show on selecting ?Help? and can be collapsed by selecting ?Close?",
          helpText: "",
          isDynamic: true,
          apiURL: "/api/GetAllAsync",
          payload: {
            properties: [
              "ctpt_name",
              "ctpt_institutionnameid",
              "ctpt_displayflag",
            ],
            filter: {
              globalfilter: "",
              globalAndOperator: false,
              fieldAndOperator: true,
              filterfields: [
                {
                  field: "statecode",
                  searchvalue: 0,
                  type: "guid",
                },
              ],
            },
            Aggregate: {
              AggregateFields: [],
            },
            sortfield: {
              field: "ctpt_name",
              sorttype: "asc",
            },
            recordtype: "ctpt_institutionnames",
            idfield: "ctpt_institutionnameid",
            continuationToken: "",
            pazesize: "1000",
          },
          enum: [],
          enumNames: [],
        },
      },
      dependencies: {
        ctpt_InstitutionName: {
          oneOf: [
            {},
            {
              properties: {
                ctpt_InstitutionName: {
                  enum: ["e47b482f-a7fb-ec11-82e5-00224881f1d4"],
                },
                ctpt_otherinstitutionname: {
                  title: "Specify",
                  type: "string",
                  requiredCheck: true,
                  datatype: "string",
                  helpText: "",
                  defaultValue: "",
                  pattern: "",
                },
              },
            },
          ],
        },
      },
    },
  },
};

export const getQualificationDetailsSchema = {
  title: "Edit Qualification",
  logicalname: "Contact2",
  type: "object",
  additionalDescription: CAPS_LOCK_WARNING,
  properties: {
    degreeConditional: {
      $ref: "#/definitions/degree",
    },
    disciplineConditional: {
      $ref: "#/definitions/discipline",
    },
    institutionConditional: {
      $ref: "#/definitions/institution",
    },
    _ctpt_country_value: {
      title: "Country",
      schemaname: "_ctpt_country_value",
      type: "string",
      datatype: "array",
      requiredCheck: true,
      helpText: "",
      isDynamic: true,
      apiURL: "/api/GetAllAsync",
      payload: {
        properties: ["ctpt_name", "ctpt_countryid", "ctpt_displayflag"],
        filter: {
          globalfilter: "",
          globalAndOperator: false,
          fieldAndOperator: true,
          filterfields: [
            {
              field: "statecode",
              searchvalue: 0,
              type: "guid",
            },
          ],
        },
        Aggregate: {
          AggregateFields: [],
        },
        sortfield: {
          field: "ctpt_name",
          sorttype: "asc",
        },
        recordtype: "ctpt_countries",
        idfield: "ctpt_countryid",
        continuationToken: "",
        pazesize: "1000",
      },
      enum: [],
      enumNames: [],
    },
    mshied_graduationdate: {
      title: "Graduation Date",
      type: "string",
      schemaname: "mshied_graduationdate",
      datatype: "string",
    },
    ctpt_yearofqualification: {
      title: "Year of Qualification",
      type: "string",
      schemaname: "ctpt_yearofqualification",
      datatype: "string",
      views: ["year"],
      format: "yyyy",
    },
  },
  definitions: {
    degree: {
      title: "",
      type: "object",
      properties: {
        _mshied_degreeid__value: {
          title: "Name of Degree/Diploma/Qualification program",
          type: "string",
          schemaname: "_mshied_degreeid__value",
          datatype: "array",
          requiredCheck: true,
          tootltipText:
            "Inline help text can appear in association with a field. It expands to show on selecting ?Help? and can be collapsed by selecting ?Close?",
          helpText: "",
          isDynamic: true,
          apiURL: "/api/GetAllAsync",
          payload: {
            properties: [
              "mshied_name",
              "mshied_programlevelid",
              "ctpt_displayflag",
            ],
            filter: {
              globalfilter: "",
              globalAndOperator: false,
              fieldAndOperator: true,
              filterfields: [
                {
                  field: "statecode",
                  searchvalue: 0,
                  type: "guid",
                },
              ],
            },
            Aggregate: {
              AggregateFields: [],
            },
            sortfield: {
              field: "mshied_name",
              sorttype: "asc",
            },
            recordtype: "mshied_programlevels",
            idfield: "mshied_programlevelid",
            continuationToken: "",
            pazesize: "1000",
          },
          enum: [],
          enumNames: [],
        },
      },
      dependencies: {
        _mshied_degreeid__value: {
          oneOf: [
            {},
            {
              properties: {
                _mshied_degreeid__value: {
                  enum: ["fe67ad1e-b5fb-ec11-82e5-00224881f7ec"],
                },
                ctpt_otherdegreeid_: {
                  title: "Specify",
                  type: "string",
                  requiredCheck: true,
                  datatype: "string",
                  helpText: "",
                  defaultValue: "",
                  pattern: "",
                },
              },
            },
          ],
        },
      },
    },
    discipline: {
      title: "",
      type: "object",
      properties: {
        _ctpt_discipline_value: {
          title: "Discipline",
          schemaname: "_ctpt_discipline_value",
          type: "string",
          datatype: "array",
          requiredCheck: true,
          helpText: "",
          isDynamic: true,
          apiURL: "/api/GetAllAsync",
          payload: {
            properties: ["ctpt_name", "ctpt_disciplineid", "ctpt_displayflag"],
            filter: {
              globalfilter: "",
              globalAndOperator: false,
              fieldAndOperator: true,
              filterfields: [
                {
                  field: "statecode",
                  searchvalue: 0,
                  type: "guid",
                },
              ],
            },
            Aggregate: {
              AggregateFields: [],
            },
            sortfield: {
              field: "ctpt_name",
              sorttype: "asc",
            },
            recordtype: "ctpt_disciplines",
            idfield: "ctpt_disciplineid",
            continuationToken: "",
            pazesize: "1000",
          },
          enum: [],
          enumNames: [],
        },
      },
      dependencies: {
        _ctpt_discipline_value: {
          oneOf: [
            {},
            {
              properties: {
                _ctpt_discipline_value: {
                  enum: ["5db0d663-bafb-ec11-82e5-00224881f7ec"],
                },
                ctpt_otherdiscipline: {
                  title: "Specify",
                  type: "string",
                  requiredCheck: true,
                  datatype: "string",
                  helpText: "",
                  defaultValue: "",
                  pattern: "",
                },
              },
            },
          ],
        },
      },
    },
    institution: {
      title: "",
      type: "object",
      properties: {
        _ctpt_institutionname_value: {
          title: "Institution Name",
          schemaname: "ctpt_InstitutionName",
          type: "string",
          datatype: "array",
          requiredCheck: true,
          tootltipText:
            "Inline help text can appear in association with a field. It expands to show on selecting ?Help? and can be collapsed by selecting ?Close?",
          helpText: "",
          isDynamic: true,
          apiURL: "/api/GetAllAsync",
          payload: {
            properties: [
              "ctpt_name",
              "ctpt_institutionnameid",
              "ctpt_displayflag",
            ],
            filter: {
              globalfilter: "",
              globalAndOperator: false,
              fieldAndOperator: true,
              filterfields: [
                {
                  field: "statecode",
                  searchvalue: 0,
                  type: "guid",
                },
              ],
            },
            Aggregate: {
              AggregateFields: [],
            },
            sortfield: {
              field: "ctpt_name",
              sorttype: "asc",
            },
            recordtype: "ctpt_institutionnames",
            idfield: "ctpt_institutionnameid",
            continuationToken: "",
            pazesize: "1000",
          },
          enum: [],
          enumNames: [],
        },
      },
      dependencies: {
        _ctpt_institutionname_value: {
          oneOf: [
            {},
            {
              properties: {
                _ctpt_institutionname_value: {
                  enum: ["e47b482f-a7fb-ec11-82e5-00224881f1d4"],
                },
                ctpt_otherinstitutionname: {
                  title: "Specify",
                  type: "string",
                  requiredCheck: true,
                  datatype: "string",
                  helpText: "",
                  defaultValue: "",
                  pattern: "",
                },
              },
            },
          ],
        },
      },
    },
  },
};
