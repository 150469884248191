// Regex for southAfrican id
const re = /^\d{2}[0-1][0-9][0-3]\d\d{4}[0-1]\d{2}$/;

// Helper to generate checksum for South African ID numbers
const mod10CheckDigit = (numbers) => {
  if (typeof numbers !== "string") {
    throw new Error("Invalid number provided");
  }

  const doubledSum = numbers
    .split("")
    .map((val, index) => {
      if ((index + numbers.length) % 2 !== 0) {
        const double = Number.parseInt(val) * 2;
        return double > 9 ? double - 9 : double;
      }

      return val;
    })
    .reduce((acc, curr) => Number.parseInt(acc) + Number.parseInt(curr));

  return (doubledSum * 9) % 10;
};

/**
 * ## Validate South African Id number
 * A South African ID number is a 13-digit number which is defined by the following format: YYMMDDSSSSCAZ.
 * - The first 6 digits (YYMMDD) are based on your date of birth. 20 February 1992 is displayed as 920220.
 * - The next 4 digits (SSSS) are used to define your gender.  Females are assigned numbers in the range 0000-4999 and males from 5000-9999.
 * - The next digit (C) shows if you're an SA citizen status with 0 denoting that you were born a SA citizen and 1 denoting that you're a permanent resident.
 * - The last digit (Z) is a checksum digit – used to check that the number sequence is accurate using a set formula called the Luhn algorithm.
 * - The graphic below details the different sections of an ID number, based on the fictitious sequence 9202204720082
 *
 * - https://www.westerncape.gov.za/general-publication/decoding-your-south-african-id-number-0
 * 
 * **Follow the user story #94546, for more details**
 *
 * @param {string} id
 * @returns {string|null}
 */
const isValidSouthAfricanId = (id) => {
  // check the pattern and checksum of the id number input
  const isValidId =
    re.test(id) &&
    mod10CheckDigit(id.slice(0, id.length - 1)) ===
      Number.parseInt(id[id.length - 1]);

  // check the length of the id number input
  if (!id || id.length !== 13) {
    return "ID should be 13 digits long";
  }

  // verify if it is a valid South African ID number, Referred rules from #Task 94546
  if (id && id.length === 13 && !isValidId) {
    return "Please enter a valid South African ID number";
  }

  return null;
};

module.exports = { isValidSouthAfricanId };
