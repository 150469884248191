export const GOVUK = "govuk";
export const MUI = "mui";
export const ANT = "ant";

export const settings = {
  UI: MUI,
};

//export const transactionCurrencyBWP = "4055b28f-4806-ed11-82e5-000d3aaf53be";
export const transactionCurrencyBWP = "e9e1569e-768f-ed11-aad0-000d3ab9473a";

export const CAPS_LOCK_WARNING =
  "When completing information, please capitalise proper nouns and the first word of a sentence only. Kindly refrain from completing information in all capital letters.";
