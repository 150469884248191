import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
   
    
  },
  headingTxt:{
    color:theme.palette.success.light
  },
  failureHeadingTxt:{
    color:theme.palette.error.light
  },
  desc:{
    background:theme.palette.midlightBg.main,
    padding:'10px'
  },
  paymentWrapper:{
    border:'1px solid grey',
    padding:'10px'
  },
  tableDesc:{
    display:'block',
    padding:'10px 0',
  },
  tablePrice:{
    marginLeft:'auto',
    padding:'10px 0',
  },
  total:{
    borderTop:'1px solid black',
    padding:'10px 0',
    fontWeight:'800'
  }

}));