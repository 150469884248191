export const uploadCVSchema = {
  schema: {
    title: "",
    helpText: "",
    tootltipheading: "head",
    tootltipText: "text",
    type: "string",
    datatype: "upload",
    format: "string",
    requiredCheck: false,
    containerName: "contacts",
    fileName: "id",
    extensions: ".doc,.pdf",
    acceptType: [
      "application/pdf",
      "application/msword",
      "image/png",
      "image/jpeg",
      "image/jpg",
    ],
    acceptExtension: [".pdf", ".doc", ".docx", ".png", ".jpg",".jpeg"],
    recordPath: "",
    onChange: {},
  },
};

export const uploadIdentity = {
  schema: {
    title: "",
    helpText: "",
    tootltipheading: "head",
    tootltipText: "text",
    type: "string",
    datatype: "upload",
    format: "string",
    requiredCheck: false,
    containerName: "contacts",
    fileName: "id",
    extensions: ".doc,.pdf",
    acceptType: [
      "application/pdf",
      "application/msword",
      "image/png",
      "image/jpeg",
      "image/jpg",
    ],
    acceptExtension: [".pdf", ".doc", ".docx", ".png", ".jpg",".jpeg"],
    recordPath: "",
    onChange: {},
  },
};
