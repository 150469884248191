export const getRegistrationPayload = (contactID, examID) => {
  return {
    recordtype: "msmedia_mediaregistrations",
    data: [
      {
        name: "msmedia_Contact",
        value: contactID,
        type: "lookup",
        relatedentity: "contacts",
      },
      {
        name: "msmedia_MediaEvent",
        value: examID,
        type: "lookup",
        relatedentity: "msmedia_mediaevents",
      },
      {
        name: "msmedia_registrationstatus",
        value: 766860003,
        type: "picklist",
      },
    ],
  };
};
