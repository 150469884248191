const DO_NOT_MAP_ADDRESS = 727530003;

export const createChannelPayload = (
  contactID,
  addressType,
  formattedFormData,
  mapAddressTo
) => ({
  recordtype: "ctpt_channels",
  data: [
    {
      name: "ctpt_methodtype",
      type: "picklist",
      value: 727530000, // Address type id
    },
    {
      name: "ctpt_CustomerId_contact",
      value: contactID,
      type: "lookup",
      relatedentity: "contacts",
    },
    {
      name: "ctpt_mapaddressto",
      type: "picklist",
      value: mapAddressTo || DO_NOT_MAP_ADDRESS,
    },
    {
      name: "ctpt_addresstype",
      value: addressType,
      type: "picklist",
    },
    ...formattedFormData,
  ],
});
