import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "50rem",
    padding: "3rem 0rem",
    "& div[class^=MuiTabs-flexContainer]": {
      margin: "0 5rem 3rem 5rem",
    },
    '& div[data-rowIndex][role="row"]': {
      //risky sizing code starts here
      minHeight: "60px !important",
      height: 60,
      "& div": {
        minHeight: "60px !important",
        height: 60,
      },
      "& div[class^=MuiDataGrid-cell]": {
        fontSize: 14,
        lineHeight: "19px !important",
        whiteSpace: "unset !important",
        textOverflow: "unset !important",
        paddingTop: "10px",
      },
    },
  },
  root: {},
}));
