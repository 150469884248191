export const navbarList = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Edit Record",
    href: "/update",
  },
  {
    label: "Contact",
    href: "/contact",
  },
  {
    label: "Sonic Web Form",
    href: "/sonicform",
  },
  {
    label: "Sonic Web Form Update",
    href: "/sonicformupdate",
  },
];

export const navbarItems = [
  {
    logo: {
      imageUrl: "",
      altText: "",
      text: "Teleportal",
    },
    items: [
      {
        labelText: "Alert",
        linkUrl: "/",
        iconUrl: "notifications",
      },
      {
        labelText: "My Profile",
        linkUrl: "/profile",
        iconUrl: "account_circle",
      },
    ],
  },
];

export const footerItems = {
  logo: {
    imageUrl: "",
    altText: "",
    text: "Teleportal",
  },
  verticalSections: [
    {
      title: "About SAICE",
      items: [
        {
          labelText: "About Us",
          linkUrl: "https://saice.org.za/about-us/",
        },
        {
          labelText: "Corporate Governance",
          linkUrl: "https://saice.org.za/corporate-governance/",
        },
        {
          labelText: "Member Benefits",
          linkUrl: "https://saice.org.za/membership/",
        },
        {
          labelText: "SAICE Connect",
          linkUrl: "https://saice.org.za/saice-connect/",
        },
        {
          labelText: "SAICE Academy",
          linkUrl: "https://store.saice.org.za/courses",
        },
        {
          labelText: "SAICE Bookshop",
          linkUrl: "https://saice.org.za/booklist/",
        },
        {
          labelText: "Civil Engineering Magazine",
          linkUrl: "https://saice.org.za/civil-engineering-magazine/",
        },
        {
          labelText: "SAICE Membership Terms and Conditions",
          linkUrl:
            "https://saice.org.za/saice-membership-terms-and-conditions/",
        },
      ],
    },
    {
      title: "Useful Links",
      items: [
        {
          labelText: "SAICE Journal",
          linkUrl: "https://saice.org.za/saice-journal-publication-issues/",
        },
        {
          labelText: "SAICE Newsletters",
          linkUrl: "https://saice.org.za/past-newsletters/",
        },
        {
          labelText: "SPEBS",
          linkUrl: "https://saice.org.za/spebs/",
        },
        {
          labelText: "Events",
          linkUrl: "https://saice.org.za/awards-2022/",
        },
        {
          labelText: "SAICE Branches",
          linkUrl: "https://saice.org.za/saice-branches/",
        },
        {
          labelText: "SAICE Divisions",
          linkUrl: "https://saice.org.za/saice-divisions/",
        },
      ],
    },
  ],
  horizontalSection: {
    title: "",
    items: [
      {
        labelText: "Privacy Policy",
        linkUrl:
          "https://saice.org.za/wp-content/uploads/2021/08/SAICE-PRIVACY-NOTICE.pdf",
        iconUrl: "Notifications",
      },
      {
        labelText: "LinkedIn",
        linkUrl: "https://za.linkedin.com/company/saice",
        iconUrl: "LinkedIn",
      },
      {
        labelText: "Facebook",
        linkUrl: "https://www.facebook.com/SAICECIVIL/?ref=share",
        iconUrl: "Facebook",
      },
      {
        labelText: "Twitter",
        linkUrl: "https://twitter.com/saice_civil",
        iconUrl: "Twitter",
      },
      {
        labelText: "Youtube",
        linkUrl: "https://www.youtube.com/channel/UCbw84Acgq_brBuibOmBeKBQ",
        iconUrl: "Youtube",
      },
      {
        labelText: "Instagram",
        linkUrl: "https://www.instagram.com/saicecivil/",
        iconUrl: "Instagram",
      },
    ],
  },
};
