import { CAPS_LOCK_WARNING } from "constants/settings";

const thisDate = new Date().toISOString().split("T")[0];

export const contactSchema = {
  title: "Personal Information",
  logicalname: "Contact",
  type: "object",
  description:
    "Update your personal information using the form below and click Save",
  additionalDescription: CAPS_LOCK_WARNING,
  //required: ["name", "address", "primaryemail", "primarycontact"],
  properties: {
    ctpt_title: {
      title: "Title",
      type: "string",
      schemaname: "salutation",
      datatype: "string",
      requiredCheck: true,
      enum: ["1", "2", "3", "4", "5", "6", "7", "9", "19", "100000000", "21"],
      enumNames: [
        "Mr",
        "Miss",
        "Mrs",
        "Mx",
        "Dr",
        "Prof",
        "Adv",
        "Eur Ing",
        "Sir",
        "Advocate",
        "Ms",
      ],
    },
    firstname: {
      title: "First Name",
      type: "string",
      schemaname: "firstname",
      datatype: "string",
      requiredCheck: true,
      pattern: '^[^ ].{0,}$',
      message: "Only string allowed",
    },
    lastname: {
      title: "Last Name",
      type: "string",
      datatype: "string",
      requiredCheck: true,
      pattern: '^[^ ].{0,}$',
      message: "Only string allowed",
    },
    // fullname: {
    //   title: "Full Name",
    //   type: "string",
    //   datatype: "string",
    //   requiredCheck: true,
    //   pattern: "^[a-zA-Z' ']*$",
    //   message: "Only string allowed",
    // },
    middlename: {
      title: "Middle Name",
      type: "string",
      datatype: "string",
      pattern: '^[^ ].{0,}$',
      message: "Only string allowed",
    },
    ctpt_specialists: {
      type: "array",
      items: {
        isDynamic: true,
        apiURL: "/api/GetAllAsync",
        payload: {
          properties: ["ctpt_name", "ctpt_specialistid", "ctpt_displayflag"],
          filter: {
            globalfilter: "",
            globalAndOperator: false,
            fieldAndOperator: false,
            filterfields: [],
          },
          Aggregate: {
            AggregateFields: [],
          },
          sortfield: {
            field: "ctpt_name",
            sorttype: "asc",
          },
          recordtype: "ctpt_specialists",
          idfield: "ctpt_specialistid",
          continuationToken: "",
          pazesize: "1000",
        },
        title: "Specialist",
        requiredCheck: false,
        helpText: "",
        type: "string",
        enum: [],
        enumNames: [],
      },
      uniqueItems: true,
    },
    // ctpt_countryofbirth: {
    //   title: "Date of birth",
    //   type: "string",
    //   datatype: "number"
    // },
    birthdate: {
      title: "Date of birth",
      type: "string",
      datatype: "number",
      requiredCheck: true,
      disableFuture: true,
    },
    // fax: {
    //   title: "Fax Number",
    //   type: "string",
    //   datatype: "string",
    // },
    ctpt_areyouacitizenofsouthafrica: {
      title: "Are you currently residing in South Africa ?",
      type: "string",
      enum: ["True", "False"],
      enumNames: ["Yes", "No"],
      default: "True",
    },
    ctpt_profiledetailsconfirmedaccurateon: {
      title: "update date",
      type: "string",
      datatype: "string",
      default: thisDate,
    },
  },
};

export const kinContactSchema = {
  title: "Next of Kin Information",
  logicalname: "Contact",
  type: "object",
  description:
    "Update next of Kin information using the form below and click Save",
  additionalDescription: CAPS_LOCK_WARNING,
  //required: ["name", "address", "primaryemail", "primarycontact"],
  properties: {
    ctpt_nextofkinsurname: {
      title: "Surname",
      type: "string",
      schemaname: "ctpt_nextofkinsurname",
      datatype: "string",
      requiredCheck: true,
      pattern: '^[^ ].{0,}$',
      message: "Only string allowed",
    },
    ctpt_nextofkinfirstname: {
      title: "First Name",
      type: "string",
      datatype: "string",
      requiredCheck: true,
      pattern: '^[^ ].{0,}$',
      message: "Only string allowed",
    },
    ctpt_nextofkinsecondname: {
      title: "Second Name",
      type: "string",
      datatype: "string",
      requiredCheck: false,
      pattern: '^[^ ].{0,}$',
      message: "Only string allowed",
    },
    ctpt_nextofkinemail: {
      title: "Email Address",
      type: "string",
      datatype: "string",
      requiredCheck: true,
      pattern:
        "^[a-zA-Z0-9.\\-_]+(\\.[a-zA-Z0-9\\-_]+)*@[a-z0-9]+(\\-[a-z0-9]+)*(\\.[a-z0-9]+(\\-[a-z0-9]+)*)*\\.[a-z]{2,4}$",
      message: "Enter a valid Email Address",
    },
    ctpt_nextofkinmobilenumber: {
      title: "Mobile Number",
      type: "string",
      datatype: "string",
      requiredCheck: true,
      pattern: "^[+]?([ 0-9]){10,}$",
      message: "Enter minimum of 10 digits",
    },

    // ctpt_nextofkinrelationship: {
    //   title: "Relationship",
    //   type: "string",
    //   schemaname: "ctpt_nextofkinrelationship",
    //   datatype: "string",
    //   requiredCheck: true,
    //   enum: [
    //     "533910000",
    //     "533910001",
    //     "533910002",
    //     "533910003",
    //     "533910004"
    //   ],
    //   enumNames: [
    //     "Mother",
    //     "Father",
    //     "Brother",
    //     "Sister",
    //     "Other"
    //   ]
    // },
    relationshipConditional: {
      $ref: "#/definitions/relationshipSelect",
    },
  },
  definitions: {
    relationshipSelect: {
      title: "",
      type: "object",
      properties: {
        ctpt_nextofkinrelationship: {
          title: "Relationship",
          type: "string",
          schemaname: "ctpt_nextofkinrelationship",
          datatype: "string",
          requiredCheck: true,
          enum: [
            "100000000",
            "533910002",
            "533910001",
            "100000001",
            "533910000",
            "533910004",
            "533910003",
            "100000002",
            "100000003",
          ],
          enumNames: [
            "Aunt",
            "Brother",
            "Father",
            "Grandparent",
            "Mother",
            "Other",
            "Sister",
            "Spouse",
            "Uncle",
          ],
        },
      },
      dependencies: {
        ctpt_nextofkinrelationship: {
          oneOf: [
            {},
            {
              properties: {
                ctpt_nextofkinrelationship: {
                  enum: ["533910004"],
                },
                ctpt_nextofkinother: {
                  title: "Specify",
                  type: "string",
                  requiredCheck: true,
                  datatype: "string",
                  helpText: "",
                  defaultValue: "",
                  pattern: "",
                },
              },
            },
          ],
        },
      },
    },
  },
};
