import Upload from "app/components/atoms/upload";
import CustomTextBox from "app/components/atoms/customTextBox";
import CustomCheckBox from "app/components/atoms/customCheckBox";
import CustomRadio from "app/components/atoms/customRadio";
import CustomDate from "app/components/atoms/customDate";
import CustomSelect from "app/components/atoms/customSelect";
import CustomButton from "app/components/atoms/customButton";
import MultipleSelect from "app/components/atoms/multiDropdown/MultipleSelect.mui";

const CustomUpload = ({ getProps }) => {
  return (
    <Upload
      recordType={getProps.schema.containerName}
      recordPath={getProps.schema.recordPath}
      getProps={getProps}
    />
  );
};

export const uiSchema = {
  "ui:title": "",
  idOrPassportDoc: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  title: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  surname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  surName: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  firstName: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  secondName: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  employerName: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  employmentsector: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  positionHeld: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  dateOfBirth: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  citizenofsaConditional: {
    citizenRadio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    typeOfIdentity: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    citizenid: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  nationality: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  race: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  gender: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  disabilityConditional: {
    disability: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    othersspecify: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },

  contactDetails: {
    email: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    altEmail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    mobileNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    workNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    altContactNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    homeContactNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },

  residentialAddress: {
    addressLine1: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    addressLine2: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    suburb: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    City: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    postalCode: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    country: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    branchConditional: {
      branchSelect: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      branch: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
    },
  },
  postaladdress: {
    sameAsAboveConditional: {
      sameAsAboveRadio: {
        "ui:widget": (props) => {
          return <CustomRadio getProps={props} />;
        },
      },
      addressLine1: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      addressLine2: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      suburb: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      City: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      postalCode: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      country: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
    },
  },
  membershipSection: {
    membershipRadio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    companyName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    billingContactName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    vatNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    billingEmail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    addressLine1: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    addressLine2: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    suburb: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    City: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    postalCode: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    country: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    billingMethod: {
      billingMethodRadio: {
        "ui:widget": (props) => {
          return <CustomRadio getProps={props} />;
        },
      },
      billingEmail1: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      billingAddress: {
        billingAddressRadio: {
          "ui:widget": (props) => {
            return <CustomRadio getProps={props} />;
          },
        },
        billingAddressConditional: {
          billingAddressConditionalRadio: {
            "ui:widget": (props) => {
              return <CustomRadio getProps={props} />;
            },
          },
          addressLine1: {
            "ui:widget": (props) => {
              return <CustomTextBox getProps={props} />;
            },
          },
          addressLine2: {
            "ui:widget": (props) => {
              return <CustomTextBox getProps={props} />;
            },
          },
          suburb: {
            "ui:widget": (props) => {
              return <CustomTextBox getProps={props} />;
            },
          },
          City: {
            "ui:widget": (props) => {
              return <CustomTextBox getProps={props} />;
            },
          },
          postalCode: {
            "ui:widget": (props) => {
              return <CustomTextBox getProps={props} />;
            },
          },
          country: {
            "ui:widget": (props) => {
              return <CustomSelect getProps={props} />;
            },
          },
        },
      },
    },
  },
  tertiaryEducation: {
    educationalInstitution: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    institutionConditional: {
      tertiaryInstitution: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      otherInstitution: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
    },
    nameOfQualificationConditional: {
      nameOfQualification: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      othernameOfQualification: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
    },
    desciplineConditional: {
      descipline: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      otherDescipline: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
    },
    nameOfQualification: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    dateObtained: {
      "ui:widget": (props) => {
        return <CustomDate getProps={props} />;
      },
    },
    descipline: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    country: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    degreeCertificate: {
      "ui:widget": (props) => {
        return <CustomUpload getProps={props} />;
      },
    },
  },
  addAnothertertiaryEducation: {
    items: {
      educationalInstitution: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      institutionConditional: {
        tertiaryInstitution: {
          "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
          },
        },
        otherInstitution: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
      },

      nameOfQualificationConditional: {
        nameOfQualification: {
          "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
          },
        },
        othernameOfQualification: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
      },
      desciplineConditional: {
        descipline: {
          "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
          },
        },
        otherDescipline: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
      },

      nameOfQualification: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      dateObtained: {
        "ui:widget": (props) => {
          return <CustomDate getProps={props} />;
        },
      },
      descipline: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      country: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      degreeCertificate: {
        "ui:widget": (props) => {
          return <CustomUpload getProps={props} />;
        },
      },
    },
  },
  organisation: {
    organisationName: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    organisationNameConditional: {
      organisationName: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      otherOrganisationName: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
    },
    registrationCategoryConditional: {
      registrationCategory: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      otherRegistrationCategory: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
    },

    organisationNationality: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    registrationCategory: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    yearOfElection: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    registrationNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    registrationCertificate: {
      "ui:widget": (props) => {
        return <CustomUpload getProps={props} />;
      },
    },
  },
  addAnotherOrganisation: {
    items: {
      organisationName: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      organisationNameConditional: {
        organisationName: {
          "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
          },
        },
        otherOrganisationName: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
      },
      registrationCategoryConditional: {
        registrationCategory: {
          "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
          },
        },
        otherRegistrationCategory: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
      },
      organisationNationality: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      registrationCategory: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      yearOfElection: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      registrationNumber: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      registrationCertificate: {
        "ui:widget": (props) => {
          return <CustomUpload getProps={props} />;
        },
      },
    },
  },
  divisionRegistration: {
    divisions: {
      "ui:widget": (props) => {
        return <MultipleSelect getProps={props} />;
      },
    },
  },
  supplementaryDocuments: {
    idOrPassportDoc: {
      "ui:widget": (props) => {
        return <CustomUpload getProps={props} />;
      },
    },
    proofOfRegistration: {
      "ui:widget": (props) => {
        return <CustomUpload getProps={props} />;
      },
    },
    addAnotherProofOfRegistration: {
      items: {
        proofOfRegistration: {
          "ui:widget": (props) => {
            return <CustomUpload getProps={props} />;
          },
        },
      },
    },
  },
  codeOfEthicsLink: {
    "ui:widget": (props) => {
      return (
        <a href={props.schema.uri} target="_blank" rel="noreferrer">
          {props.label}
        </a>
      );
    },
  },
  constitutionAndBylawsLink: {
    "ui:widget": (props) => {
      console.log("constitutionAndBylawsLink", props);
      return (
        <a href={props.schema.uri} target="_blank" rel="noreferrer">
          {props.label}
        </a>
      );
    },
  },
  iHaveReadAllInfo: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  privacyInfo: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  noteInfo: {
    "ui:widget": (props) => {
      return <p>{props.schema.title}</p>;
    },
  },
  currentDate: {
    "ui:widget": (props) => {
      return <p>{props.schema.title + ": " + new Date().toDateString()}</p>;
    },
  },
};
