import { CAPS_LOCK_WARNING } from "constants/settings";

export const getStudentSchema = {
  title: "Student Training Agreement",
  logicalname: "Contact2",
  type: "object",
  description: CAPS_LOCK_WARNING,
  properties: {
    
    ctpt_name: {
      title: "Student Name",
      type: "string",
      schemaname: "ctpt_name",
      datatype: "string",
      helpText:"",
      requiredCheck:true,
      pattern:"^[a-zA-Z' ']*$",
      message:"Only string allowed"
      
    },
    
    ctpt_startdate: {
      title: "Start Date",
      type: "string",
      schemaname: "ctpt_startdate",
      datatype: "string",
      requiredCheck:true,
      message:"Please select start date",
      pattern:""
    },
    ctpt_duration: {
      title: "Duration (months)",
      type: "string",
      schemaname: "ctpt_duration",
      datatype: "string",
      helpText:"",
      requiredCheck:true,
      message:"Please enter number",
      pattern:"^[[0-9]*]{0,9}$"
    },
    ctpt_enddate: {
      title: "End Date",
      type: "string",
      schemaname: "ctpt_enddate",
      datatype: "string",
      helpText:"End Date should be manually inputted as the addition of Start Date with Duration",
      requiredCheck:true,
      message:"Please select end date",
      pattern:""
    },
    ctpt_documenturl: {
      title: "Attach document",
      type: "string",
      schemaname: "ctpt_documenturl",
      datatype: "upload",
      helpText: "",
      tootltipheading: "",
      requiredCheck: false,
      format: "string",
      containerName: "channels",
      fileName: "studentRegister",
      acceptExtension: [
        ".pdf",
        ".doc",
        ".docx",
        ".png",
        ".jpeg",
        ".xlsx",
        ".xls",
        ".csv",
      ],
      acceptType: [
        "application/pdf",
        "application/msword",
        "image/png",
        "image/jpeg",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "application/csv",
      ],
      recordPath: "/",
      requireBoburl: true,
    },
  }
};

