import { CAPS_LOCK_WARNING } from "constants/settings";

export const workAddressSchema = {
  title: "Edit Billing Address",
  logicalname: "Channel",
  type: "object",
  additionalDescription: CAPS_LOCK_WARNING,
  //required: ["name", "address", "primaryemail", "primarycontact"],
  properties: {
    ctpt_companyname: {
      title: "Company Name",
      type: "string",
      datatype: "string",
      requiredCheck: false,
      pattern: "^[a-zA-Z0-9 ',./-]*$",
      message: "Only string allowed",
    },
    ctpt_billingcontactperson: {
      title: "Billing Contact Person",
      type: "string",
      datatype: "string",
      requiredCheck: false,
      pattern: "^[a-zA-Z0-9 ',./-]*$",
      message: "Only string allowed",
    },
    ctpt_vatnumber: {
      title: "VAT Number",
      type: "string",
      datatype: "string",
      requiredCheck: false,
      pattern: "^[a-zA-Z0-9 ',./-]*$",
      message: "Only string allowed",
    },
    ctpt_line1: {
      title: "Address Line 1",
      type: "string",
      datatype: "string",
      requiredCheck: true,
      pattern: "^[a-zA-Z0-9 ',./-]*$",
      message: "Only string allowed",
    },
    ctpt_line2: {
      title: "Address Line 2",
      type: "string",
      datatype: "string",
      requiredCheck: false,
      pattern: "^[a-zA-Z0-9 ',./-]*$",
      message: "Only string allowed",
    },
    ctpt_county: {
      title: "Suburb",
      type: "string",
      datatype: "string",
      requiredCheck: false,
      pattern: "^[a-zA-Z0-9 ]*$",
      message: "Only string allowed",
    },
    ctpt_city: {
      title: "City",
      type: "string",
      datatype: "string",
      requiredCheck: true,
      pattern: "^[a-zA-Z_ ]*$",
      message: "Only letters allowed",
    },
    ctpt_postalcode: {
      title: "Postal Code",
      type: "string",
      datatype: "string",
      requiredCheck: true,
      pattern: "^[a-zA-Z0-9]{4,8}$",
      message: "Enter correct postal code",
    },
    ctpt_country: {
      title: "Country",
      type: "string",
      datatype: "string",
      requiredCheck: true,
      isCountry: true,
      isDynamic: true,
      apiURL: "/api/GetAllAsync",
      payload: {
        properties: ["ctpt_name", "ctpt_countryid", "ctpt_displayflag"],
        filter: {
          globalfilter: "",
          globalAndOperator: false,
          fieldAndOperator: true,
          filterfields: [
            {
              field: "statecode",
              searchvalue: 0,
              type: "guid",
            },
          ],
        },
        Aggregate: {
          AggregateFields: [],
        },
        sortfield: {
          field: "ctpt_name",
          sorttype: "asc",
        },
        recordtype: "ctpt_countries",
        idfield: "ctpt_countryid",
        continuationToken: "",
        pazesize: "1000",
      },
      enum: [],
      enumNames: [],
    },
  },
};
