export const getContactDetailsAPIPayload = (contactId) => ({
  properties: [
    "contactid",
    "emailaddress1",
    "ctpt_membershipclass",
    "mobilephone",
    "ctpt_lastapplicationapprovedon",
    "address1_composite",
    "statecode",
    "firstname",
    "ctpt_profiledetailsconfirmedaccurateon",
    "ctpt_contacttype",
    "ctpt_membershipnumber",
    "ctpt_datejoined",
    "ctpt_onhold",
    "ctpt_terminationtype",
    "ctpt_dateterminated",
  ],
  formattedFields: ["ctpt_datejoined"],
  recordType: "contacts",
  id: contactId,
});

export const getConfig = {
  properties: ["ctpt_value"],
  formattedFields: [],
  recordType: "ctpt_configurationitems",
  filters: [
    {
      field: "ctpt_name",
      searchvalue: "ProfileExpiryDuration",
      type: "string",
    },
  ],
  FieldAndOperator: "false",
  idfield: "ctpt_configurationitemid",
};

export const getServiceDetailsOptionsAPIPayload = {
  properties: ["ctpt_name", "ctpt_casetypeid", "ctpt_displayflag"],
  sortfield: {
    field: "ctpt_name",
    sorttype: "asc",
  },
  recordtype: "ctpt_casetypes",
  idfield: "ctpt_casetypeid",
  continuationToken: "",
  pazesize: "1000",
};
