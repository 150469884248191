import moment from "moment";

const validateDates = (formData, errors) => {
  if (formData.ctpt_startdate && formData.ctpt_enddate) {
    const startDate = moment(formData.ctpt_startdate);
    const endDate = moment(formData.ctpt_enddate);
    if (startDate.isSameOrAfter(endDate)) {
      errors.ctpt_enddate.addError(
        "End Date should be greater than Start Date"
      );
    }
  }
};

export const customValidate = (formData, errors) => {
  validateDates(formData, errors);
  return errors;
};
