export const msalConfig = {
  auth: {
    clientId: "fe6073d9-a636-41ce-99be-484104e8b715",
    authority: "https://prodsaiceb2c.b2clogin.com/prodsaiceb2c.onmicrosoft.com",
    knownAuthorities: ["prodsaiceb2c.b2clogin.com"],
    redirectUri: "https://memberportal.saice.org.za/",
    postLogoutRedirectUri: "https://memberportal.saice.org.za/",
    //redirectUri: "https://memberportal.saice.org.za/",
    //postLogoutRedirectUri: "https://memberportal.saice.org.za/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const forgotPasswordRequest = {
  authority:
    "https://prodsaiceb2c.b2clogin.com/prodsaiceb2c.onmicrosoft.com/B2C_1_signup",
};

export const resetPasswordRequest = {
  authority:
    "https://prodsaiceb2c.b2clogin.com/prodsaiceb2c.onmicrosoft.com/B2C_1_passReset",
};

export const editProfileRquest = {
  authority:
    "https://prodsaiceb2c.b2clogin.com/prodsaiceb2c.onmicrosoft.com/B2C_1_editProfile",
};
export const signupRequest = {
  authority:
    "https://prodsaiceb2c.b2clogin.com/prodsaiceb2c.onmicrosoft.com/B2C_1_registration",
};

export const loginRequest = {
  authority:
    "https://prodsaiceb2c.b2clogin.com/prodsaiceb2c.onmicrosoft.com/B2C_1_signup",
  scopes: [
    "openid",
    "profile",
    "https://prodsaiceb2c.b2clogin.com/api/demo.read",
  ],
};

export const logoutRequest = {
  authority:
    "https://prodsaiceb2c.b2clogin.com/prodsaiceb2c.onmicrosoft.com/B2C_1_signup",
};

export const tokenRequest = {
  authority:
    "https://prodsaiceb2c.b2clogin.com/prodsaiceb2c.onmicrosoft.com/B2C_1_signup",
  scopes: [
    "openid",
    "profile",
    "https://prodsaiceb2c.b2clogin.com/api/demo.read",
  ],
};

export const tokenRequestRegistration = {
  authority:
    "https://prodsaiceb2c.b2clogin.com/prodsaiceb2c.onmicrosoft.com/B2C_1_registration",
  scopes: [
    "openid",
    "profile",
    "https://prodsaiceb2c.b2clogin.com/api/demo.read",
  ],
};

export const edittokenRequest = {
  authority:
    "https://prodsaiceb2c.b2clogin.com/prodsaiceb2c.onmicrosoft.com/B2C_1_editProfile",
  scopes: [
    "openid",
    "profile",
    "https://prodsaiceb2c.b2clogin.com/api/demo.read",
  ],
};

export const graphConfig = {
  // graphMeEndpoint: "https://graph.microsoft.com/beta/me",
  graphMeEndpoint: "https://graph.windows.net/me?api-version=1.6",
};
