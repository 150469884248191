import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import NavBar from "app/components/molecules/navbar/NavBar.mui";
import Statusbar from "app/components/atoms/statusbar/Statusbar.mui";
import { useStyles } from "./style";
import { useSelector } from "react-redux";

const PrivateRoute = ({
  component: Component,
  siteNav,
  checkTermination = false,
  ...rest
}) => {
  const userEntitlement = useSelector((state) => state.entitleMents);
  //TODO: remove "Active" when account status this configured
  const accountStatus = "Active"; // useSelector((state) => state.accountStatus);
  console.log({ userEntitlement });
  const isAccountOnHold = useSelector((state) => state.isAccountOnHold);
  const isTerminated = useSelector((state) => state.isTerminated);
  const isAccountOnHoldOrTerminated = checkTermination
    ? isAccountOnHold || isTerminated
    : false;
  const classes = useStyles();
  let isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const renderProp = (props) => {
    if (
      inProgress &&
      inProgress !== InteractionStatus.Logout &&
      accountStatus !== "Active"
    ) {
      return <Redirect to={"/inactive"} />;
    }
    if (isAuthenticated) {
      let permissions = userEntitlement.find(
        (x) => x.route == props.match.path
      );

      // if (1 == 1) {
      if (
        !isAccountOnHoldOrTerminated &&
        permissions &&
        permissions.permissionsets != null &&
        permissions.permissionsets.length > 0 &&
        permissions.permissionsets[0] != null &&
        permissions.permissionsets[0].canread == "1"
      ) {
        return <Component {...props} />;
      } else return <Redirect to="/noaccess" />;
    } else if (inProgress === InteractionStatus.None) {
      return <Redirect to="/" />;
    } else {
      return null;
    }
  };
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <div className={classes.content}>
      <NavBar siteNav={siteNav} />
      <div
        className={siteNav === "visible" ? classes.withNav : classes.withoutNav}
      >
        <Statusbar />
        <Route {...rest} render={(props) => renderProp(props)} />
      </div>
    </div>
  );
};

export default PrivateRoute;
