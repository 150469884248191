import { post } from "../api";
import { createChannelPayload } from "./channelCreatePayload";
import { config } from "utilities/config";

const formatData = (data) =>
  data.reduce((acc, curr) => {
    const { name, value = "" } = curr;
    acc[name] = value;
    return acc;
  }, {});

const updateBillingDetails = async (jsonData, details) => {
  await post(config.teleportal.submitForm.Update, {
    id: jsonData.contactid,
    recordtype: "contacts",
    data: [
      {
        name: "ctpt_companyname",
        value: details.ctpt_companyname,
        type: "string",
      },
      {
        name: "ctpt_billingcontactperson",
        value: details.ctpt_billingcontactperson,
        type: "string",
      },
      {
        name: "ctpt_vatnumber",
        value: details.ctpt_vatnumber,
        type: "string",
      },
    ],
  });
};

export const WorkAddressPayload = async (JsonData) => {
  const details = formatData(JsonData.data);
  var payload;
  payload = {
    id: JsonData.recordId,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "ctpt_line1",
        value: details.ctpt_line1,
        type: "string",
      },
      {
        name: "ctpt_line2",
        value: details.ctpt_line2,
        type: "string",
      },
      {
        name: "ctpt_county",
        value: details.ctpt_county,
        type: "string",
      },
      {
        name: "ctpt_city",
        value: details.ctpt_city,
        type: "string",
      },
      {
        name: "ctpt_postalcode",
        value: details.ctpt_postalcode,
        type: "string",
      },
      {
        name: "ctpt_country",
        value: details.ctpt_country,
        type: "string",
      },
    ],
  };
  
  await updateBillingDetails(JsonData, details);
  
  if (!JsonData.recordId) {
    // INFO: theres no channel record to update, so create the channel record.
    return createChannelPayload(
      JsonData.contactid,
      727530002,
      payload.data,
      727530004
    );
  }
  return payload;
};
