export const getcurrentYeardivisionslistPayload = (contactid) => {
  var payload;
  payload = {properties: [
    "ctpt_division_contactid",
    "ctpt_divisionid",
    "contactid"
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: true,
    filterfields: [
      {
        field: "contactid",
        searchvalue: contactid,
        type: "guid"
    }
    ],
  },
  recordtype: "ctpt_division_contactset",
  idfield: "ctpt_division_contactid",
  continuationToken: "",
  pazesize: "10",
};
return payload
}

export const getnextYeardivisionslistPayload = (contactid) => {
  var payload;
  payload = {
    properties: [
    "ctpt_contact_ctpt_nextyeardivisionid",
    "ctpt_divisionid",
    "contactid"
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: false,
    filterfields: [
      {
          field: "contactid",
          searchvalue: contactid,
          type: "guid"
      }
    ],
  },
  recordtype: "ctpt_contact_ctpt_nextyeardivisionset",
  idfield: "ctpt_contact_ctpt_nextyeardivisionid",
  continuationToken: "",
  pazesize: "10",
};
return payload
}

export const GetDivisionListPayload = {
  properties: ["ctpt_divisionid", "ctpt_name"],
  recordType: "ctpt_divisions",
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: false,
    filterfields: [
      // {
      //     field: "_ctpt_contactid_value",
      //     searchvalue: "contactid",
      //     type: "guid"
      // }
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  sortfield: {
    field: "ctpt_name",
    sorttype: "asc",
  },
  idfield: "ctpt_divisionid",
  continuationToken: "",
  pazesize: "10",
};

export const AddDivisionPayload = (jsonData) => {
  const details = jsonData.data
  var payload;
  payload = {

      "recordtype": "contacts",
      "id":jsonData.contactid,
      "data": [
        {
          "name": "ctpt_Contact_ctpt_NextYearDivision",
          "manyToManyValues": [
            {
              "parentId": jsonData.contactid,//contactid
              "parentEntityName": "contacts",//same
              "childId": details[0].value,//divisionid
              "childEntityName": "ctpt_divisions",
              "type": "add" //send 'delete'
            }
          ],
          "relatedentity": "ctpt_Contact_ctpt_NextYearDivision",//
          "getRelatedEntity": "ctpt_contact_ctpt_nextyeardivisionset",//
          "type": "manytomany"
        }
      ]
  }
  return payload;
}

export const DeleteDivisionPayload = (contactId,divisionId) => {
  //const details = jsonData.data
  var payload;
  payload = {

      "recordtype": "contacts",
      "id":contactId,
      "data": [
        {
          "name": "ctpt_Contact_ctpt_NextYearDivision",
          "manyToManyValues": [
            {
              "parentId": contactId,//contactid
              "parentEntityName": "contacts",//same
              "childId": divisionId,//divisionid
              "childEntityName": "ctpt_divisions",
              "type": "delete" //send 'delete'
            }
          ],
          "relatedentity": "ctpt_Contact_ctpt_NextYearDivision",//
          "getRelatedEntity": "ctpt_contact_ctpt_nextyeardivisionset",//
          "type": "manytomany"
        }
      ]
  }
  return payload;
}

