//Import React Library
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { AuthenticatedTemplate } from "@azure/msal-react";

//Import Material-UI Library
import { ThemeProvider } from "@material-ui/core/styles";

//Loading Data and Style..
import { globalTheme } from "style/globalTheme";
import { useStyles } from "./style";
//Loading components
import { config } from "utilities/config";

import ListComponent from "app/components/mvp/listWidget";
import {
  getcurrentYeardivisionslistPayload,
  getnextYeardivisionslistPayload,
  AddDivisionPayload,
  DeleteDivisionPayload,
} from "./model/payload";
import { getDivisionListSchema } from "./model/schema";
import { getDivisionListUiSchema } from "./model/UiSchema";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TabWidget from "app/components/molecules/tab/TabWidget.mui";
import { post } from "app/services/api";

function Divisions(props) {
  const [divisionList, setDivisionList] = useState({});
  const classes = useStyles();
  const getcontactId = useSelector((state) => state.contactid);
  const history = useHistory();
  const getcontactName = useSelector((state) => state.user);

  useEffect(() => {
    getDivisionList();
  }, [getcontactId]);

  const getDivisionList = () => {
    let api;
    let payloads;
    //let ctpt_Specialist_Contact_Contact=[]
    let formData = {};
    api = config.teleportal.submitForm.get;
    payloads = {
      properties: ["ctpt_divisionid", "ctpt_name"],
      recordType: "ctpt_divisions",
      formattedFields: [],
      filter: {
        globalfilter: "",
        globalAndOperator: false,
        fieldAndOperator: false,
        filterfields: [],
      },
      Aggregate: {
        AggregateFields: [],
      },
      idfield: "ctpt_identityid",
      continuationToken: "",
      pazesize: "10",
    };

    post(api, payloads)
      .then((res) => {
        res.data.data.forEach((obj) => {
          formData[obj.data[0].value] = obj.data[1].value;
        });
        setDivisionList(formData);
      })
      .catch(console.log);
  };
  const currentYearcolumns = [
    {
      field: "ctpt_divisionid",
      flex: 1,
      headerName: "Name",
      renderCell: (params) => (
        <p style={{ marginLeft: "1rem" }}>{divisionList[params.value]}</p>
      ),
    },
  ];
  const nextYearcolumns = [
    {
      field: "ctpt_divisionid",
      flex: 1,
      headerName: "Name",
      renderCell: (params) => (
        <p style={{ marginLeft: "1rem" }}>{divisionList[params.value]}</p>
      ),
    },
  ];
  const currentYearoptions = {
    apiURL: config.teleportal.submitForm.get,
    //apiUrlDel: config.teleportal.file.delete,
    columns: currentYearcolumns,
    searchPayload: "searchFilter",
    getlistPayload: getcurrentYeardivisionslistPayload(getcontactId),
    deleteListPayload: {},
    listHeading: "Current Year Divisions",
    formWidget: {},
    nonRecord: false,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: false,
    filterField: "",
    filterValue: [],
    aggregateField: "",
    hideSearch: true,
    pageSize: 10,
    hasPayloadfilter: false,
    payloadModifyForbidden: true,
    hideAdd: true,
    controlLists: false,
  };

  const nextYearoptions = {
    apiURL: config.teleportal.submitForm.get,
    apiUrlDel: config.teleportal.submitForm.Update,
    columns: nextYearcolumns,
    searchPayload: "searchFilter",
    getlistPayload: getnextYeardivisionslistPayload(getcontactId),
    deleteListPayload: DeleteDivisionPayload,
    formwidgetOptions: {
      widgetType: "update",
      formSchema: getDivisionListSchema,
      uiSchema: getDivisionListUiSchema,
      apiURL: config.teleportal.submitForm.Update,
      entityType: "ctpt_divisions",
      formUischema: "",
      button1Label: "Add",
      button2Label: "Cancel",
      button3Label: "Clear",
      payloadSubmit: AddDivisionPayload,
      columns: nextYearcolumns,
      contactId: getcontactId,
      historyUrl: "",
    },
    listHeading: "Next Year Divisions",
    nonRecord: false,
    rows: "",
    localDataStore: true,
    contactId: getcontactId,
    hideSearch: true,
    pageSize: 10,
    hasPayloadfilter: true,
    hideModalOnSubmit: true,
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div class={classes.background1}></div>
        {Object.keys(divisionList).length && (
          <div id="cTTP_applicationList" class={classes.container}>
            <TabWidget
              options={{
                defaultTabID: 1,
                tabNavJson: [
                  {
                    label: "Current Year",
                    id: 1,
                    entity: (
                      <div class={classes.listSection}>
                        <p
                          style={{
                            maxWidth: "70%",
                            fontWeight: 600,
                            marginTop: "2rem",
                          }}
                        >
                          Things to note :
                        </p>
                        <ul>
                          <li>
                            Each member may select any number of technical
                            divisions of which one division is free.
                          </li>
                          <li>
                            Geotechnical division members are paying for ISSMGE
                            membership, which means that when members select
                            GEOTECHNICAL as their only division, they will still
                            have to pay the additional fee.
                          </li>
                          <li>
                            Members are required to make their changes to the
                            divisions before  1st October to take effect in the
                            next financial year.
                          </li>
                        </ul>
                        <ListComponent
                          options={currentYearoptions}
                          divisionList
                        />

                        <Button
                          size="medium"
                          onClick={() => history.push("/profile")}
                          style={{ marginTop: "20rem" }}
                          className={classes.margin}
                        >
                          Go Back
                        </Button>
                      </div>
                    ),
                  },
                  {
                    label: "Next Year",
                    id: 2,
                    entity: (
                      <div class={classes.paidInvoiceList}>
                        <p
                          style={{
                            maxWidth: "70%",
                            fontWeight: 600,
                            marginTop: "2rem",
                          }}
                        >
                          Things to note :
                        </p>
                        <ul>
                          <li>
                            Each member may select any number of technical
                            divisions of which one division is free.
                          </li>
                          <li>
                            Geotechnical division members are paying for ISSMGE
                            membership, which means that when members select
                            GEOTECHNICAL as their only division, they will still
                            have to pay the additional fee.
                          </li>
                          <li>
                            Members are required to make their changes to the
                            divisions before  1st October to take effect in the
                            next financial year.
                          </li>
                        </ul>
                        <ListComponent options={nextYearoptions} divisionList />
                        <Button
                          size="medium"
                          onClick={() => history.push("/profile")}
                          style={{ marginTop: "20rem" }}
                          className={classes.margin}
                        >
                          Go Back
                        </Button>
                      </div>
                    ),
                  },
                ],
              }}
            />
          </div>
        )}
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default Divisions;
