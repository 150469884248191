import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography } from "@material-ui/core";
import Frame from "layout/Frame";
import styled, { css } from "styled-components";

import Stack from "layout/Stack";
import { globalTheme } from "style/globalTheme";
import { brandPalette } from "brand/theme/brandTheme";

const Tile = styled(Card)(
  (props) => css`
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 1.2rem;
      font-weight: bold;
      font-family: poppins, sans-serif;
    }
    flex-grow: 1;
    position: relative;
    max-width: 22rem;
    min-width: 22rem;
    img {
      position: absolute;
      z-index: 10;
      height: 100%;
      width: 100%;
      filter: opacity(0.2);
    }

    ${props.$variant === "PROFILE" &&
    `
    max-width: 30%;
    min-width: 250px;
    box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
    transition: all 0.2s;
    &:hover {
      transform: translateY(-5px) scale(1.05);
      box-shadow: 0 15px 25px rgba(0, 0, 0, 0.11);
    }
    `}
    footer {
      display: flex;
      background: rgba(0, 0, 0, 0.05);
      align-items: center;
      justify-content: ${(props) =>
        props.$variant === "PROFILE" ? "center" : "flex-end"};
      min-height: 2rem;

      padding: ${globalTheme.globalSizes.small};
      position: absolute;
      bottom: 0px;
      width: 100%;
      right: 0;
      z-index: 10;
      a {
        margin-left: 0;
        text-decoration: none;
        color: ${globalTheme.palette.grey[700]};
        font-size: 0.9rem;
        ${props.$variant === "PROFILE" &&
        `
         border: 1px solid ${brandPalette["Primary"]["900"]};
         font-weight: bold;
         padding: 8px 16px;
         border-radius: 15px;
        `}
        &:hover {
          color: ${globalTheme.palette.primary.main};
        }
      }
    }
  `
);

const CustomCardContent = styled(CardContent)(() => css``);

const HeaderSection = styled.section(
  (props) =>
    css`
      position: relative;
      background-image: linear-gradient(to right, #4ca1af, #c4e0e5);
      width: 100%;
      padding: 0px;
      min-height: 25px;
      .imageContainer {
        opacity: 0;
      }
      ${props.profilecard &&
      `
      clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
      min-height: 155px;
      .idoBrv > img,
      .idoBrv > video {
        display: none;
      }
      `}
    `
);
const TileTitle = styled(Typography)(
  (props) => `
  font-size: 1rem;
  margin-left: 2.2rem;
  padding: ${globalTheme.globalSizes.small};
  ${
    props.profilecard &&
    `
  position: relative;
  text-transform: uppercase;
  text-align: center;
  margin-left: 0rem;
  `
  }
`
);
const PixDivider = styled.div(
  (props) => css`
    display: ${props.$variant === "PROFILE" ? "none" : "block"};
    position: absolute;
    opacity: 1;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 9999;
    background: url(/brand/imgSwoosh.svg) bottom left no-repeat;
  `
);
const Field = styled(Stack)((props) => {
  const profilecard = props.$variant === "PROFILE";
  return css`
    dt {
      font-weight: ${(props) =>
        props.$variant === "PROFILE" ? "normal" : "bold"};
      font-size: 1rem;
    }
    dd {
      font-weight: ${(props) =>
        props.$variant === "PROFILE" ? "bold" : "normal"};
      font-size: 1rem;
      margin-inline-start: 0px;
    }
    ${profilecard &&
    `
     width: 80%;
     text-align: center;
     margin: 0 auto;
     &:not(:last-child) {
       border-bottom: 1px solid #eee;
     }
     dt {
      border-bottom: 1px solid #eee;
      padding: 0.6rem;
      display: inline-block;
     }
     dd{
      padding: 0.6rem;
     }
    `}
  `;
});

const Contents = styled(Stack)((props) => {
  return css`
    margin-bottom: ${({ $showBorder }) => ($showBorder ? "2.5rem" : "3rem")};
    ${props.$variant === "PROFILE" &&
    `
    margin-top: -0.1rem;
    text-align: center;
    `}
    .description {
      font-size: 1rem;
      color: rgba(0, 0, 0, 0.6);
      max-width: ${props.$variant === "PROFILE" && "13rem"};
    }
    margin-top: -0.1rem;
    .list-item {
      text-decoration: none;
      padding: 0.4rem;
      border-bottom: 1px solid #eee;
      display: flex;
      align-items: center;
      color: ${brandPalette["Analogous-1"][900]};

      &::before {
        display: inline-block;
        content: ">";
        font-size: 1.5rem;

        border-right: rgba(255, 255, 255, 0.2) 1px solid;
        text-align: center;
        color: #fff;
        text-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1px;
        z-index: 200;
        line-height: 1.3em;
        margin-right: 1rem;
        align-items: flex-end;
        margin-top: -3px;
      }

      &:hover {
        color: ${brandPalette["Analogous-1"][700]};
      }
    }
  `;
});
const TileCard = ({
  title,
  description,
  headingLevel = "h3",
  items,
  itemLinks,
  image={},
  linkURL = "#",
  linkText,
  hasDialogue,
  colour = globalTheme.palette.secondary.main,
  variant = "DEFAULT",
  handleChange,
}) => {
  const showModal = (e) => {
    e.preventDefault();
    handleChange({ showModel: true, url: linkURL });
  };
  return (
    <Tile variant="outlined" raised={true} $variant={variant}>
      {variant === "PROFILE" && image}

      <HeaderSection $profilecard={variant === "PROFILE"} $colour={colour}>
        {Object.keys(image).length !== 0 && (
          <>
            <Frame
              ratio="16:4"
              width="100%"
              className={variant === "PROFILE" && "imageContainer"}
            >
              {image}
            </Frame>
            <PixDivider $variant={variant} className="pixd">
              <img src="/brand/imgSwoosh.svg" alt="" />
              {variant !== "PROFILE" && (
                <TileTitle profilecard={false} variant={headingLevel}>
                  {title}
                </TileTitle>
              )}
            </PixDivider>
          </>
        )}
        {variant === "PROFILE" && (
          <TileTitle profilecard={true} variant={headingLevel}>
            {title}
          </TileTitle>
        )}
      </HeaderSection>

      <CustomCardContent>
        <Contents element="dl" gap="small" $variant={variant}>
          {description && <p class="description">{description}</p>}
          {items?.map((item, index) =>
            Object.keys(item).map((lbl, idxx) => (
              <Field $variant={variant} gap="smallest">
                <dt key={`key-${idxx}`} onClick={() => alert("abcd")}>
                  {lbl}
                  {variant === "PROFILE" ? "" : ":"}
                </dt>
                <dd key={`val-${idxx}`}>{item[lbl]}</dd>
              </Field>
            ))
          )}
          <div>
            {itemLinks?.map((link, index) => (
              <a key={index} className="list-item" href={link.link}>
                {link.name}
              </a>
            ))}
          </div>
        </Contents>
      </CustomCardContent>

      <footer>
        {hasDialogue ? (
          <a onClick={showModal} style={{ cursor: "pointer" }}>
            {linkText}
          </a>
        ) : (
          <a href={linkURL} style={{ cursor: "pointer" }}>
            {linkText}
          </a>
        )}
      </footer>
    </Tile>
  );
};

TileCard.propTypes = {
  title: PropTypes.string,
  /** which heading level to render as the title of the component */
  description: PropTypes.string,
  /** Description to be rendered in body part of the component */
  headingLevel: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
  /** items to display in the main body of the component. takes an array of objects with single key/value pairs like [{label:value},{label:value}] */
  items: PropTypes.array,
  /** image to render in the header section (use a full `<img />` element) */
  itemLinks: PropTypes.array,
  image: PropTypes.string,
  /** URL for the call to action link to access */
  linkURL: PropTypes.string,
  /** Text to display in the call to action link in the footer */
  linkText: PropTypes.string,
  /**  this applies a background colour to the header section of the component if no image is rendered */
  colour: PropTypes.string,
  /**  which variant of tile to render */
  variant: PropTypes.oneOf(["DEFAULT", "PROFILE"]),
};

export default TileCard;
