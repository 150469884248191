//Import React Library
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { AuthenticatedTemplate } from "@azure/msal-react";

//Import Material-UI Library
import { ThemeProvider } from "@material-ui/core/styles";

//Loading Data and Style..
import { globalTheme } from "style/globalTheme";
import { useStyles } from "./style";
//Loading components
import { Button } from "@material-ui/core";
import { config } from "utilities/config";

import ListComponent from "app/components/mvp/listWidget";
import { getlistPayload } from "./model/payload";
import { useHistory } from "react-router-dom";
import { DECLARATION_STATUS_MAP } from "./model/constants";

function MemberDeclarations() {
  const classes = useStyles();
  const getcontactId = useSelector((state) => state.contactid);
  const history = useHistory();

  const onClickViewDetails = (params) => {
    history.push({
      pathname: "/exam/evidence",
      state: {
        data: params.row,
        editable:
          !params.row?.ctpt_read_only &&
          (params.row.statuscode === 533910001 ||
            params.row.statuscode === 533910000),
      },
    });
  };

  const columns = [
    { field: "_ctpt_contactid_value", flex: 1, headerName: " ", hide: true },
    // NOTE: KeyVault field to disable edit. if ctpt_read_only is true then evidence's cant't be added or removed.
    { field: "ctpt_read_only", flex: 1, headerName: " ", hide: true },
    { field: "ctpt_memberdeclarationid", flex: 1, headerName: " ", hide: true },
    { field: "ctpt_selectedforreview", flex: 1, headerName: " ", hide: true },
    { field: "ctpt_name", flex: 2, headerName: "Name" },
    {
      field: "ctpt_declarationperiodstartdate",
      headerName: <p>Start Date</p>,
      flex: 1,
      renderCell: (params) => (
        <p>
          {params.row.ctpt_declarationperiodstartdate !== null
            ? new Date(params.row.ctpt_declarationperiodstartdate)
                .toISOString()
                .substring(0, 10)
            : ""}
        </p>
      ),
    },
    {
      field: "ctpt_declarationperiodenddate",
      headerName: <p>End Date</p>,
      flex: 1,
      renderCell: (params) => (
        <p>
          {params.row.ctpt_declarationperiodenddate !== null
            ? new Date(params.row.ctpt_declarationperiodenddate)
                .toISOString()
                .substring(0, 10)
            : ""}
        </p>
      ),
    },
    {
      field: "ctpt_declartationdate",
      headerName: <p>Declaration Date</p>,
      flex: 1.3,
      renderCell: (params) => (
        <p>
          {params.row.ctpt_declartationdate !== null
            ? new Date(params.row.ctpt_declartationdate)
                .toISOString()
                .substring(0, 10)
            : ""}
        </p>
      ),
    },
    {
      field: "statuscode",
      flex: 1,
      headerName: "Status",
      renderCell: (params) => (
        <p>{DECLARATION_STATUS_MAP[params.row.statuscode]}</p>
      ),
    },
    {
      field: "action",
      headerName: <p class={classes.paidListHeader}>Action</p>,
      flex: 1,
      renderCell: (params) => {
        return (
          <div class={classes.actionsButtons}>
            <Button
              variant="outlined"
              color="primary"
              className="primaryBtn"
              onClick={() => {
                onClickViewDetails(params);
              }}
              disabled={false}
            >
              View Details
            </Button>
          </div>
        );
      },
    },
  ];

  const options = {
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: columns,
    searchPayload: "searchFilter",
    getlistPayload: getlistPayload(),
    deleteListPayload: {},
    listHeading: "CPD Declarations",
    formWidget: {},
    nonRecord: false,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: false,
    filterField: "",
    filterValue: [],
    aggregateField: "",
    hideSearch: true,
    pageSize: 6,
    hasPayloadfilter: true,
    hideAdd: true,
    controlLists: false,
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        {/* <div class={classes.background1}></div> */}
        <div id="cTTP_applicationList" class={classes.container}>
          <div class={classes.listSection}>
            <ListComponent options={options} />
          </div>

          <div></div>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default MemberDeclarations;
