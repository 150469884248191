import CustomTextBox from "app/components/atoms/customTextBox";
import CustomSelect from "app/components/atoms/customSelect";

export const branchUiSchema = {
  "ui:title": "",
  _ctpt_branchid_value: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
};
