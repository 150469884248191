import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import styled from "styled-components";
import { useStyles } from "./style";
import { footerItems } from "app/components/molecules/navbar/model/sideNavData";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 300px;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function AccountInactive(props) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={globalTheme}>
      <StartPage>
        <h1 className={classes.headingTxt}>Account Disabled​</h1>
        <div className={classes.desc}>
          <p></p>
          <ul>
            <li>
              Sorry. Your membership is currently suspended. Please contact us on <a href = "mailto: membership@saice.org.za">membership@saice.org.za</a>
              <a> to re-activate your membership.</a>
            </li>
          </ul>
        </div>
      </StartPage>
    </ThemeProvider>
  );
}

export default AccountInactive;
