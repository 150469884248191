import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, CssBaseline } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Alert from "@material-ui/lab/Alert";
import {
  loginRequest,
  signupRequest,
  logoutRequest,
} from "constants/msalConfig";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
  useAccount,
} from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useStyles } from "./style";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { InteractionStatus } from "@azure/msal-browser";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Cluster from "layout/Cluster";
import Stack from "layout/Stack";
import ImageCard from "app/components/molecules/imageCard/ImageCard.mui";
import TileCard from "app/components/molecules/tileCard/TileCard.mui";
import StatusBar from "app/components/atoms/statusbar";
import Row from "layout/Row";
import { callRedirectAPI } from "utilities/utils";
import {
  getContactDetails,
  getConigitems,
  getServiceRequestOptions,
} from "./service";
import { usePreLoader } from "app/services/hooks/usePreLoader";
import { brandPalette } from "brand/theme/brandTheme";
import { getHomeTilesData } from "./model/constants";
import "./styles.css";
import CustomCheckBox from "app/components/atoms/customCheckBox";
import moment from "moment";
import { config } from "utilities/config";
import { post } from "app/services/api";
import { appData } from "data/appData";
import { useHistory } from "react-router-dom";
import { ContactsOutlined } from "@material-ui/icons";
import { CARDS_DATA } from "./model/constants";
import MediaCard from "./components/media-card/index";

//layout

function Home() {
  const classes = useStyles();
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const contactId = useSelector((state) => state.contactid);
  const token = useSelector((state) => state.token);
  const [checked, setChecked] = React.useState(false);
  const { enableLoader } = usePreLoader();

  const [contactDetails, setContactDetails] = useState({});
  const [profileUpdate, setProfileupdate] = useState(false);

  const history = useHistory();

  const compareDates = () => {
    const startDate = contactDetails.profileupdateDate;
    const currenDate = moment(new Date()).format("DD/MM/YYYY");
    const endDate = moment(currenDate, "DD/MM/YYYY");
    const results = endDate.diff(startDate, "months");
    const expiryDate = appData[0].utilities.profileExpiryDuration;
    if (results > expiryDate) {
      setProfileupdate(true);
    } else {
      setProfileupdate(false);
    }
  };

  const saveProfile = () => {
    if (checked) {
      const apiURL = config.teleportal.submitForm.Update;
      const saveDate = new Date();
      const payload = {
        recordtype: "contacts",
        id: contactId,
        data: [
          {
            name: "ctpt_profiledetailsconfirmedaccurateon",
            value: saveDate,
            type: "string",
          },
        ],
      };
      post(apiURL, payload)
        .then((res) => {
          if (res) {
            setProfileupdate(false);
          }
        })
        .catch(console.log);
    } else {
      setProfileupdate(false);
    }
  };

  const handleLocalLogin = () => {
    localStorage.clear();
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      callRedirectAPI(instance, loginRequest);
      localStorage.setItem("isRedirect", false);
      localStorage.setItem("tknPay", "tokenRequest");
      localStorage.setItem("isSocial", false);
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleGoogleSignIn = () => {
    callRedirectAPI(instance, signupRequest);
  };

  useEffect(() => {
    //console.log("get--"+)
    if (isAuthenticated) {
      dispatch({ type: "SET_AUTH", payload: true });
      dispatch({
        type: "SET_USER",
        payload: { name: account.idTokenClaims.given_name },
      });
    }
  }, [account, dispatch, isAuthenticated]);

  useEffect(() => {
    const fetchContactDetails = async () => {
      console.log("Token fetched:", !!token);
      if (isAuthenticated && contactId && token) {
        const contactDetails = await getContactDetails(contactId);
        setContactDetails(contactDetails);
        dispatch({
          type: "SET_ACCOUNT_STATUS",
          payload: contactDetails.accountStatus,
        });
        dispatch({
          type: "SET_ISACCOUNTONHOLD",
          payload: contactDetails.isAccountOnHold === "True" ? true : false,
        });
        dispatch({
          type: "SET_ISTERMINATED",
          payload: !!contactDetails.terminationType,
        });
      }
      enableLoader(false);
    };

    fetchContactDetails().catch(console.log);
  }, [contactId, isAuthenticated, token, enableLoader, dispatch]);

  useEffect(() => {
    dispatch({ type: "SET_CONTACTDET", payload: contactDetails });
    compareDates();
    if (Object.keys(contactDetails).length > 0) {
      if (
        account.idTokenClaims.tfp.indexOf("registration") > -1 ||
        account.idTokenClaims.newUser
      ) {
        callRedirectAPI(instance, logoutRequest, false);
      }
    }
  }, [contactDetails, dispatch]);

  const [serviceRequests, setServiceRequests] = useState([]);

  useEffect(() => {
    const fetchServiceRequests = async () => {
      if (isAuthenticated && contactId && token) {
        const serviceRequests = await getServiceRequestOptions(contactId);
        setServiceRequests(serviceRequests);
      }
      enableLoader(false);
    };

    fetchServiceRequests().catch(console.log);
  }, [contactId, isAuthenticated, token, enableLoader, dispatch]);

  const getImg=()=>{
    return <img src={`./brand/leaf-optimized.jpg`} alt="member" />
  }

  return (
    <ThemeProvider theme={globalTheme}>
      <CssBaseline />
      <UnauthenticatedTemplate>
        <Grid container>
          <Grid item className={classes.heroImg} id="herobanner"></Grid>
        </Grid>
        <Cluster
          className={classes.buttonBand}
          align="center"
          padding="large"
          gap="medium"
        >
          <Row className={classes.textBand} align="center">
            <LockOutlinedIcon
              size="large"
              sx={{color: "white"}}
              style={{ fill: "white" }}
            />
            <Typography variant="body2" className={classes.bodyCopy2}>
              Login to member portal
            </Typography>
          </Row>
        </Cluster>
        <div className={classes.imageCards}>
          <Cluster
            className={classes.imageCard}
            justify="evenly"
            padding="medium"
            gap="medium"
          >
            <Grid item xs={12} sm={4}>
              <ImageCard header="SAICE Members" lrRatio="90:10">
                <Typography variant="body1">
                 You have been allocated a membership number
                  <br />
                  <br />
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  className={clsx(classes.buttonBig, classes.primary)}
                  onClick={() => handleLocalLogin()}
                >
                 Click here
                </Button>
              </ImageCard>
            </Grid>
            <Grid item xs={12} sm={5}>
              <ImageCard header="Interested in becoming a SAICE member" lrRatio="100:0">
                <Typography variant="body1">
                  <br />
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  className={clsx(classes.buttonBig, classes.primary)}
                  onClick={() => handleGoogleSignIn()}
                >
                   Click here
                </Button>
              </ImageCard>
            </Grid>
          </Cluster>
        </div>
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <div class={classes.bannerContainer}></div>
        <Grid component="main" container spacing={0} className={classes.root}>
          <StatusBar className="show" open={true} />
          <Grid item xs={12}>
            <Stack gap="medium" padding="large">
              <div
                className={`profileBlock ${
                  profileUpdate ? classes.showflex : classes.hideflex
                }`}
              >
                <Typography
                  variant="h6"
                  element="h6"
                  className="profileHeading"
                >
                  Please check your profile details are up to date
                </Typography>
                <FormControlLabel
                  key="saveid"
                  control={
                    <Checkbox
                      checked={checked}
                      name="checked"
                      color="primary"
                      onChange={handleChange}
                    />
                  }
                  label="I can confirm my profile details are up to date"
                />
                <div>
                  <Button
                    disabled={!checked}
                    variant="contained"
                    onClick={saveProfile}
                    color={"primary"}
                  >
                    {" "}
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    disabled={checked}
                    style={{ marginLeft: "1rem" }}
                    onClick={() => history.push("/profile")}
                  >
                    {" "}
                    Go to profile
                  </Button>
                </div>
              </div>
              <Typography variant="h2" element="h2">
                Hello {contactDetails.firstname || "User"}
              </Typography>

              <Typography
                variant="subtitle1"
                className={classes.description}
                style={{ marginBottom: ".625rem" }}
              >
                {contactDetails.membershipType
                  ? `Welcome back to your membership portal.${
                      contactDetails.memberSince
                        ? " You have been a member since " +
                          contactDetails.memberSince
                        : ""
                    }.​`
                  : "Welcome back, You do not currently have any active memberships. Please use the applications menu at the top of the page to start a new application."}
              </Typography>
              {contactDetails.membershipType && (
                <Typography
                  component="span"
                  variant="subtitle1"
                  className={classes.description}
                  style={{ marginTop: 0 }}
                >
                  Please navigate to "My Profile" to review and update your
                  details
                </Typography>
              )}
              <Typography className={classes.heading} variant="h2" element="h2">
                Manage Your Membership
              </Typography>
              <Cluster justify="start" gap="large">
                {getHomeTilesData(serviceRequests).map((tile) => (
                  <div className={classes.tileCardContainer}>
                    <TileCard
                      title={tile.title}
                      linkURL="/profile"
                      image={getImg}
                      itemLinks={tile.items}
                      colour={brandPalette["Primary"][100]}
                    />
                  </div>
                ))}
              </Cluster>
            </Stack>
          </Grid>
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default Home;
