//Import React Library
import React, { useState, useEffect } from "react";

//Import Material-UI Library
import Input from "@material-ui/core/Input";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { post } from "app/services/api";
import { formatData } from "utilities/datatable";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
    maxWidth: 300,
    marginLeft: -1,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
    fontSize: "1.1rem",
    fontWeight: "bold",
    color: "#616161",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelect(props) {
  const { getProps } = props;
  const classes = useStyles();
  const [enumToEnumNameMapping, setEnumToEumNameMapping] = useState({});
  const [selectValue, setSelectValue] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const dynamicList = () => {
    if (getProps.schema.items.isDynamic) {
      getProps.schema.items.enum = [];
      getProps.schema.items.enumNames = [];
      post(getProps.schema.items.apiURL, getProps.schema.items.payload)
        .then((res) => {
          let map = {};
          formatData(res.data)?.forEach((item) => {
            if (item?.id) {
              map[item.id] = item.ctpt_name;
            }
          });
          if (Object.keys(map).length > 0) {
            localStorage.setItem("dynamicPickList", JSON.stringify(map));
          }

          res.data.data.map((x) => {
            if (x.data.data === undefined) {
              getProps.schema.items.enum.push(x.data[1].value.toString());
              getProps.schema.items.enumNames.push(x.data[0].value);
            } else {
              getProps.schema.items.enum.push(x.data.data[0].value.toString());
              getProps.schema.items.enumNames.push(x.data.data[1].value);
            }
          });
          setIsLoaded(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } else setIsLoaded(true);
  };

  useEffect(() => {
    dynamicList();
  }, []);

  useEffect(() => {
    console.log("getProps--" + JSON.stringify(getProps));
    if (getProps?.schema?.items?.enum) {
      let mapping = {};
      let reverseMap = {};
      Object.values(getProps.schema.items.enumNames).forEach((val, i) => {
        mapping[val] = getProps.schema.items.enum[i];
        reverseMap[getProps.schema.items.enum[i]] = val;
      });
      setEnumToEumNameMapping(mapping);
      if (getProps.value?.length) {
        setSelectValue(getProps.value?.map((x) => reverseMap[x] || x));
      }
    }
  }, [getProps]);

  const multiDropSelect = (value) => {
    console.log("value--" + JSON.stringify(value));
    setSelectValue(value);
    const formattedValue = value.map((x) => {
      if (getProps?.schema?.items?.enumNames.indexOf(x) < 0) {
        return x;
      } else {
        return getProps.schema.items.enum[
          getProps?.schema?.items?.enumNames.indexOf(x)
        ];
      }
    });
    getProps.onChange(formattedValue.toString().split(","));
  };

  const createMarkup = (txt) => {
    return { __html: txt };
  };
  const astrix = getProps.schema.requiredCheck ? "*" : "";
  const labelText = getProps.schema.items.title + "" + astrix;

  const getRenderValue = (value) => {
    if ((value + "")?.length) {
      let valueNames = [];
      if (Array.isArray(value)) {
        value.map((v) => {
          if (getProps.schema.items.enum.indexOf(v) > -1) {
            valueNames.push(
              getProps.schema.items.enumNames[
                getProps.schema.items.enum.indexOf(v)
              ]
            );
          } else {
            valueNames.push(v);
          }
        });
      }
      return Array.isArray(value)
        ? valueNames.join(", ")
        : enumToEnumNameMapping[value];
    } else {
      return "Please select";
    }
  };

  return (
    <div>
      {getProps.schema.heading && (
        <u>
          <h3>{getProps.schema.heading}</h3>
        </u>
      )}
      {getProps.schema.items?.title && (
        <div
          className={classes.title}
          dangerouslySetInnerHTML={{
            __html: labelText,
          }}
        />
      )}
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">
          {"Please select"}
        </InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={selectValue}
          onChange={(event) => multiDropSelect(event.target.value)}
          input={<Input />}
          // renderValue={(selected) => {
          //   return selected.join(", ")}
          // }
          renderValue={getRenderValue}
          MenuProps={MenuProps}
          required={getProps.schema.requiredCheck}
        >
          {getProps.schema.items?.enumNames?.map((name, index) => (
            <MenuItem key={index} value={name}>
              <Checkbox
                checked={
                  selectValue.includes(getProps.schema.items.enum[index]) ||
                  selectValue.includes(name)
                }
              />
              <ListItemText
                primary={
                  <span
                    style={{ whiteSpace: "break-spaces" }}
                    dangerouslySetInnerHTML={createMarkup(name)}
                  ></span>
                }
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
