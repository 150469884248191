import { CAPS_LOCK_WARNING } from "constants/settings";

export const studentSchema = {
  title: "Student Placement Details",
  logicalname: "Contact",
  type: "object",
  description: CAPS_LOCK_WARNING,
  // description:
  //   "Update your personal information using the form below and click Save",
  //required: ["name", "address", "primaryemail", "primarycontact"],
  properties: {
    firstname: {
      title: "First Name",
      type: "string",
      schemaname: "firstname",
      datatype: "string",
      isReadonly: true,
    },
    lastname: {
      title: "Last Name",
      type: "string",
      datatype: "string",
      isReadonly: true,
    },
    fullname: {
      title: "Full Name",
      type: "string",
      datatype: "string",
      isReadonly: true,
    },
    birthdate: {
      title: "Date of birth",
      type: "string",
      datatype: "number",
      isReadonly: true,
    },
    cv: {
      title: "CV",
      helpText: "",
      tootltipheading: "head",
      tootltipText: "text",
      type: "string",
      datatype: "upload",
      format: "string",
      requiredCheck: false,
      containerName: "contacts",
      fileName: "id",
      extensions: ".doc,.pdf",
      acceptType: [
        "application/pdf",
        "application/msword",
        "image/png",
        "image/jpeg",
      ],
      acceptExtension: [".pdf", ".doc", ".docx", ".png", ".jpeg"],
      recordPath: "",
      onChange: {},
    },
  },
};

// export const uploadCVSchema = {
//   schema: {
//     title: "",
//     helpText: "",
//     tootltipheading: "head",
//     tootltipText: "text",
//     type: "string",
//     datatype: "upload",
//     format: "string",
//     requiredCheck: false,
//     containerName: "contacts",
//     fileName: "id",
//     extensions: ".doc,.pdf",
//     acceptType: [
//       "application/pdf",
//       "application/msword",
//       "image/png",
//       "image/jpeg",
//     ],
//     acceptExtension: [".pdf", ".doc", ".docx", ".png", ".jpeg"],
//     recordPath: "",
//     onChange: {},
//   },
// };
