import React, { useState } from 'react';
import { settings } from "constants/settings"

async function loadComponent() {
    return import('./ObjectFieldTemplate.'+settings.UI+'.js')
}

function ObjectFieldTemplate(props) {

    const { children, data, ...rest } = props;

    const [component, setComponent] = useState(null)

    loadComponent().then(x => setComponent(x))

    if (!component) return null;
    return (
        <component.default data={data} {...rest}>{children}</component.default>
    )
}

export default ObjectFieldTemplate;