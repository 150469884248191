//Import React Library
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { AuthenticatedTemplate } from "@azure/msal-react";

//Import Material-UI Library
import { ThemeProvider } from "@material-ui/core/styles";

//Loading Data and Style..
import { globalTheme } from "style/globalTheme";
import { useStyles } from "./style";
//Loading components
import { config } from "utilities/config";

import ListComponent from "app/components/mvp/listWidget";
import { aggregatecolumns } from "./model/column";
import { getcolumns } from "./model/column";
import { getemploymentlistPayload } from "./model/payload";
import { getEmploymentHistorySchema } from "./model/schema";
import { getEmploymentHistoryUiSchema } from "./model/UiSchema";
import { CreaterequestPayload } from "./model/createPayload";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { customValidate } from "./model/validation";

function EmploymentHistory(props) {
  const classes = useStyles();
  const getcontactId = useSelector((state) => state.contactid);
  const history = useHistory();
  const getcontactName = useSelector((state) => state.user);

  const onClickViewDetails = (params) => {
    window.location.href =
      "/profile/viewemploymenthistorydetails?id=" +
      params.row.ctpt_professionalhistoryid;
  };

  const columns = [
    { field: "ctpt_jobtitle", flex: 0.5, headerName: "Position Held" },
    {
      field: "ctpt_company",
      flex: 0.5,
      headerName: "Company",
    },
    {
      field: "ctpt_typeofemployment",
      flex: 0.7,
      headerName: "Employment Sector",
    },
    {
      field: "ctpt_startdate",
      flex: 0.5,
      headerName: "Start Date",
    },
    {
      field: "ctpt_enddate",
      flex: 0.5,
      headerName: "End Date",
    },
    {
      field: "action",
      headerName: <p class={classes.paidListHeader}>Action</p>,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <div class={classes.actionsButtons}>
            <Button
              variant="outlined"
              color="primary"
              className="primaryBtn"
              onClick={() => {
                onClickViewDetails(params);
              }}
              disabled={false}
            >
              View Details
            </Button>
          </div>
        );
      },
    },
  ];
  const options = {
    apiURL: config.teleportal.submitForm.get,
    apiUrlDel: config.teleportal.file.delete,
    columns: columns,
    searchPayload: "searchFilter",
    getlistPayload: getemploymentlistPayload(getcontactId),
    formwidgetOptions: {
      widgetType: "add",
      formSchema: getEmploymentHistorySchema,
      uiSchema: getEmploymentHistoryUiSchema,
      apiURL: config.teleportal.submitForm.Create,
      entityType: "ctpt_professionalhistories",
      formUischema: "",
      button1Label: "Add",
      button2Label: "Cancel",
      button3Label: "Clear",
      payloadSubmit: CreaterequestPayload,
      columns: [],
      contactId: getcontactId,
      contactName: getcontactName.name,
      historyUrl: "",
      hasMultiplecall: true,
      hasModel: true,
      validate: customValidate,
    },
    deleteListPayload: {},
    listHeading: "Employment History",
    formWidget: {},
    nonRecord: false,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: false,
    filterField: "",
    filterValue: [],
    aggregateField: "",
    hideSearch: true,
    pageSize: 10,
    hasPayloadfilter: true,
    hideAdd: false,
    controlLists: false,
    validate: customValidate,
  };

  ////////////
  // const options = {
  //   listDescription:
  //     "Employment History",
  //   apiURL: config.teleportal.submitForm.get,
  //   columns: getcolumns(),
  //   searchPayload: "searchFilter",
  //   getlistPayload: getlistPayload,

  //   deleteListPayload: {},
  //   listHeading: "",
  //   formWidget: {},
  //   nonRecord: false,
  //   role: role,
  //   rows: "",
  //   localDataStore: false,
  //   hideDelete: true,
  //   hasAggregate: false,
  //   staticTable: false,
  //   staticRow: [],
  //   hiddenColumn: {},
  //   contactId: getcontactId,
  //   filteredValue: "",
  //   hasFilter: false,
  //   filterField: "",
  //   filterValue: [],
  //   aggregateColumns: aggregatecolumns,
  //   aggregateField: "",
  //   hideSearch: true,
  //   pageSize: 10,
  //   hasPayloadfilter: true,
  //   hideAdd: false,
  //   controlLists: false,
  // };

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div id="cTTP_applicationList" class={classes.container}>
          <div class={classes.listSection}>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              onClick={() => {
                history.push("/profile");
              }}
              style={{ marginLeft: "1.5rem" }}
            >
              Go Back
            </Button>
            <ListComponent options={options} />
          </div>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default EmploymentHistory;
