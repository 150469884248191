export const appData = [
  {
    msalConfig: {
      B2CAppId: "9ef81a41-e128-4e84-aa7e-60f010762c27",
      B2CTenant: "devpowerthingb2c",
      redirectPolicy: {
        signIn: "B2C_1_signup",
        signOut: "B2C_1_signup",
        editProfile: "B2C_1_editProfile",
        resetPass: "B2C_1_passReset",
      },
    },
    brandDetails: {
      typography: {
        fontSize: 18,
        fontFamily: "Roboto Mono",
      },
      // palette: {
      //   primary: brandPalette.Primary[900],
      //   primaryTint: brandPalette.Primary,
      //   secondaryTint: brandPalette.Secondary,
      //   complementaryTint: brandPalette.Complementary,
      //   secondary: brandPalette.Secondary[900],
      //   error: brandPalette["Triadic-2"][500],
      //   warning: brandPalette.Complementary[400],
      //   desaturated: brandPalette.Secondary[50],
      //   info: brandPalette.Primary[100],
      //   success: brandPalette["Analogous-1"][400]
      // },
      image: {
        logoLarge: "url",
        logoSmall: "url",
        heroBanner: "url",
      },
      icon: {
        favIcon: "url",
        homescreenIcon: "url",
      },
    },
    pageDetails: {
      header: {
        title: "SAICE",
        logo: "brand/saice-logo.png",
        desktop: {
          logoWidth: "150px",
          logoHeight: "77px",
        },
        mobile: {
          logoWidth: "92px",
          logoHeight: "46px",
        },
      },
      footer: {
        copyRight:
          "© Copyright 2022, SAICE – The South African Institution of Civil Engineering. All Rights Reserved.",
        disclaimer: "disclaimer text",
      },
    },
    utilities: {
      setSessionTime: true,
      sessionTime: 1800,
      cookieConsent: true,
      cookieExpiryDate: "date",
      cookieExpiryTime: 5000,
      sidenavDrawerwidth: { value: 225, unit: "px" },
    },
  },
];

export const appData2 = [
  {
    brandDetails: {
      typography: {
        fontSize: 18,
        fontFamily: "Roboto Mono",
      },
      palatte: {
        primary: "#009688",
        secondary: "#00897b",
        error: "#ef5350",
        warning: "#b2dfdb",
        info: "#e0f2f1",
        success: "#6fbf73",
      },
      image: {
        logoLarge: "url",
        logoSmall: "url",
        heroBanner: "url",
      },
      icon: {
        favIcon: "url",
        homescreenIcon: "url",
      },
    },
    pageDetails: {
      header: {
        title: "SAICE",
        logo: "assets/Logo3.png",
        logoWIdth: "150px",
        logoHeight: "50px",
      },
      footer: {
        copyRight: "copy right text",
        disclaimer: "disclaimer text",
      },
    },
    utilities: {
      setSessionTime: true,
      sessionTime: 1800,
      cookieConsent: true,
      cookieExpiryDate: "date",
      cookieExpiryTime: 10000,
    },
  },
];

export const appData1 = [
  {
    brandDetails: {
      typography: {
        fontSize: 18,
        fontFamily: "Roboto Mono",
      },
      palatte: {
        primary: "#78909c",
        secondary: "#b0bec5",
        error: "#ef5350",
        warning: "#cfd8dc",
        info: "#ffecb3",
        success: "#6fbf73",
      },
      image: {
        logoLarge: "url",
        logoSmall: "url",
      },
      icon: {
        favIcon: "url",
        homescreenIcon: "url",
      },
    },
    pageDetails: {
      header: {
        title: "SAICE",
        logo: "assets/logo2a.png",
        logoWIdth: "150px",
        logoHeight: "50px",
      },
      footer: {
        copyRight: "copy right text",
        disclaimer: "disclaimer text",
      },
    },
    utilities: {
      setSessionTime: true,
      sessionTime: 1800,
      cookieConsent: true,
      cookieExpiryDate: "date",
      cookieExpiryTime: 10000,
    },
  },
];
