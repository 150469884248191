export const getlistPayload = () => ({
  properties: [
    "_ctpt_contactid_value",
    "ctpt_memberdeclarationid",
    "ctpt_declarationperiodenddate",
    "ctpt_declarationperiodstartdate",
    "ctpt_declartationdate",
    "ctpt_name",
    "ctpt_selectedforreview",
    "statuscode",
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: true,
    filterfields: [],
  },
  Aggregate: {
    AggregateFields: [],
  },
  sortfield: {
    field: "ctpt_declarationperiodstartdate",
    sorttype: "desc",
  },
  recordtype: "ctpt_memberdeclarations",
  idfield: "ctpt_memberdeclarationid",
  continuationToken: "",
  pazesize: "10",
});
