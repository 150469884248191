import CustomTextBox from "app/components/atoms/customTextBox";
import CustomLinkText from "app/components/atoms/customLinkText";

export const getStudentUiSchema = {
  "ui:title": "",
  ctpt_title: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
    "ui:readonly": true,
  },
  ctpt_casenumber: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
    "ui:readonly": true,
  },
  ctpt_details: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
    "ui:readonly": true,
  },
  createdon: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
    "ui:readonly": true,
  },
  modifiedon: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
    "ui:readonly": true,
  },
  ctpt_caseresolutiondetails: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
    "ui:readonly": true,
  },
  ctpt_documenturl: {
    "ui:widget": (props) => {
      return props.value!=""?
       <CustomLinkText getProps={props} /> : null
    },
    "ui:readonly": true,
  },
};
