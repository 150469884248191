import React, { useEffect } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useSelector } from "react-redux";
import ListWidget from "app/components/mvp/listWidget";
import { getlistPayload } from "./model/payload";
import { config } from "utilities/config";
import { Grid } from "@material-ui/core";
import { resultCodes } from "utilities/stateCodes";

import { useStyles } from "./style";

function ExamResult() {
  const classes = useStyles();
  const role = useSelector((state) => state.role);
  const getcontactId = useSelector((state) => state.contactid);
  const getcontactName = useSelector((state) => state.user);
  const renderContactName = (params) => {
    console.log("getcontactName--" + JSON.stringify(getcontactName));
    return <div>{getcontactName.name || ""}</div>;
  };

  const examColumns = [
    { field: "ctpt_attempt", flex: 0.3, headerName: "Attempt" },
    {
      field: "ctpt_name",
      flex: 0.5,
      headerName: "Name",
    },
    {
      field: "ctpt_candidatenumber",
      flex: 0.6,
      headerName: "Candidate Number",
    },
    {
      field: "ctpt_ismockexam",
      flex: 0.6,
      headerName: "Is Mock Exam?",
    },
    {
      field: "ctpt_contactid",
      flex: 0.5,
      headerName: "Contact",

      renderCell: renderContactName,
    },
    {
      field: "_ctpt_examid_value",
      flex: 0.5,
      headerName: "Exam",

      renderCell: (params) => <div>{params.row._ctpt_examid_value}</div>,
    },

    {
      field: "ctpt_isshortlisted",
      flex: 0.5,

      headerName: "Short Listed",
    },
    {
      field: "ctpt_mark",
      flex: 0.4,

      headerName: "Mark",
    },

    {
      field: "ctpt_officecode",
      flex: 0.5,

      headerName: "Office",
    },
    {
      field: "ctpt_result",
      flex: 0.5,

      headerName: "Result",
      renderCell: (params) => (
        <div>
          {params.row.ctpt_result !== null
            ? resultCodes[params.row.ctpt_result]
            : ""}
        </div>
      ),
    },
  ];

  const configuration = {
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: examColumns,
    searchPayload: "searchFilter",
    getlistPayload: getlistPayload,
    deleteListPayload: {},
    listHeading: "Exam Statement & Reports",
    formwidgetOptions: {},
    nonRecord: true,
    role: role,
    rows: "",
    localDataStore: false,
    hideControls: true,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: false,
    filterField: "_ctpt_contactid_value",
    filterValue: [],
    aggregateColumns: [],
    aggregateField: "",
    hasPayloadfilter: true,
    hideDelete: true,
    hideSearch: true,
    hideAdd: true,
    pageSize: 3,
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <div className={classes.container}>
              <ListWidget options={configuration} />
            </div>
          </Grid>
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default ExamResult;
