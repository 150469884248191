import { CAPS_LOCK_WARNING } from "constants/settings";

export const branchSchema = {
  title: "Edit Branch Details",
  logicalname: "Branch",
  type: "object",
  additionalDescription: CAPS_LOCK_WARNING,
  properties: {
    _ctpt_branchid_value: {
      title: "Branch",
      type: "string",
      datatype: "array",
      requiredCheck: true,
      isDynamic: true,
      apiURL: "/api/GetAllAsync",
      payload: {
        properties: ["ctpt_name", "ctpt_branchid", "ctpt_displayflag"],
        filter: {
          globalfilter: "",
          globalAndOperator: false,
          fieldAndOperator: true,
          filterfields: [
            {
              field: "statecode",
              searchvalue: 0,
              type: "guid",
            },
          ],
        },
        Aggregate: {
          AggregateFields: [],
        },
        sortfield: {
          field: "ctpt_name",
          sorttype: "asc",
        },
        recordtype: "ctpt_branchs",
        idfield: "ctpt_branchid",
        continuationToken: "",
        pazesize: "1000",
      },
      enum: ["1"],
      enumNames: ["1"],
    },
  },
};
