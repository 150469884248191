export const getlistPayload = {
        "properties": [
             "ctpt_name",
              "_ctpt_student_value",
             "ctpt_startdate",
             "ctpt_duration",
             "ctpt_enddate",
             "_ownerid_value",
             "ctpt_studenttrainingagreementid"
         ],
         "filter": {
             "globalfilter": "",
             "globalAndOperator": false,
             "fieldAndOperator": false,
             "filterfields": [
                {
                    "field": "_ctpt_student_value",
                    "searchvalue": "",
                    "type": "guid"
                },
             ]
         },
         "Aggregate": {
             "AggregateFields": []
         },
         "recordtype": "ctpt_studenttrainingagreements",
         "idfield": "ctpt_studenttrainingagreementid",
         "continuationToken": "",
         "pazesize": "10"
     }