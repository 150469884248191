import { CAPS_LOCK_WARNING } from "constants/settings";

export const getStudentSchema = {
  title: "Service Request",
  logicalname: "Contact2",
  type: "object",
  // description: CAPS_LOCK_WARNING,
  additionalDescription: (props) => {
    const schema =
      Object.values(props.schema.properties).find(
        ({ schemaname }) => schemaname === "ctpt_casetypeid"
      ) || {};

    const enums = schema.enum.map((x, index) => ({
      value: x,
      label: schema.enumNames[index],
    }));

    const val = enums.find(
      (item) => item.value === props.formData["ctpt_casetypeid"]
    )?.label;

    if (
      val &&
      (val.includes("Acceptance Letter") || val.includes("Resignation Letter"))
    ) {
      return (
        <>
          Only available for request upon approved application received from
          portal
          {!!CAPS_LOCK_WARNING && <p>{CAPS_LOCK_WARNING}</p>}
        </>
      );
    }
    return CAPS_LOCK_WARNING;
  },
  properties: {
    ctpt_casetypeid: {
      title: "Type of Request",
      type: "string",
      requiredCheck: true,
      schemaname: "ctpt_casetypeid",
      datatype: "string",
      isDynamic: true,
      helpText: "",
      apiURL: "/api/GetAllAsync",
      payload: {
        properties: ["ctpt_name", "ctpt_casetypeid", "ctpt_displayflag"],
        filter: {
          globalfilter: "",
          globalAndOperator: false,
          fieldAndOperator: false,
          filterfields: [],
        },
        sortfield: {
          field: "ctpt_name",
          sorttype: "asc",
        },
        Aggregate: {
          AggregateFields: [],
        },
        recordtype: "ctpt_casetypes",
        idfield: "ctpt_casetypeid",
        continuationToken: "",
        pazesize: "1000",
      },
      enum: [],
      enumNames: [],
    },
    ctpt_title: {
      title: "Subject",
      type: "string",
      schemaname: "ctpt_title",
      datatype: "string",
      requiredCheck: true,
      referValueFrom: "ctpt_casetypeid",
    },
    ctpt_details: {
      title: "Details",
      type: "string",
      schemaname: "ctpt_details",
      datatype: "string",
      ismultiline: true,
      multilineRow: 4,
      requiredCheck: false,
    },
    // ctpt_documenturl: {
    //   title: "Attachment",
    //   type: "string",
    //   schemaname: "ctpt_documenturl",
    //   datatype: "upload",
    //   helpText: "",
    //   tootltipheading: "",
    //   requiredCheck: false,
    //   format: "string",
    //   containerName: "channels",
    //   fileName: "studentRegister",
    //   acceptExtension: [
    //     ".pdf",
    //     ".doc",
    //     ".docx",
    //     ".png",
    //     ".jpeg",
    //     ".xlsx",
    //     ".xls",
    //     ".csv",
    //   ],
    //   acceptType: [
    //     "application/pdf",
    //     "application/msword",
    //     "image/png",
    //     "image/jpeg",
    //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //     "application/vnd.ms-excel",
    //     "application/csv",
    //   ],
    //   recordPath: "/",
    //   requireBoburl: true,
    // },
  },
};

export const getDocumentSchema = {
  title: " ",
  logicalname: "Contact2",
  type: "object",
  properties: {
    ctpt_name: {
      title: "Name",
      type: "string",
      schemaname: "ctpt_customerid",
      datatype: "string",
      isDynamic: false,
    },
    ctpt_documenttype: {
      title: "Document Type",
      type: "string",
      schemaname: "ctpt_documenttype",
      datatype: "string",
    },
    ctpt_case: {
      title: "Case",
      type: "string",
      schemaname: "ctpt_case",
      datatype: "string",
    },
    ctpt_documenturl: {
      title: "Student attendance register",
      type: "string",
      schemaname: "ctpt_documenturl",
      datatype: "string",
      requireBoburl: true,
    },
  },
};
