import CustomTextBox from "app/components/atoms/customTextBox";
import CustomDate from "app/components/atoms/customDate";
import CustomRadio from "app/components/atoms/customRadio";
import CustomSelect from "app/components/atoms/customSelect";
import MultipleSelect from "../atoms/multiDropdown/MultipleSelect.mui";

export const identityUiSchema = {
  "ui:title": "",
  ctpt_identitynumber: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_type: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  }
};


