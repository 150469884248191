//Import React Library
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
//Import Material-ui Library
import Form from "@rjsf/material-ui";
import { makeStyles } from "@material-ui/core/styles";
import styled, { css } from "styled-components";
import { useSelector, useDispatch } from "react-redux";

//Utilities, config, style....
import { style } from "./style";
import { post } from "app/services/api";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Box,
  Button,
} from "@material-ui/core";
import { usePreLoader } from "app/services/hooks/usePreLoader";
import { updateDependentValues } from "./service";
import ObjectFieldTemplate from "app/components/molecules/ObjectFieldTemplate";

export const useStyles = makeStyles((theme) => ({
  ...style,
  margin: {
    margin: theme.spacing(1.3),
  },
  firstButton: {
    marginRight: "1rem",
  },
  ".boxContainer": {
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
}));

const StyledForm = styled(Form)(
  () => css`
    h5[class^="MuiTypography-root"] {
      margin-bottom: 1rem;
    }
  `
);

export default function FormWidget(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enableLoader } = usePreLoader();

  const goBackAfterSubmit = useRef(null);

  const {
    apiURL,
    button1Label,
    button2Label,
    button2GoBack,
    onClickButton2,
    entityType,
    formSchema,
    uiSchema,
    formData,
    initialFormData,
    payloadSubmit,
    localData,
    contactId,
    eventID,
    goBackPage,
    recordId,
    historyUrl,
    payloadSecondform,
    payloadThirdForm,
    hasMultiplecall,
    contactName,
    customSubmitCallback,
    hasModel,
    validate,
  } = props.options;
  let history = useHistory();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [localformData, setlocalformData] = useState(formData);
  const docUrl = useSelector((state) => state.docUrl);
  const base64Doc = useSelector((state) => state.base64Doc);

  const goBack = () => {
    enableLoader(false);
    if (goBackAfterSubmit.current && goBackPage) {
      history.push(goBackPage);
    }
  };

  const handleSuccessfullSubmission = (res) => {
    props.successResponse(res);
    if (hasModel) {
      props.handleModalClose();
    }
    goBack();
  };

  //Submit the filled data with Api using Axios
  const submitFormData = (JsonData) => {
    enableLoader(true, "Please wait, Submitting...");
    let payload1 = payloadSubmit(JsonData);
    Promise.resolve(payload1).then((payload) => {
      post(apiURL, payload)
        .then((res) => {
          let caseId = res.data.id;
          if (hasMultiplecall && docUrl !== "") {
            let payloads = payloadSecondform(
              contactName,
              caseId,
              docUrl,
              JsonData
            );
            Promise.resolve(payloads).then((pl) => {
              multiCall(pl);
            });
          } else {
            handleSuccessfullSubmission(res);
          }
          if (localData) {
            localStorage.removeItem("contactId");
            localStorage.setItem("contactId", res.data.id);
          }
        })
        .catch((error) => {
          enableLoader(false);
          console.log(error);
        });
    });
  };

  const multiCall = (payloads) => {
    post(apiURL, payloads)
      .then((res) => {
        if (payloadThirdForm) {
          const payload = payloadThirdForm(base64Doc, res.data);
          if (payload) {
            post(apiURL, payload)
              .then(() => {
                props.successResponse(res);
                props.handleModalClose();
              })
              .catch(console.log)
              .finally(() => {
                goBack();
              });
          }
        } else {
          enableLoader(false);
          props.successResponse(res);
          props.handleModalClose();
          if (goBackAfterSubmit.current && goBackPage) {
            history.push(goBackPage);
          }
        }
      })
      .catch((error) => {
        enableLoader(false);
        console.log(error);
      });
  };

  //Arranging the form data for submitting form
  const onSubmit = (data, e) => {
    var JsonData = {};
    var arrayData = [];
    var dataSchema = data.schema.properties;
    var i = 0;
    for (var key in dataSchema) {
      arrayData[i] = {
        name: key,
        value: data.formData[key],
        type: dataSchema[key].type,
      };
      i++;
    }
    JsonData["data"] = arrayData;
    JsonData["recordtype"] = entityType;
    JsonData["id"] = contactId;
    JsonData["recordId"] = recordId;
    JsonData["contactid"] = contactId;
    JsonData["eventID"] = eventID;
    if (docUrl) {
      JsonData["docUrl"] = docUrl;
      JsonData["base64Doc"] = base64Doc;
    }
    if (props.updateFormData) {
      props.updateFormData(data.schema.logicalname, data.formData);
    }
    if (customSubmitCallback) {
      customSubmitCallback(JsonData);
    } else {
      submitFormData(JsonData);
    }
  };

  const handleConfirmationClose = () => {
    setOpenConfirmation(false);
  };

  useEffect(() => {
    if (props.options.formData) {
      setlocalformData(props.options.formData);
    }
  }, [props]);

  const transformErrors = (errors) => {
    const filteredErrors = errors.filter((x) => x.name === "pattern");
    console.log("Filtere:", filteredErrors);
    return filteredErrors.map((error) => {
      const schemaPath = error.schemaPath.split("/")?.slice(1, -1)?.join(".");
      const selectedInputField = schemaPath
        .split(".")
        .reduce((a, prop) => a[prop], formSchema);
      if (selectedInputField.message) {
        error.message = selectedInputField.message;
      }
      console.log("error.message", error.message);

      return error;
    });
  };

  return (
    <div className="boxContainer" style={{ minHeight: "104%" }}>
      <Box className={classes.textBox}>
        {historyUrl !== "" ? <a href={historyUrl}>Go Back</a> : ""}
        <StyledForm
          schema={formSchema}
          uiSchema={uiSchema}
          formData={localformData}
          liveValidate={true}
          transformErrors={(errors) => transformErrors(errors)}
          showErrorList={false}
          validate={validate}
          onChange={(changedData) => {
            console.log("changedData--" + JSON.stringify(changedData));

            const newFormData = updateDependentValues(
              changedData,
              localformData
            );

            setlocalformData({
              ...localformData,
              ...changedData.formData,
              ...newFormData,
            });
          }}
          onSubmit={onSubmit}
          ObjectFieldTemplate={ObjectFieldTemplate}
        >
          <div className="button-group">
            {button1Label !== "" ? (
              <Button
                id="1"
                variant="contained"
                type="submit"
                size="medium"
                color="primary"
                className={classes.margin}
              >
                {button1Label}
              </Button>
            ) : (
              ""
            )}
            {button2Label !== "" ? (
              <Button
                id="2"
                size="medium"
                className={classes.margin}
                onClick={() => {
                  if (onClickButton2) {
                    return onClickButton2();
                  }
                  if (
                    button2GoBack &&
                    JSON.stringify(localformData) ===
                      JSON.stringify(initialFormData)
                  ) {
                    setOpenConfirmation(false);
                    props.openModel?.(false);
                    if (goBackPage) history.push(goBackPage);
                  } else {
                    setOpenConfirmation(true);
                  }
                }}
              >
                {button2Label}
              </Button>
            ) : (
              ""
            )}
          </div>
        </StyledForm>
        <Dialog
          open={openConfirmation}
          onClose={handleConfirmationClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>Unsaved changes</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to save your changes before leaving this page?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                const submitButton = document.getElementById("1");
                submitButton.click();
                if (goBackPage) {
                  goBackAfterSubmit.current = true;
                }
              }}
              variant="contained"
              size="small"
              color="primary"
              className={classes.margin}
            >
              Save and continue
            </Button>
            <Button
              onClick={() => {
                console.log("PROPS:", props);
                setOpenConfirmation(false);
                props.openModel?.(false);
                if (goBackPage) history.push(goBackPage);
              }}
              autoFocus
              variant="outlined"
              size="small"
            >
              Discard changes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
}
