//import BrandStorybookImage from "../../public/brand/brand-storybook.svg";

export const BrandInfo = {
  brandTitle: "SAICE Storybook : powered by TelePortal",
  brandUrl: "http://localhost:3000", //local obvs
  brandImage: `/brand/brand-storybook.svg`,
  header: {
    title: "SAICE",
    logo: "/brand/saice-logo.png",
    desktop: {
      logoWidth: "140px",
      logoHeight: "65px",
    },
    mobile: {
      logoWidth: "160px",
      logoHeight: "65px",
    },
  },
};

export const socialFeeds = {
  facebook: {
    url: "https://www.facebook.com/SAICECIVIL/",
    buttonText: "Find us on Facebook",
  },
  twitter: {
    url: "https://twitter.com/saice_civil",
    buttonText: "Follow us on Twitter",
  },
  linkedIn: {
    url: "https://www.linkedin.com/company/botswana-institute-of-chartered-accountants",
    buttonText: "Check us out on LinkedIn",
  },
  instagram: "https://instagram.com/saicecivil",
};
