//Import React Library
import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { logoutRequest } from "constants/msalConfig";
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
//Import Material-UI & third party Library
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Link,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import Row from "../../../../layout/Row";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

//data, components, style.....
import { useStyles } from "./style";
import { navbarItems } from "app/components/molecules/navbar/model/sideNavData";
import { appData } from "data/appData";
import { BrandInfo } from "brand/brandInfo";
import SessionComponent from "app/components/atoms/session";
import EntitleComponent from "app/components/atoms/entitlement";
import AppMenu from "./AppMenu";
import IconTextButton from "../../atoms/IconTextButton/IconTextButton";
import SiteMenu from "./SiteMenu";

import styled from "styled-components";
import { callRedirectAPI } from "utilities/utils";
import { useSelector, useDispatch } from "react-redux";

const setSessionTime = appData[0].utilities.setSessionTime;

export default function NavBar(props) {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const classes = useStyles();
  //const accountStatus = useSelector((state) => state.accountStatus);
  const accountStatus = "temp";
  const dispatch = useDispatch();

  //Logout Method
  const handleLogout = () => {
    localStorage.clear();
    dispatch({ type: "CLEAR_STORE" });
    callRedirectAPI(instance, logoutRequest, false);
  };

  const [mobileOpen, setMobileOpen] = React.useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const NavDrawer = styled(Drawer)`
    & > div {
      height: 100vh;
    }
  `;

  useEffect(() => {
    //INFO: locationChanged event will be fired when a menu item is pressed.
    window.addEventListener("locationChanged", () => {
      setMobileOpen((mobOpen) => !mobOpen);
    });

    return () => {
      window.removeEventListener("locationChanged", null);
    };
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLarge = useMediaQuery(theme.breakpoints.up("md"));
  const matchesPrint = useMediaQuery("print");

  return (
    <>
      {!matchesPrint && (
        <AppBar position="fixed" className={classes.appBar}>
          {/* logo */}

          <Box
            className={clsx(classes.titleBox, {
              [classes.noMenuButton]: !isAuthenticated,
            })}
          >
            <a href="/">
              <img
                src={BrandInfo.header.logo}
                alt="Logo"
                style={{ marginTop: "3px", marginLeft: ".5rem" }}
                width={BrandInfo.header.mobile.logoWidth}
              />
            </a>
          </Box>
          <Toolbar>
            {/* header nav (right aligned row of buttons) */}

            <AuthenticatedTemplate>
              {/* hamburger button */}

              {(accountStatus !== null || true) && (
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Row className={classes.titleBox1} gap="xs">
                <AuthenticatedTemplate>
                  {isLarge && accountStatus !== null && <SiteMenu />}
                  {/* <EntitleComponent /> */}
                  {setSessionTime ? <SessionComponent /> : ""}
                  {accountStatus !== null && getnavItems()}
                </AuthenticatedTemplate>
                {isAuthenticated ? (
                  <Link
                    onClick={() => handleLogout()}
                    component="a"
                    variant="body1"
                  >
                    <IconTextButton
                      color="black"
                      icon="logout"
                      labelText="Logout"
                    />
                  </Link>
                ) : (
                  ""
                )}
              </Row>
            </AuthenticatedTemplate>
          </Toolbar>
        </AppBar>
      )}
      <AuthenticatedTemplate>
        {isMobile && (
          <NavDrawer
            component="aside"
            variant={isMobile ? "temporary" : "permanent"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            className={isMobile ? "" : classes.desktopNav}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <IconButton
              onClick={handleDrawerToggle}
              className={classes.closeMenuButton}
            >
              <CloseIcon />
            </IconButton>
            <AppMenu />
          </NavDrawer>
        )}
      </AuthenticatedTemplate>
    </>
  );
}
//Navigation list items
const getnavItems = () => {
  let list = navbarItems[0].items;
  return list.map(({ labelText, linkUrl, iconUrl }) => {
    return (
      <Link
        {...{
          component: RouterLink,
          to: linkUrl,
          color: "inherit",
          style: { textDecoration: "none" },
          key: labelText,
        }}
      >
        <IconTextButton color="black" icon={iconUrl} labelText={labelText} />
      </Link>
    );
  });
};
