import { CAPS_LOCK_WARNING } from "constants/settings";

const thisDate = new Date().toISOString().split("T")[0];

export const identitySchema = {
  title: "Identity Details",
  logicalname: "Identity",
  type: "object",
  description: "",
  additionalDescription: CAPS_LOCK_WARNING,
  //required: ["name", "address", "primaryemail", "primarycontact"],
  properties: {
    ctpt_identitynumber: {
      title: "Identity Numer",
      type: "string",
      schemaname: "salutation",
      datatype: "string",
      requiredCheck: false,
      pattern: "",
      message: "",
      isReadonly: false,
    },
    ctpt_type: {
      title: "Type Of Identity",
      type: "string",
      requiredCheck: true,
      datatype: "array",
      tootltipText: "",
      helpText: "",
      enum: ["100000001", "100000004"],
      enumNames: ["South African ID Document", "South African Passport"],
    },
  },
};
