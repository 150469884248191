export const getrecordPayload =
{
    "properties": [
          "ctpt_name",
        "_ctpt_student_value",
        "ctpt_startdate",
        "ctpt_duration",
        "ctpt_enddate",
        "ctpt_studenttrainingagreementid"
    ],
    "formattedFields": [],
    "recordType": "ctpt_studenttrainingagreements",
    "filters": [
                {
                    "field": "ctpt_studenttrainingagreementid",
                    "searchvalue": "",
                    "type": "guid"
                }
             ],
             
    "idfield": "ctpt_studenttrainingagreementid"
}




