import { CAPS_LOCK_WARNING } from "constants/settings";

const thisDate = new Date().toISOString().split("T")[0];

export const memberhipStatusSchema = {
  title: "Membership Status",
  logicalname: "MembershipStatus",
  type: "object",
  description:
    "",
    additionalDescription: CAPS_LOCK_WARNING,
  //required: ["name", "address", "primaryemail", "primarycontact"],
  properties: {
    ctpt_membershipnumber: {
      title: "Membership Number",
      type: "string",
      datatype: "string",
      requiredCheck: false,
      pattern: "",
      message: "",
      disabled:true
    },
    ctpt_category: {
      title: "Category Name",
      type: "string",
      schemaname: "salutation",
      datatype: "string",
      requiredCheck: false,
      pattern: "",
      message: "",
      disabled:true
    },
    statecode: {
        title: "Active Status",
        type: "string",
        schemaname: "salutation",
        datatype: "string",
        requiredCheck: false,
        pattern: "",
        message: "",
        disabled:true
    },
    ctpt_onhold: {
        title: "On Hold Status",
        type: "string",
        schemaname: "salutation",
        datatype: "string",
        requiredCheck: false,
        pattern: "",
        message: "",
        disabled:true
    },
    ctpt_onholdreason: {
      title: "On Hold Reason",
      type: "string",
      schemaname: "firstname",
      datatype: "string",
      requiredCheck: true,
      pattern: "",
      message: "",
      disabled:true
    },
    ctpt_datejoined: {
      title: "Date Joined",
      type: "string",
      datatype: "string",
      requiredCheck: true,
      pattern: "",
      message: "",
      disabled:true
    }
  },
};