export const getlistPayload =
{
  "properties": [
      "ctpt_caseid",
      "ctpt_title",
      "ctpt_details",
      "createdon",
       "statuscode", 
       "modifiedon" , 
       "statecode",
       "ctpt_caseresolutiondetails",
       "ctpt_documenturl"
  ],
  "filter": {
      "globalfilter": "",
      "globalAndOperator": false,
      "fieldAndOperator": true,
      "filterfields": [{
        "field": "_ctpt_customerid_value",
        "searchvalue": "",
        "type": "guid"
      }]
  },
  "Aggregate": {
      "AggregateFields": []
  },
  "sortfield": {
      "field": "createdon",
      "sorttype": "desc"
  },
  "recordtype": "ctpt_cases",
  "idfield": "ctpt_caseid",
  "continuationToken": "",
  "pazesize": "1000"
}


export const deleteListPayload = {
  "recordtype": "ctpt_cases"
}

