import { CAPS_LOCK_WARNING } from "constants/settings";
import { GetDivisionListPayload } from "./payload"
import { config } from "utilities/config";
export const getDivisionListSchema = {
  title: "Edit Divisions",
  logicalname: "Contact2",
  type: "object",
  additionalDescription: CAPS_LOCK_WARNING,
  properties: {
    ctpt_divisionid: {
      title: "Divisions",
      type: "string",
      datatype: "array",
      enum: [],
      enumNames: [],
      isDynamic: true,
      payload: GetDivisionListPayload,
      apiURL: config.teleportal.submitForm.get,
      isDivision:true
    }
  }
};
