import CustomTextBox from "app/components/atoms/customTextBox";
import CustomDate from "app/components/atoms/customDate";

export const getStudentUiSchema = {
    "ui:title": "",
    ctpt_name: {
        "ui:widget": props => {
          return <CustomTextBox getProps={props} />;
          }
      },
    
    ctpt_startdate: {
      "ui:widget": props => {
        return <CustomDate getProps={props} />;
      }
  },
  ctpt_duration: {
    "ui:widget": props => {
      return <CustomTextBox getProps={props} />;
    }
},
ctpt_enddate: {
  "ui:widget": props => {
    return <CustomDate getProps={props} />;
  }
},
ctpt_studenttrainingagreementid: {
  "ui:widget": "hidden"
}
}


