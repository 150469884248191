import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useSelector } from "react-redux";
import styled from "styled-components";
import FormWidget from "app/components/mvp/formWidget";
import { getStudentSchema } from "./model/studentSchema";
import { getStudentUiSchema } from "./model/UiSchema";
import { getrecordPayload } from "./model/payload";
import { config } from "utilities/config";
import { useLocation } from "react-router-dom";
import { post } from "app/services/api";
import { CreaterequestPayload } from "./model/createPayload";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 300px;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function ViewRecord() {
  const [recordId, setrecordId] = useState("");
  const [contact2FormData, setContact2FormData] = useState({});
  const getcontactId = useSelector((state) => state.contactid);
  let query = useQuery();
  let formDataa = {};

  const getRecordDetails = (payload) => {
    let api = config.teleportal.submitForm.getId;
    if (recordId !== "") {
      post(api, payload)
        .then((res) => {
          res.data.data.forEach((item) => {
            console.log(item);
            formDataa[item.name] = item.value;
          });
          setContact2FormData(formDataa);
        })
        .catch(console.log);
    }
  };

  const getResponse = (res) => {
    console.log(res);
  };

  const options = {
    widgetType: "update",
    formSchema: getStudentSchema,
    uiSchema: getStudentUiSchema,
    apiURL: config.teleportal.submitForm.Update,
    entityType: "ctpt_studenttrainingagreements",
    formUischema: "",
    button1Label: "Update",
    button2Label: "Cancel",
    historyUrl: "/membership/student-training-agreement",
    goBackPage: "/membership/student-training-agreement",
    button2GoBack: true,
    columns: [],
    contactId: getcontactId,
    payloadSubmit: CreaterequestPayload,
    formData: contact2FormData,
    initialFormData: contact2FormData,
    hasModel: false,
  };

  useEffect(() => {
    if (window.location.href.indexOf("?") > -1) {
      setrecordId(query.get("id"));
    }
  }, []);

  useEffect(() => {
    let payload = {
      ...getrecordPayload,
      filters: [
        {
          field: "ctpt_studenttrainingagreementid",
          searchvalue: recordId,
          type: "guid",
        },
      ],
    };

    getRecordDetails(payload);
  }, [recordId]);

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <Grid container spacing={0}>
          {/* NOTE:layout */}
          {/* <Grid item xs={12} md={2}></Grid> */}
          <Grid item xs={12} md={12}>
            <StartPage>
              <FormWidget
                options={options}
                successResponse={getResponse}
              ></FormWidget>
            </StartPage>
          </Grid>
          {/* <Grid item xs={12} md={2}></Grid> */}
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default ViewRecord;
