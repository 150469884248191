import { CAPS_LOCK_WARNING } from "constants/settings";

export const getStudentSchema = {
  title: "Student Training Agreement",
  logicalname: "Contact2",
  type: "object",
  description: CAPS_LOCK_WARNING,
  properties: {
    ctpt_name: {
      title: "Student Name",
      type: "string",
      schemaname: "ctpt_name",
      datatype: "string",
      helpText:"",
      requiredCheck:true,
      pattern:"^[a-zA-Z' ']*$",
      message:"Only string allowed"
      
    },
    
    ctpt_startdate: {
      title: "Start Date",
      type: "string",
      schemaname: "ctpt_startdate",
      datatype: "string",
      requiredCheck:true,
      message:"Please select start date",
      pattern:""
    },
    ctpt_duration: {
      title: "Duration",
      type: "string",
      schemaname: "ctpt_duration",
      datatype: "string",
      helpText:"",
      requiredCheck:true,
      message:"Please enter number",
      pattern:"^[[0-9]*]{0,9}$"
    },
    ctpt_enddate: {
      title: "End Date",
      type: "string",
      schemaname: "ctpt_enddate",
      datatype: "string",
      helpText:"End Date should be manually inputted as the addition of Start Date with Duration",
      requiredCheck:true,
      message:"Please select end date",
      pattern:""
    },
    ctpt_studenttrainingagreementid: {
      title: "Owener Id",
      type: "string",
      schemaname: "ctpt_studenttrainingagreementid",
      datatype: "string",
      helpText:""
    }
  }
};

