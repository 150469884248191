import { post } from "app/services/api";
import { config } from "utilities/config";

export const CreaterequestPayload = (JsonData) => {
  const details = JsonData.data;
  return {
    recordtype: "ctpt_organisationhistories",
    data: [
      {
        name: "ctpt_Applicant",
        value: JsonData.contactid,
        type: "lookup",
        relatedentity: "contacts",
      },
      {
        name: "ctpt_OrganisationName",
        value: details[0].value._ctpt_organisationname_value || "",
        type: "lookup",
        relatedentity: "ctpt_organisations",
      },
      {
        name: "ctpt_otherorganisationname",
        value:
          details[0].value._ctpt_organisationname_value ==
          "342136ea-c8fb-ec11-82e5-00224881f1d4"
            ? details[0].value.ctpt_otherorganisationname
            : "",
        type: "string",
      },
      {
        name: "ctpt_OrganisationCountry",
        value: details[1].value || "",
        type: "lookup",
        relatedentity: "ctpt_countries",
      },
      {
        name: "ctpt_RegistrationCategory",
        value: details[2].value._ctpt_registrationcategory_value || "",
        type: "lookup",
        relatedentity: "ctpt_registrationcategories",
      },
      {
        name: "ctpt_otherregistrationcategory",
        value:
          details[2].value._ctpt_registrationcategory_value ==
          "959698ba-c9fb-ec11-82e5-00224881f7ec"
            ? details[2].value.ctpt_otherregistrationcategory
            : "",
        type: "string",
      },
      {
        name: "ctpt_yearofelection",
        value: details[3].value || "",
        type: "string",
      },
      {
        name: "ctpt_registrationnumber",
        value: details[4].value || "",
        type: "string",
      },
    ],
  };
  //return payload
};
