import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { contactSchema } from "app/components/mvp/formWidget/model/contactJson";
import { contactUiSchema } from "app/components/uiSchema/contactUiSchema";
import { ContactPayload } from "app/services/payloadSubmit/contactPayload";
import { config } from "utilities/config";
import { post } from "app/services/api";
import FormWidget from "app/components/mvp/formWidget";

function AboutYou(props) {
  const userEntitlement = useSelector((state) => state.entitleMents);
  const userRole = useSelector((state) => state.role);
  let permissions =
    userEntitlement && userEntitlement.find((x) => x.route == props.match.path);
  const contactid = useSelector((state) => state.contactid);
  const [contactFormData, setContactFormData] = useState({});
  const dispatch = useDispatch();

  const contact = {
    widgetType: "updateContact",
    formSchema: contactSchema,
    uiSchema: contactUiSchema,
    formData: contactFormData,
    apiURL: config.teleportal.submitForm.Update,
    payloadSchema: {},
    entityType: "contacts",
    formUischema: "",
    button1Label: "Save",
    button2Label: "Go Back",
    payloadSubmit: ContactPayload,
    contactId: contactid,
    goBackPage: "/profile",
    button2GoBack: true,
    historyUrl: "",
  };

  const [contactOptions, setContactOptions] = useState(contact);

  useEffect(() => {
    if (contactid.length > 0) {
      console.log("Coming here 2 times ?? ");
      getContactDetails();
    }
  }, [contactid]);

  const updateFormData = (entityType, formData) => {
    // if (
    //   userRole.name && userRole.name.includes('Individual') &&
    //   permissions &&
    //   permissions.permissionsets != null &&
    //   permissions.permissionsets.length > 0 &&
    //   permissions.permissionsets[0] != null &&
    //   permissions.permissionsets[0].canwrite == '0' &&
    //   permissions.permissionsets[0].cancreate == '0'
    // ) {
    //   contact.formSchema.properties.firstname.isReadonly = true
    //   contact.formSchema.properties.lastname.isReadonly = true
    //   contact.formSchema.properties.birthdate.isReadonly = true
    //   contact.formSchema.properties.ctpt_isresidentofbotswana.isReadonly = true
    //   contact.formSchema.properties.ctpt_othername.isReadonly = true
    //   contact.formSchema.properties.ctpt_profiledetailsconfirmedaccurateon.isReadonly = true
    //   contact.formSchema.properties.fax.isReadonly = true
    //   contact.formSchema.properties.fullname.isReadonly = true
    //   contact.formSchema.properties.salutation.isReadonly = true
    // } else if (
    //   userRole.name && userRole.name.includes('Student') &&
    //   permissions &&
    //   permissions.permissionsets != null &&
    //   permissions.permissionsets.length > 0 &&
    //   permissions.permissionsets[0] != null &&
    //   permissions.permissionsets[0].canwrite == '0' &&
    //   permissions.permissionsets[0].cancreate == '0'
    // ) {
    //   contact.formSchema.properties.firstname.isReadonly = true
    //   contact.formSchema.properties.lastname.isReadonly = true
    //   contact.formSchema.properties.birthdate.isReadonly = true
    // }
    /* NOTE-This needs to be put back for entitlements */
    contact.formData = formData;
    contact.initialFormData = formData;
    setContactOptions(contact);
    setContactFormData(formData);
  };

  const getContactDetails = () => {
    let api, api2;
    let payloads, specialistPayload;
    let ctpt_Specialist_Contact_Contact = [];
    const formData = {};
    api = config.teleportal.submitForm.getId;
    api2 = config.teleportal.submitForm.get;

    payloads = {
      properties: [
        "contactid",
        "ctpt_title",
        "firstname",
        "lastname",
        "fullname",
        "middlename",
        "ctpt_othername",
        "ctpt_Specialist_Contact_Contact",
        "birthdate",
        // 'fax',
        "ctpt_areyouacitizenofsouthafrica",
        "ctpt_profiledetailsconfirmedaccurateon",
      ],
      formattedFields: ["gendercode"],
      recordType: "contacts",
      id: contactid,
    };
    specialistPayload = {
      properties: ["contactid", "ctpt_specialistid"],
      filter: {
        globalfilter: "",
        globalAndOperator: false,
        fieldAndOperator: false,
        filterfields: [
          {
            field: "contactid",
            searchvalue: contactid,
            type: "guid",
          },
        ],
      },
      recordtype: "ctpt_specialist_contactset",
      idfield: "ctpt_specialist_contactid",
      continuationToken: "",
      pagesize: "1000",
    };
    post(api, payloads)
      .then((res) => {
        res.data.data.forEach((x) => {
          console.log("x.name--" + x.name);
          if (x.name === "ctpt_profiledetailsconfirmedaccurateon") {
            formData["ctpt_profiledetailsconfirmedaccurateon"] = new Date();
          }
          formData[x.name] = x.value;
        });
        post(api2, specialistPayload).then((res) => {
          res.data.data.forEach((x) => {
            x &&
              x.data[1] &&
              x.data[1].value &&
              ctpt_Specialist_Contact_Contact.push(x.data[1].value);
          });
          formData["ctpt_specialists"] =
            ctpt_Specialist_Contact_Contact;
          updateFormData({}, formData);
        });
      })
      .catch(console.log);
  };

  const getResponse = (res) => {
    if (res.data.id) {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record updated successfully",
          code: "",
          display: "show",
          severity: "success",
        },
      });
    } else {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record update failed",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    }
  };

  return (
    <Grid container spacing={0} justify="center">
      <Grid item xs={12} md={9}>
        <FormWidget
          options={contactOptions}
          updateFormData={updateFormData}
          successResponse={getResponse}
        />
      </Grid>
    </Grid>
  );
}

export default AboutYou;
