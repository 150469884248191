import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { memberhipStatusSchema } from "app/components/mvp/formWidget/model/membershipStatus";
import { memershipStatusUiSchema } from "app/components/uiSchema/membershipStatusUiSchema";
import { ContactPayload } from "app/services/payloadSubmit/contactPayload";
import { config } from "utilities/config";
import { post } from "app/services/api";
import FormWidget from "app/components/mvp/formWidget";
import moment from "moment";

function MembershipStatus(props) {
  // const userEntitlement = useSelector(state => state.entitleMents)
  // const userRole = useSelector(state => state.role)
  // let permissions =
  //   userEntitlement && userEntitlement.find(x => x.route == props.match.path)
  const contactid = useSelector((state) => state.contactid);
  const [contactFormData, setContactFormData] = useState({});
  const dispatch = useDispatch();

  const contact = {
    widgetType: "updateContact",
    formSchema: memberhipStatusSchema,
    uiSchema: memershipStatusUiSchema,
    formData: contactFormData,
    apiURL: config.teleportal.submitForm.Update,
    payloadSchema: {},
    entityType: "contacts",
    formUischema: "",
    button1Label: "",
    button2Label: "Go Back",
    payloadSubmit: ContactPayload,
    contactId: contactid,
    goBackPage: "/profile",
    button2GoBack: true,
    historyUrl: "",
  };

  const [contactOptions, setContactOptions] = useState(contact);

  useEffect(() => {
    if (contactid.length > 0) {
      console.log("Coming here 2 times ?? ");
      getContactDetails();
    }
  }, [contactid]);

  const updateFormData = (entityType, formData) => {
    contact.formData = formData;
    contact.initialFormData = formData;
    setContactOptions(contact);
    setContactFormData(formData);
  };

  const getContactDetails = () => {
    let api;
    let payloads;
    //let ctpt_Specialist_Contact_Contact=[]
    const formData = {};
    api = config.teleportal.submitForm.getId;
    payloads = {
      properties: [
        "contactid",
        "ctpt_category",
        // 'statuscode',
        // "ctpt_onhold",
        "ctpt_onholdreason",
        "ctpt_datejoined",
        "ctpt_membershipnumber"
      ],
      formattedFields: ["ctpt_onhold", "statecode", "ctpt_datejoined"],
      recordType: "contacts",
      id: contactid,
    };

    post(api, payloads)
      .then((res) => {
        res.data.data.forEach((x) => {
          formData[x.name] = x.name === "ctpt_datejoined" ? moment(x.id).format("MM/DD/YYYY") : x.value;
        });
        updateFormData({}, formData);
      })
      .catch(console.log);
  };

  const getResponse = (res) => {
    if (res.data.id) {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record updated successfully",
          code: "",
          display: "show",
          severity: "success",
        },
      });
    } else {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record update failed",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    }
  };

  return (
    <Grid container spacing={0} justify="center">
      <Grid item xs={12} md={9}>
        <FormWidget
          options={contactOptions}
          updateFormData={updateFormData}
          successResponse={getResponse}
        />
      </Grid>
    </Grid>
  );
}

export default MembershipStatus;
