const { isValidSouthAfricanId } = require("../../../../utilities/validation");
const southAfricanIdDropdownValue = "100000001";

const validateSouthAfricanId = (formData, errors) => {
  const idType = formData?.["typeOfIdentity"];
  const idNumber = formData?.["citizenid"];

  // If the south african id is not selected don't apply validation
  if (idType !== southAfricanIdDropdownValue) return errors;

  const error = isValidSouthAfricanId(idNumber);
  if (error) {
    errors?.["citizenid"].addError(error);
  }

  return errors;
};

const validateIfEmailsAreSame = (fieldContainer, fieldNames, errors) => {
  if (!fieldContainer) {
    return errors;
  }
  if (
    fieldContainer[fieldNames[0]] &&
    fieldContainer[fieldNames[0]] === fieldContainer[fieldNames[1]]
  ) {
    return errors[fieldNames[1]].addError(
      "The alternate email address cannot be the same as applicant email address"
    );
  }
};

const validateIfContactNumbersAreSame = (
  fieldContainer,
  fieldNames,
  errors
) => {
  if (!fieldContainer) {
    return errors;
  }
  if (
    fieldContainer[fieldNames[0]] &&
    fieldContainer[fieldNames[0]] === fieldContainer[fieldNames[1]]
  ) {
    return errors[fieldNames[1]].addError(
      "The alternate phone number cannot be the same as applicant phone/mobile number"
    );
  }
};

const validateIfNonMandatoryFieldsAreEmpty = (
  fieldContainer,
  fieldNames,
  errors
) => {
  if (!fieldContainer) {
    return errors;
  }

  if (
    fieldContainer[fieldNames[0]] !== undefined &&
    fieldContainer[fieldNames[0]].length === 0
  ) {
    console.log("Errors Coming in custom validate ?? ", errors);
    delete errors[fieldNames[0]];
  }
  if (
    fieldContainer[fieldNames[1]] &&
    fieldContainer[fieldNames[1]].length === 0
  ) {
    console.log("Errors Coming in custom validate second ?? ", errors);
    delete errors[fieldNames[1]];
  }
  return errors;
};

export const customValidate = (formData, errors) => {
  validateIfEmailsAreSame(
    formData["contactDetails"],
    ["email", "altEmail"],
    errors["contactDetails"]
  );
  validateIfContactNumbersAreSame(
    formData["contactDetails"],
    ["mobileNumber", "altContactNumber"],
    errors["contactDetails"]
  );
  //validateIfNonMandatoryFieldsAreEmpty(formData["contactDetails"], ["workNumber", "homeContactNumber"], errors["contactDetails"])
  validateSouthAfricanId(
    formData?.["citizenofsaConditional"],
    errors?.["citizenofsaConditional"]
  );
  return errors;
};
