import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import styled, { css } from "styled-components";
import Icon from "@material-ui/core/Icon";
import { globalTheme } from "style/globalTheme";

const BtnBox = styled.div(
  (props) => css`
    display: flex;
    flex-direction: column;
    // width: 3rem;
    width: max-content;
    align-items: center;
    justify-items: center;
    & .MuiIcon-root,
    & small {
      color: ${props.color};
    }
    & small {
      display: block;
      margin-top: -0.5rem;
      font-size: 0.8rem;
    }
  `
);
/** The `IconTextButton` component renders a `<div>` containing a Material-UI IconButton with a label beneath it. */
const IconTextButton = ({ icon, labelText, color = globalTheme.text }) => {
  return (
    <BtnBox className="iconBtn" color={color}>
      <IconButton>
        <Icon>{icon}</Icon>
      </IconButton>
      <small>{labelText}</small>
    </BtnBox>
  );
};

IconTextButton.propTypes = {
  /** The name of the icon (in `snake_case`) */
  icon: PropTypes.string.isRequired,
  /** The label for the icon button (keep to a single word if possible and keep it SHORT! */
  labelText: PropTypes.string,
  /** The colour of the element (applied to both icon and text) any valid HTML colour value (as a string) or pull a theme colour*/
  color: PropTypes.string,
};
export default IconTextButton;
