import React, { useEffect, useState } from 'react'
import { AuthenticatedTemplate } from '@azure/msal-react'
import { ThemeProvider } from '@material-ui/core/styles'
import { globalTheme } from 'style/globalTheme'
import styled from 'styled-components'
import SuccessIcon from '@material-ui/icons/CheckCircle'
import { useHistory } from 'react-router-dom'
import { useStyles } from './style'
import { Button, Box } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { postPay } from 'app/services/api'
import { config } from 'utilities/config'
import { post } from 'app/services/api'
import { useSelector } from 'react-redux'
import { PAYMENT_STATUS_MAP } from "./model/constants";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up('sm')} {
    padding: 4rem 4rem 4rem 300px;
  }
  ${globalTheme.breakpoints.down('xs')} {
    padding: 1rem;
  }
`

function PaymentSuccess() {
  const classes = useStyles()
  const [payDetails, setpayDetails] = useState(null)
  const [amount, setAmount] = useState(localStorage.getItem('amount'))
  const [refNumber, setRefNumber] = useState("");
  const [transactionStatus, setTransactionStatus] = useState('')
  const [transactionStatusCode, setTransactionStatusCode] = useState('')
  const [payloads, setpayloads] = useState({
    orderId: ''
  })
  const [invoiceNumber, setInvoiceNumber] = useState('')
  const dispatch = useDispatch()
  let history = useHistory()

  useEffect(() => {
    setInvoiceNumber(localStorage.getItem("invoiceNumber"));
  }, []);

  useEffect(() => {
    let data = {
      payRequestId: localStorage.getItem('PAY_REQUEST_ID'),
      reference: localStorage.getItem('REFERENCE'),
      authToken: localStorage.getItem('token')
    }
    axios
      .post(config.payment.env + config.payment.queryTransactionSync, data)
      .then(res => {
        setAmount(res.data.amount/100)
        setRefNumber(res.data.reference)
        setTransactionStatusCode(res.data.result_Code)
        setTransactionStatus(PAYMENT_STATUS_MAP[res.data.result_Code])
        // if (res.data.result_Code === '900003') {
        //   setTransactionStatus('InsufficientFunds')
        // } else if (res.data.result_Code === '900007') {
        //   setTransactionStatus('DeclinedFunds')
        // } else if (res.data.result_Code === '990022') {
        //   setTransactionStatus('UnprocessedFunds')
        // } else if (res.data.result_Code === '900004') {
        //   setTransactionStatus('InvalidCard')
        // } else if (res.data.result_Code === '990017') {
        //   setTransactionStatus('Success')
        // }
        // console.log("STATUSSSSSS----"+PAYMENT_STATUS_MAP[res.data.result_Code])
      })
      .catch(err => {
        console.log('Transaction process failed:', err)
      })
      .finally(() => {
        //enableLoader(false);
      })
  }, [])

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <StartPage>
          {transactionStatus === 'Success' ? (
            <>
              <h1 className={classes.headingTxt}>
                Thank you for your Application
              </h1>
              {localStorage.getItem("invoiceNumber") ? <p>{`Your reference number ${invoiceNumber || "XXXXXXXX"}`}</p> : null}
              <div className={classes.desc}>
                <div className={classes.paymentWrapper}>
                  <h4>Payment Summary</h4>
                  {/* {payDetails !== null
                    ? payDetails.products.map((element, index) => {
                        return (
                          <Box sx={{ display: "flex" }}>
                            <div className={classes.tableDesc}>
                              {element.description !== null
                                ? element.description
                                : " "}
                            </div>
                            <div className={classes.tablePrice}>
                              {element.amount}
                            </div>
                          </Box>
                        );
                      })
                    : ""} */}
                  <Box sx={{ display: 'flex' }} className={classes.total}>
                    <div className={classes.tableDesc}>Total paid</div>
                    <div className={classes.tablePrice}>
                      {/* {payDetails !== null
                        ? payDetails.invoiceTotal.invoideTotal
                        : ""} */}
                      {amount}
                    </div>
                  </Box>
                </div>
              </div>
              <div className={classes.notes}>
                <p>
                  Your application has now been submitted to our membership team
                  for review. You will be notified by email once it is approved
                  or a member of our team will contact you if required. If you
                  have any queries in relation to your application, please
                  contact us.
                </p>
              </div>
              <Box sx={{ p: 2 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => history.push('/')}
                >
                  {' '}
                  Return to Membership Homepage
                </Button>
              </Box>
            </>
          ): transactionStatus.length>0 ? (
            <>
              <h1 className={classes.failureHeadingTxt}>
                Sorry, Payment transaction failed !!!
              </h1>

              <div className={classes.desc}>
                <p></p>
                <ul>
                  <li>Transaction Status: <span style={{ fontWeight: "bold" }} >{transactionStatus}</span>​</li>
                  <li>Status Code: <span style={{ fontWeight: "bold" }} >{transactionStatusCode}</span>​</li>
                </ul>
              </div>

              <Box sx={{ p: 5 }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => history.push("/")}
                >
                  {" "}
                  Go Back to Homepage​
                </Button>
              </Box>
            </>
          ): null}

          {/* {transactionStatus === 'InsufficientFunds' && }
          {transactionStatus === 'DeclinedFunds' && (
            <>
              <h1 className={classes.headingTxt}>
                Sorry, Payment transaction failed !!!
              </h1>

              <div className={classes.desc}>
                <p></p>
                <ul>
                  <li>Transaction Status: <span style={{ fontWeight: "bold" }} >{transactionStatus}</span>​</li>
                  <li>Status Code: <span style={{ fontWeight: "bold" }} >{transactionStatusCode}</span>​</li>
                </ul>
              </div>

              <Box sx={{ p: 5 }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => history.push("/")}
                >
                  {" "}
                  Go Back to Homepage​
                </Button>
              </Box>
            </>
          )}
          {transactionStatus === 'UnapprovedFunds' && (
            <>
              <h1 className={classes.headingTxt}>
                Sorry, Payment transaction failed !!!
              </h1>

              <div className={classes.desc}>
                <p></p>
                <ul>
                  <li>Transaction Status: <span style={{ fontWeight: "bold" }} >{transactionStatus}</span>​</li>
                  <li>Status Code: <span style={{ fontWeight: "bold" }} >{transactionStatusCode}</span>​</li>
                </ul>
              </div>

              <Box sx={{ p: 5 }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => history.push("/")}
                >
                  {" "}
                  Go Back to Homepage​
                </Button>
              </Box>
            </>
          )}
          {transactionStatus === 'InvalidCard' && (
            <>
              <h1 className={classes.headingTxt}>
                Sorry, Payment transaction failed !!!
              </h1>

              <div className={classes.desc}>
                <p></p>
                <ul>
                  <li>Transaction Status: <span style={{ fontWeight: "bold" }} >{transactionStatus}</span>​</li>
                  <li>Status Code: <span style={{ fontWeight: "bold" }} >{transactionStatusCode}</span>​</li>
                </ul>
              </div>

              <Box sx={{ p: 5 }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => history.push("/")}
                >
                  {" "}
                  Go Back to Homepage​
                </Button>
              </Box>
            </>
          )} */}
        </StartPage>
      </AuthenticatedTemplate>
    </ThemeProvider>
  )
}

export default PaymentSuccess
