import React, { useEffect, useState, useRef } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import styled from "styled-components";
import { useStyles } from "./style";
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { postPay } from "app/services/api";
import { config } from "utilities/config";
import { useHistory, useLocation } from "react-router-dom";
import { post } from "app/services/api";
import { usePreLoader } from "app/services/hooks/usePreLoader";
import {
  getSWFID,
  getSWFSubmissionID,
  showMembershipFeeHeading,
} from "./service";
import CreateOrder from "brand/components/order";
import { invoiceNewdata } from "./model/invoiceData";
import Invoice from "brand/components/invoice";
import "./styles.css";
import WithLoader from "./components/WithLoader";
import PaymentMethods from "./components/PaymentMethods";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 300px;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function Prepayment(props) {
  const contactid = useSelector((state) => state.contactid);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [payDetails, setpayDetails] = useState(null);
  const [hasError, sethasError] = useState(false);
  const [payloads, setpayloads] = useState({
    orderId: "",
    type: "",
  });
  const [eventID, setEventID] = useState("");
  const [open, setOpen] = React.useState(false);
  const getCancelUrl = useSelector((state) => state.redirectUrls.payment);
  let history = useHistory();
  const downloadFunc = React.useRef(null);
  let query = useQuery();
  const [paymentMethod, setPaymentMethod] = React.useState("1");
  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [vatNumber, setVatNumber] = React.useState("");
  const [addressLine1, setAddressLine1] = React.useState("");
  const [addressLine2, setAddressLine2] = React.useState("");
  const [suburb, setSuburb] = React.useState("");
  const [City, setCity] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  //const [country, setCountry] = React.useState("");
  const [payLater, setPayLater] = useState(false);
  const docUrl = useSelector((state) => state.docUrl);
  const { enableLoader } = usePreLoader();
  const submissionID = useRef(null);
  const [emailError, setEmailError] = React.useState("");
  const emailRegex =
    /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,4}$/;

  const [hidePayThroughSponsor, setHidePayThroughSponsor] = useState(false);

  const radioHandler = (event) => {
    console.log("status--" + event.target.value);
    setPaymentMethod(event.target.value);
  };

  const handleInputChange = (event) => {
    if (event.target.id === "fullName") setFullName(event.target.value);
    else if (event.target.id === "email") {
      setEmail(event.target.value);
      if (event.target.value.match(emailRegex)) {
        setEmailError("");
      } else {
        setEmailError("Invalid Email");
      }
    } else if (event.target.id === "companyName") {
      setCompanyName(event.target.value);
    } else if (event.target.id === "vatNumber") {
      setVatNumber(event.target.value);
    } else if (event.target.id === "companyName") {
      setCompanyName(event.target.value);
    } else if (event.target.id === "vatNumber") {
      setVatNumber(event.target.value);
    } else if (event.target.id === "addressLine1") {
      setAddressLine1(event.target.value);
    } else if (event.target.id === "addressLine2") {
      setAddressLine2(event.target.value);
    } else if (event.target.id === "suburb") {
      setSuburb(event.target.value);
    } else if (event.target.id === "City") {
      setCity(event.target.value);
    } else if (event.target.id === "postalCode") {
      setPostalCode(event.target.value);
    }
    // else if (event.target.id === "country") {
    //   setCountry(event.target.value);
    // }
  };

  const recalculateOrder = async () => {
    const swfID = await getSWFID("eventreg");
    const swfSubmissionID = await getSWFSubmissionID(payloads.orderId);
    submissionID.current = swfSubmissionID;

    if (swfID && swfSubmissionID && contactid) {
      const orderCreatePayload = {
        applicationtype: "event",
        contactid: contactid,
        data: {
          eventid: eventID,
        },
        sonicwebformid: swfID,
        swfsubmissionid: swfSubmissionID,
      };
      return post(config.teleportal.order.create, orderCreatePayload);
    }
  };

  useEffect(() => {
    const membershipSection = JSON.parse(
      localStorage.getItem("membershipSection")
    );
    if (membershipSection) {
      setFullName(membershipSection.billingContactName);
      setEmail(membershipSection.billingEmail);
      setCompanyName(membershipSection.companyName);
      setVatNumber(membershipSection.vatNumber);
      setAddressLine1(membershipSection.addressLine1);
      setAddressLine2(membershipSection.addressLine2);
      setSuburb(membershipSection.suburb);
      setCity(membershipSection.City);
      setPostalCode(membershipSection.postalCode);
      //setCountry(membershipSection.country);
    }
  }, []);

  useEffect(() => {
    if (contactid) {
      enableLoader(false);
    } else {
      enableLoader(true);
    }
  }, [contactid]);

  const getInvoice = () => {
    enableLoader(true);
    postPay(config.payment.orderDetails, payloads)
      .then((res) => {
        sethasError(!res.data.data);
        setpayDetails(res.data.data);
        dispatch({ type: "SET_PAYSUMMARY", payload: res.data.data });
      })
      .catch(() => {
        sethasError(true);
        dispatch({
          type: "SET_STATUS",
          payload: {
            message: "Oops something went wrong!",
            code: "",
            display: "show",
            severity: "error",
          },
        });
      })
      .finally(() => {
        enableLoader(false);
      });
  };

  const cancelPayment = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const cancelClick = () => {
    setOpen(false);
    history.push(getCancelUrl);
  };

  const finishPayment = () => {
    enableLoader(true);
    if (paymentMethod == "2") {
      const apiURL = config.teleportal.submitForm.Create;
      const payload = {
        recordtype: "ctpt_supportingdocuments",
        data: [
          {
            name: "ctpt_name",
            value: "PaymentProof",
            type: "string",
          },
          {
            name: "ctpt_documenttype",
            value: 100000001,
            type: "picklist",
          },
          {
            name: "ctpt_CustomerId_contact",
            value: contactid,
            type: "lookup",
            relatedentity: "contacts",
          },
          {
            name: "ctpt_documenturl",
            value: docUrl !== "" ? docUrl : "",
            type: "string",
          },
        ],
      };
      post(apiURL, payload)
        .then((res) => {
          if (res) {
            history.push("/application/prepayment/payment-success");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          enableLoader(false);
        });
    } else if (paymentMethod == "3") {
      const membershipSection =
        localStorage.getItem("membershipSection") &&
        JSON.parse(localStorage.getItem("membershipSection"));
      const apiURL = config.teleportal.submitForm.Update;
      const payload = {
        recordtype: "salesorders",
        id: localStorage.getItem("oid"),
        data: [
          {
            name: "ctpt_paymentmethod",
            value: "533910002",
            type: "string",
          },
          {
            name: "ctpt_sponsoremail",
            value: email ? email : "",
            type: "string",
          },
          {
            name: "ctpt_sponsorname",
            value: fullName ? fullName : "",
            type: "string",
          },
          {
            name: "ctpt_companyname",
            value: companyName ? companyName : "",
            type: "string",
          },
          {
            name: "ctpt_vatnumber",
            value: vatNumber ? vatNumber : "",
            type: "string",
          },
          {
            name: "address3_line1",
            value: addressLine1 ? addressLine1 : "",
            type: "string",
          },
          {
            name: "address3_line2",
            value: addressLine2 ? addressLine2 : "",
            type: "string",
          },
          {
            name: "address3_county",
            value: suburb ? suburb : "",
            type: "string",
          },
          {
            name: "address3_city",
            value: City ? City : "",
            type: "string",
          },
          {
            name: "address3_postalcode",
            value: postalCode ? postalCode : "",
            type: "string",
          },
          // {
          //   name: "address3_country",
          //   value: country ? country : "",
          //   type: "string",
          // },
        ],
      };
      post(apiURL, payload)
        .then((res) => {
          if (res) {
            localStorage.removeItem("membershipSection");
            history.push("/application/prepayment/payment-success");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          enableLoader(false);
        });
    }
  };

  useEffect(() => {
    //INFO: invoice number is used as reference number in payment success page.
    localStorage.setItem("invoiceNumber", payDetails?.invoiceNumber || "");
  }, [payDetails]);

  useEffect(() => {
    if (payloads.orderId !== "") {
      if (eventID) {
        enableLoader(true);
        if (!contactid) return;
        recalculateOrder().then(() => {
          props.getProcess(
            { swfsubmissionid: submissionID.current },
            getInvoice
          );
        });
      } else {
        getInvoice();
      }
    }
  }, [payloads, eventID, contactid]);

  useEffect(() => {
    setpayDetails(invoiceNewdata);
    let typeNum = 0;
    //   if (window.location.href.indexOf("?") > -1) {
    //     typeNum = query.get("type");
    //     setPayLater(query.get("payLater"));
    //     const applicationType = query.get("swfName");
    //     if (applicationType === "eventreg") {
    //       setEventID(query.get("eid"));
    //     }
    //     if (applicationType === "eventreg" || applicationType === "examreg") {
    //       setHidePayThroughSponsor(true);
    //     }
    //   console.log(typeNum);
    // }

    let oId = localStorage.getItem("oid");
    let SId = localStorage.getItem("sId");
    setpayloads({
      orderId: oId,
      //orderId: '5267744c-b093-ec11-b400-6045bd0eebfa',
      type: typeNum,
      swfsubmissionid: SId,
      //swfsubmissionid: 'a2df4ecb-af93-ec11-b400-6045bd0eebfa'
    });
  }, []);

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <StartPage>
          {localStorage.getItem("oid") !== null ? (
            <div>
              <h1>Make Payment</h1>
              <p>
                Please check your order details below, and choose a payment
                method to proceed and complete your order.
              </p>
              <WithLoader showLoader={!payDetails} hasError={hasError}>
                <div className={classes.paymentWrapper}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      p: 1,
                      m: 1,
                      bgcolor: "background.paper",
                    }}
                  >
                    Currency: {payDetails?.currencyCode}
                  </Box>
                  {showMembershipFeeHeading(payDetails)}

                  {payDetails?.products?.map((element) => {
                    return (
                      <>
                        <Box sx={{ display: "flex" }}>
                          <div className={classes.tableDesc}>
                            {element.description !== null
                              ? element.description
                              : " "}
                          </div>
                          <div className={classes.tablePrice}>
                            {element.amount}
                          </div>
                        </Box>
                      </>
                    );
                  })}
                  <Box sx={{ display: "flex" }} className={classes.total}>
                    <div className={classes.tableDesc}>Total Due</div>
                    {payDetails && (
                      <div className={classes.tablePrice}>
                        {payDetails.invoiceTotal.invoideTotal}
                      </div>
                    )}
                  </Box>
                </div>
                <div className={classes.downloadWrapper}>
                  {payDetails && (
                    <div>
                      <h4>Download here</h4>
                      To download an invoice for your order, please{" "}
                      <span
                        className={classes.downloadBtn}
                        onClick={() => downloadFunc.current()}
                      >
                        click here
                      </span>
                      (
                      <span className={classes.itlTxt}>
                        the file will automatically be downloaded into your
                        browser window
                      </span>
                      )
                      <Invoice
                        payDetails={payDetails}
                        label="Download Invoice"
                        downloadFunc={downloadFunc}
                      />
                    </div>
                  )}
                </div>
                <div className={classes.select}>
                  <h4>Choose your Payment Method</h4>
                  <p>
                    You can pay by bank transfer OR upload proof of payment OR
                    you can make a payment now by card OR someoone can sponsor
                    you application.
                  </p>

                  {paymentMethod && paymentMethod !== "" && (
                    <div>
                      <div className={classes.customRadio}>
                        <input
                          type="radio"
                          id="1"
                          name="customRadio"
                          className="custom-control-input"
                          onChange={radioHandler}
                          checked={paymentMethod === "1" ? true : false}
                          value="1"
                        />
                        <label className={classes.customLabel} htmlFor="1">
                          Pay Now using Credit or Debit Card
                        </label>
                      </div>
                      <div className={classes.customRadio}>
                        <input
                          type="radio"
                          id="2"
                          name="customRadio"
                          className="custom-control-input"
                          onChange={radioHandler}
                          checked={paymentMethod === "2" ? true : false}
                          value="2"
                        />
                        <label className={classes.customLabel} htmlFor="2">
                          Upload Proof of Payment
                        </label>
                      </div>
                      {!hidePayThroughSponsor && (
                        <div className={classes.customRadio}>
                          <input
                            type="radio"
                            id="3"
                            name="customRadio"
                            className="custom-control-input"
                            onChange={radioHandler}
                            checked={paymentMethod === "3" ? true : false}
                            value="3"
                          />
                          <label className={classes.customLabel} htmlFor="3">
                            Pay through a Sponsor
                          </label>
                        </div>
                      )}
                      {(eventID || payLater) && (
                        <div className={classes.customRadio}>
                          <input
                            type="radio"
                            id="4"
                            name="customRadio"
                            className="custom-control-input"
                            onChange={radioHandler}
                            checked={paymentMethod === "4" ? true : false}
                            value="4"
                          />
                          <label className={classes.customLabel} htmlFor="3">
                            Pay Later
                          </label>
                        </div>
                      )}
                      <PaymentMethods
                        paymentMethod={paymentMethod}
                        payDetails={payDetails}
                        payloads={payloads}
                        cancelPayment={cancelPayment}
                        finishPayment={finishPayment}
                        fullName={fullName}
                        email={email}
                        companyName={companyName}
                        vatNumber={vatNumber}
                        addressLine1={addressLine1}
                        addressLine2={addressLine2}
                        suburb={suburb}
                        City={City}
                        postalCode={postalCode}
                        emailRegex={emailRegex}
                        emailError={emailError}
                        handleInputChange={handleInputChange}
                      />
                    </div>
                  )}
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure you want to cancel the application?
                      </DialogContentText>
                      <DialogActions>
                        <Button onClick={handleClose}>No</Button>
                        <Button onClick={cancelClick} autoFocus>
                          Yes
                        </Button>
                      </DialogActions>
                    </DialogContent>
                  </Dialog>
                </div>
              </WithLoader>
            </div>
          ) : (
            <div>
              <h1>Make Payment</h1>
              <p>
                We couldnt fetch the payment details. Please contact
                Administrator for more details.
              </p>
            </div>
          )}
        </StartPage>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default CreateOrder(Prepayment);
