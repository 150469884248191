export const getemaillistPayload = (contactid) => {
  var payload;
  payload = {properties: [
    "ctpt_emailaddress",
    "ctpt_emailtype",
    "ctpt_mapemailto",
    "ctpt_methodtype",
    "ctpt_channelid"
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: true,
    filterfields: [
      {
        "field": "ctpt_methodtype",
        "searchvalue": "727530001",
        "type": "guid"
      },
      {
        field: '_ctpt_customerid_value',
        searchvalue: contactid,
        type: 'guid'
      }
    ],
  },
  recordtype: "ctpt_channels",
  idfield: "ctpt_channelid",
  continuationToken: "",
  pazesize: "10",
};
return payload
}

export const getEmailPayload = {
  properties: [
    "ctpt_channelid",
    "ctpt_emailaddress",
  ],
  formattedFields: [],
  recordType: "ctpt_channels",
  // id: contactid,

  FieldAndOperator: "true",
  idfield: "ctpt_channelid"

};

export const emailDetailsPayload = (JsonData) => {
  const details = JsonData.data;
  var payload;
  payload = {
    id: JsonData.recordId,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "ctpt_emailaddress",
        value: details[0].value,
        type: "string",
      },
    ],
  };
  return payload;
};