export const getqualificationlistPayload = (studentid) => {
  var payload;
  payload = {
    properties: [
      "mshied_name",
      "ctpt_Country",
      "_mshied_degreeid__value",
      "ctpt_otherdegreeid_",
      "ctpt_Discipline",
      "ctpt_otherdiscipline",
      // "_ctpt_educationtypeid_value",
      "ctpt_enddate",
      "mshied_gpa",
      "mshied_graduationdate",
      "_ctpt_institutionname_value",
      "ctpt_otherinstitutionname",
      "mshied_previouseducationid",
      "ctpt_yearofqualification",
    ],
    formattedFields: [
      "_mshied_degreeid__value",
      "_ctpt_discipline_value",
      "_ctpt_institutionname_value",
      "mshied_graduationdate",
    ],

    filter: {
      globalfilter: "",
      globalAndOperator: true,
      fieldAndOperator: true,
      filterfields: [
        {
          field: "_mshied_studentid_value",
          searchvalue: studentid,
          type: "guid",
        },
        { field: "statecode", searchvalue: 0 },
      ],
    },
    recordtype: "mshied_previouseducations",
    idfield: "mshied_previouseducationid",
    continuationToken: "",
    pagesize: "10",
  };
  return payload;
};

export const getQualificationDetailsPayload = {
  properties: [
    "mshied_name",
    "mshied_StudentId",
    "_ctpt_country_value",
    "_mshied_degreeid__value",
    "ctpt_otherdegreeid_",
    "_ctpt_discipline_value",
    "ctpt_otherdiscipline",
    "ctpt_enddate",
    "mshied_gpa",
    "mshied_graduationdate",
    "_ctpt_institutionname_value",
    "ctpt_otherinstitutionname",
    "mshied_previouseducationid",
    "ctpt_yearofqualification",
  ],
  // formattedFields: ["_mshied_degreeid__value",
  //   "_ctpt_discipline_value","_ctpt_institutionname_value"],
  recordType: "mshied_previouseducations",
  // id: contactid,

  FieldAndOperator: "true",
  idfield: "contactid",
};

export const qualificationDetailsPayload = (JsonData) => {
  const details = JsonData.data;
  var payload;
  console.log("rohan", { JsonData });
  var data = [
    {
      name: "mshied_name",
      value: JsonData.contactid || "",
      type: "string",
    },
    {
      name: "ctpt_Country",
      value: details[3].value || "",
      type: "lookup",
      relatedentity: "ctpt_countries",
    },
    {
      name: "mshied_DegreeId_",
      value: details[0].value._mshied_degreeid__value || "",
      type: "lookup",
      relatedentity: "mshied_programlevels",
    },

    {
      name: "ctpt_otherdegreeid_",
      value:
        details[0].value._mshied_degreeid__value ==
        "fe67ad1e-b5fb-ec11-82e5-00224881f7ec"
          ? details[0].value.ctpt_otherdegreeid_
          : "",
      type: "string",
    },
    {
      name: "ctpt_Discipline",
      value: details[1].value._ctpt_discipline_value || "",
      type: "lookup",
      relatedentity: "ctpt_disciplines",
    },

    {
      name: "ctpt_otherdiscipline",
      value:
        details[1].value._ctpt_discipline_value ==
        "5db0d663-bafb-ec11-82e5-00224881f7ec"
          ? details[1].value.ctpt_otherdiscipline
          : "",
      type: "string",
    },
    // {
    //   name: "ctpt_EducationTypeId",
    //   value: details[4].value,
    //   type: "lookup",
    //   relatedentity: "ctpt_categories"
    // },
    {
      name: "ctpt_InstitutionName",
      value: details[2].value._ctpt_institutionname_value || "",
      type: "lookup",
      relatedentity: "ctpt_institutionnames",
    },
    {
      name: "ctpt_otherinstitutionname",
      value:
        details[2].value._ctpt_institutionname_value ==
        "e47b482f-a7fb-ec11-82e5-00224881f1d4"
          ? details[2].value.ctpt_otherinstitutionname
          : "",
      type: "string",
    },
  ];
  if (details[4] && details[4].value != undefined && details[4].value != "") {
    data.push({
      name: "mshied_graduationdate",
      value: details[4].value
        ? new Date(details[4].value).toISOString().substring(0, 10)
        : "",
      type: "string",
    });
  }
  if (details[5] && details[5].value != undefined && details[5].value != "") {
    data.push({
      name: "ctpt_yearofqualification",
      value: details[5].value ? new Date(details[5].value).toISOString().substring(0, 4) : "",
      type: "string",
    });
  }
  // if (details[5] && details[5].value == "") {
  //   data.push({
  //     name: "mshied_graduationdate",
  //     value: new Date("01/02/1900").toISOString().substring(0, 10),
  //     type: "string",
  //   });
  // }
  // if (details[6] && details[6].value != undefined && details[6].value != "") {
  //   data.push({
  //     name: "ctpt_enddate",
  //     value: details[6].value
  //       ? new Date(details[6].value).toISOString().substring(0, 10)
  //       : "",
  //     type: "string",
  //   });
  // }
  payload = {
    id: JsonData.recordId,
    recordtype: JsonData.recordtype,
    data: data,
  };
  return payload;
};
