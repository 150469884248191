import React from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import Cluster from "layout/Cluster";
import TileCard from "app/components/molecules/tileCard/TileCard.mui";
import { upgradeTilesData } from "./model/tilData";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { brandPalette } from "brand/theme/brandTheme";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 3rem 4rem 4rem 4rem;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function Upgrade() {
  const [open, setOpen] = React.useState(false);
  const [urlText, seturlText] = React.useState("");
  const userDetails = useSelector((state) => state.contactDet);
  const handleOk = (params) => {
    setOpen(false);
    window.location.href = urlText;
  };
  const contactDet = useSelector((state) => state.contactDet);
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (val) => {
    setOpen(val.showModel);
    seturlText(val.url);
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <Grid container spacing={0}>
          {/* NOTE:layout */}
          {/* <Grid item xs={12} md={2}></Grid> */}
          <Grid item xs={12} md={12}>
            <StartPage>
              <h1>Membership Upgrade Application</h1>
              {userDetails && userDetails.membershipType && !userDetails.membershipType.includes('Corporate') ?
              <div>
              <p style={{ marginTop: "2rem" }}>
                SAICE has a provision for membership upgrades to allow its
                members to upgrade to other categories and they are as follows :
              </p>
              <ul>
              {userDetails && userDetails.membershipType && userDetails.membershipType.includes('Student') && <li>Student Member to Associate Member</li>}
              {userDetails && userDetails.membershipType && userDetails.membershipType.includes('Associate') && <li>Associate Member To Corporate Member</li>}
              </ul>

              <div style={{ marginLeft: "-2rem" }}>
                <Cluster
                  justify="start"
                  gap="large"
                  padding={"large"}
                  maxWidth={"max-content"}
                >
                  {upgradeTilesData.filter((item) => { return userDetails.membershipType.includes(item.allowmemberType) }).map(
                    ({ title, linkText, linkURI, allowmemberType }) => (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          maxWidth: "22rem",
                          justifyContent: "flex-start",
                        }}
                      >
                        <TileCard
                          title={title}
                          linkURL={linkURI}
                          linkText={linkText}
                          items={[]}
                          image={
                            <img
                              src={`/brand/leaf-optimized.jpg`}
                              alt="member"
                            />
                          }
                          //variant={"PROFILE"}
                          colour={brandPalette["Primary"][100]}
                          hasDialogue={true}
                          handleChange={handleChange}
                          description={
                            "Apply to upgrade your membership now. Please ensure all your profile information is up to date before you start your application."
                          }
                        />
                      </div>
                    )
                  )}
                </Cluster>
              </div>
              </div>:
              <p style={{ marginTop: "2rem" }}>No upgrades are available for your member type.</p>}
            </StartPage>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <div> Ensure to update your profile details</div>
                  <div>
                    <a href="/profile">Edit Profile</a>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleOk}>Ok</Button>
                <Button onClick={handleClose}> Cancel</Button>
              </DialogActions>
            </Dialog>
          </Grid>
          {/* <Grid item xs={12} md={2}></Grid> */}
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default Upgrade;
