import CustomTextBox from "app/components/atoms/customTextBox";
import CustomSelect from "app/components/atoms/customSelect";
import CustomDate from "app/components/atoms/customDate";
import Upload from "app/components/atoms/upload";

export const getEmploymentHistoryUiSchema = {
  "ui:title": "",
  // _ctpt_employeeid_value: {
  //   "ui:widget": props => {
  //     return <CustomTextBox getProps={props} />;
  //   }
  // },
  ctpt_company: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_jobtitle: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_startdate: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  ctpt_enddate: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  ctpt_typeofemployment: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
};

export const viewEmploymentHistoryDetailsUiSchema = {
  "ui:title": "",
  // _ctpt_employeeid_value: {
  //   "ui:widget": props => {
  //     return <CustomTextBox getProps={props} />;
  //   }
  // },
  ctpt_company: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  // ctpt_jobdescription: {
  //   "ui:widget": (props) => {
  //     return <CustomTextBox getProps={props} />;
  //   },
  // },
  ctpt_jobtitle: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_startdate: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  ctpt_enddate: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  ctpt_typeofemployment: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
};
