const {
  isValidSouthAfricanId,
} = require("../../../../../utilities/validation");
const southAfricanIdDropdownValue = "100000001";

const validateSouthAfricanId = (formData, errors) => {
  const idType = formData["ctpt_type"];
  const idNumber = formData["ctpt_identitynumber"];

  // If the south african id is not selected don't apply validation
  if (idType !== southAfricanIdDropdownValue) return errors;

  const error = isValidSouthAfricanId(idNumber);
  if (error) {
    errors["ctpt_identitynumber"].addError(error);
  }

  return errors;
};

export const customValidate = (formData, errors) => {
  return validateSouthAfricanId(formData, errors);
};
