import CustomTextBox from "app/components/atoms/customTextBox";
import CustomSelect from "app/components/atoms/customSelect";
import CustomDate from "app/components/atoms/customDate";
import Upload from "app/components/atoms/upload";

export const getQualificationUiSchema = {
  "ui:title": "",
  ctpt_country: {
    "ui:widget": props => {
      return <CustomSelect getProps={props} />;
    }
  },
  degreeConditional: {
    mshied_DegreeId_: {
      "ui:widget": props => {
        return <CustomSelect getProps={props} />;
      }
    },
    ctpt_otherdegreeid_: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },

  },
  disciplineConditional: {
    ctpt_Discipline: {
      "ui:widget": props => {
        return <CustomSelect getProps={props} />;
      }
    },
    ctpt_otherdiscipline: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },

  },
  institutionConditional: {
    ctpt_InstitutionName: {
      "ui:widget": props => {
        return <CustomSelect getProps={props} />;
      }
    },
    ctpt_otherinstitutionname: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },

  mshied_graduationdate: {
    "ui:widget": props => {
      return <CustomDate getProps={props} />;
    }
  },
  mshied_name: {
    "ui:widget": props => {
      return <CustomTextBox getProps={props} />;
    }
  },
  ctpt_enddate: {
    "ui:widget": props => {
      return <CustomDate getProps={props} />;
    }
  },
  mshied_gpa: {
    "ui:widget": props => {
      return <CustomTextBox getProps={props} />;
    }
  },
  ctpt_yearofqualification: {
    "ui:widget": props => {
      return <CustomDate getProps={props} />;
    }
  },
};

export const viewQualificationDetailsUiSchema = {
  "ui:title": "",
  _ctpt_country_value: {
    "ui:widget": props => {
      return <CustomSelect getProps={props} />;
    }
  },
  degreeConditional: {
    _mshied_degreeid__value: {
      "ui:widget": props => {
        return <CustomSelect getProps={props} />;
      }
    },
    ctpt_otherdegreeid_: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },

  },
  disciplineConditional: {
    _ctpt_discipline_value: {
      "ui:widget": props => {
        return <CustomSelect getProps={props} />;
      }
    },
    ctpt_otherdiscipline: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },

  },
  institutionConditional: {
    _ctpt_institutionname_value: {
      "ui:widget": props => {
        return <CustomSelect getProps={props} />;
      }
    },
    ctpt_otherinstitutionname: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },

  _ctpt_educationtypeid_value: {
    "ui:widget": props => {
      return <CustomTextBox getProps={props} />;
    }
  },

  mshied_graduationdate: {
    "ui:widget": props => {
      return <CustomDate getProps={props} />;
    }
  },
  mshied_name: {
    "ui:widget": props => {
      return <CustomTextBox getProps={props} />;
    }
  },
  ctpt_enddate: {
    "ui:widget": props => {
      return <CustomDate getProps={props} />;
    }
  },
  mshied_gpa: {
    "ui:widget": props => {
      return <CustomTextBox getProps={props} />;
    }
  },
  ctpt_yearofqualification: {
    "ui:widget": props => {
      return <CustomDate getProps={props} />;
    }
  },
};
