import React from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import styled from "styled-components";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style";
import { Button, Box } from "@material-ui/core";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 300px;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function PaymentSuccessStatic() {
  const classes = useStyles();
  let history = useHistory();

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <StartPage>
         
          <h1 className={classes.headingTxt}>Thank you for your Application</h1>
          <div className={classes.notes}>
            
            <p>
            Your application has now been submitted to our membership team for review. You will be notified by email once it is approved or a member of our team will contact you if required. If you have any queries in relation to your application, please contact us on <a href="mailto:membership@saice.org.za">membership@saice.org.za</a>


            </p>
           
          </div>
          <Box sx={{ p: 2 }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push("/")}
            >
              {" "}
              Return to Membership Homepage
            </Button>
          </Box>
        </StartPage>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default PaymentSuccessStatic;
