export const invoiceData = {
  header: {
    addressHeader:
      "The South African Institution of Civil Engineering NPO Reg No.: 211-689NPO",
    address:
      "Block 19, Thornhill Office Park, Bekker Street, Vorna Valley, X21, Midrand",
    postal: "Private Bag X200",
    phone: "(011) 805-5947/48",
    fax: "(011) 805-5971",
    email: "accounts@saice.org.za",
    vat: "4900110661",
  },
  heading: "Proforma Invoice",
  memberNumber: 16193,
  memberCategory: "NON-AUDIT ACCOUNTING TECHNICIAN",
  invoiceNumber: "75,473",
  invoiceDate: "31-OCT-2020",
  dueDate: "31-DEC-2020",
  fromName: "FINLIGHT(PTY) LTD",
  fromAddress: "P O BOX 41211, Gaborne",
  invoiceTable: [
    {
      code: "NADTMFSB20",
      description: "Annual Subscriptions 2021- Non Aduit Accounting ",
      unit: "2,930.36",
      quantity: "1.00",
      vat: "351.64",
      amount: "3,282.00",
    },
    {
      code: "NADTMFQA20",
      description: "Quality Assurance Review- Non-Audit Firm ",
      unit: "930.36",
      quantity: "1.00",
      vat: "111.64",
      amount: "1,042.00",
    },
  ],
  invoideTotal: {
    total: "3,860.72",
    valueTax: "463.29",
    invoideTotal: "4,324.01",
  },
  footer: {
    disclaimer1: "The Annual Subscription Fees are due and payable on the",
    disclaimer2:
      "Please note that your name is liable to be struck off the Register of Members if payment of subscription is not received\n",
    dueDate: "--",
    address:
      "BICA Banking Details:- STANBIC BANK, Account Number: 9060000684207 - Account Name: BICA Main - Bank: Stanbic Bank - Swift Code:SBICBWGX - Branch: Fairground, Branch Code:064967\n",
    note: "*Please quote your Membership Number as payment reference when paying by EFT or Cash deposit",
    settlementDiscountNote: "There are three options to consider for payment of this invoice:\n 1. Early Bird - Paid by 31st Dec 2023 and get a 20% settlement discount.\n 2. Full Fee - Paid between 2nd Jan 2024 and 31st Dec 2024.\n Click on the link below for card payment facilities. \n http://members.saice.org.za/membershippayment/makepayment"
  },
  fileName: "proformainvoice",
};

export const invoiceNewdata = {
  invoiceDate: {
    year: 2021,

    month: 11,

    day: 24,
  },

  dueDate: {
    year: 1,

    month: 1,

    day: 1,
  },

  invoiceNumber: "ORD-01008-P1Y1R4",

  invoiceTotalTax: 12.0,

  invoiceTotalDiscount: 2.0,

  invoiceTotalAmmount: 133.0,

  currencyCode: "GBP",

  currencySymbol: "£",

  products: [
    {
      code: null,

      description: null,

      unit: 0.0,

      quantity: 1.0,

      vat: 12.0,

      ammount: 0.0,
    },
    {
      code: null,

      description: null,

      unit: 0.0,

      quantity: 1.0,

      vat: 12.0,

      ammount: 120.0,
    },
  ],

  invoiceTotal: {
    total: 0.0,

    valueTax: 12.0,

    invoideTotal: 0.0,
  },
};
