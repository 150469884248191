import React from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FooterComponent from "app/components/molecules/footer/FooterComponent.mui";
import { globalTheme } from "style/globalTheme";
import { appData } from "data/appData";

const drawerWidth = `${appData[0].utilities.sidenavDrawerwidth.value}${appData[0].utilities.sidenavDrawerwidth.unit}`;
const drawerWidthPlusOne = `${
  appData[0].utilities.sidenavDrawerwidth.value + 1
}${appData[0].utilities.sidenavDrawerwidth.unit}`;

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "100vh",
    background: "whitesmoke",
    position: "relative",
    zIndex: "1200",
  },
  stickyHeader: {
    position: "fixed",
    width: "100vw",
    minHeight: "5rem",
    background: "white",
    opacity: "0.8",
  },
  pillarBox: {
    position: "relative",
    padding: 0,
    //display: "flex",
    maxWidth: "1280px",
    backgroundColor: "white",
    // [globalTheme.breakpoints.up("sm")]: {
    //   background: `linear-gradient(90deg, ${globalTheme.palette.secondaryTint[50]} 0%, ${globalTheme.palette.secondaryTint[50]} ${drawerWidth} , gray ${drawerWidthPlusOne} , white ${drawerWidthPlusOne} , white 100%)`,
    // },
    minHeight: "100vh",
  },
}));

function PageLayout(props) {
  const classes = useStyles();
  const { children } = props;
  return (
    <>
      <div className={classes.root}>
        <div className={classes.stickyHeader}>&nbsp;</div>
        <Container className={classes.pillarBox} maxWidth={false}>
          {children}
        </Container>
      </div>
      <FooterComponent />
    </>
  );
}

export default PageLayout;
