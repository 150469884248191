import CustomTextBox from "app/components/atoms/customTextBox";
import Upload from "app/components/atoms/upload";
import CustomDate from "app/components/atoms/customDate";

const componentType = {
  upload: {
    "ui:widget": (props) => {
      console.log("PROPS:", props);
      return <div />;
    },
  },
};
export const getStudentUiSchema = {
  "ui:title": "",
  ctpt_name: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_descriptionofdevelopmentlearningactivity: {
    "ui:widget": (props) => {
      return (
        <CustomTextBox
          getProps={{
            ...props,
            schema: { ...props.schema, ismultiline: !!props.value },
          }}
        />
      );
    },
  },
  ctpt_whydidyouchoosethisactivityandhowisitre: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_whendidthisactivitytakeplace: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  ctpt_howmanyunitsareyourclaimingforthisactivit: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_howmanyoftheseunitscouldyouverifywithsup: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_whatdidyoulearnandhowdidwillyouapplyit: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_documenturl: componentType.upload,
};

export const getDocumentUiSchema = {
  "ui:title": "",
  ctpt_name: {
    "ui:widget": "hidden",
  },
  ctpt_documenttype: {
    "ui:widget": "hidden",
  },
  ctpt_case: {
    "ui:widget": "hidden",
  },
  ctpt_documenturl: {
    "ui:widget": "hidden",
  },
};
