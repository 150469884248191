export const routerMappingOG = [
  {
    key: "Page 1.0",
    route: "/",
    page: "Member Portal | Home Page | Access",
    component: "home",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 1.1",
    route: "/notifications",
    page: "Member Portal | Notifications Page | Access",
    component: "Notifications",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 2.0",
    route: "/applications",
    page: "Member Portal | Application Page | Access",
    component: "Applications",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 2.2",
    route: "/application/studentApplication",
    page: "Member Portal | Student Membership Application Page | Access",
    component: "StudentApplication",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 2.3",
    route: "/application/associateMemberApplication",
    page: "Member Portal | Associate Membership Application Page | Access",
    component: "Associate",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 2.4",
    route: "/application/corporateMemberApplication",
    page: "Member Portal | Corporate Membership Application Page | Access",
    component: "Corporate",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },

  {
    key: "Page 3.1",
    route: "/members/upgrade",
    page: "Member Portal | Upgrade Membership Page | Access",
    component: "Upgrade",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 3.1.1",
    route: "/application/upgradeStudentApplication",
    page: "Member Portal | Upgrade Student To Associate Application Page | Access",
    component: "UpgradeStudent",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 3.1.2",
    route: "/application/upgradeAssociateApplication",
    page: "Member Portal | Upgrade Associate To Corporate Membership Page | Access",
    component: "UpgradeFellow",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 3.2",
    route: "/application/duplicateCertificate",
    page: "Member Portal | Request Member Certificate Page | Access",
    component: "DuplicateCertificate",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 3.3",
    route: "/membership/resignationApplication",
    page: "Member Portal | Request Member Certificate Page | Access",
    component: "Resignation",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 3.4",
    route: "/membership/feeExemption",
    page: "Member Portal | Fee Exemption Application Page | Access",
    component: "FeeExemption",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 3.5",
    route: "/membership/feeReduction",
    page: "Member Portal | Fee Reduction Application Page | Access",
    component: "FeeReduction",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 3.6",
    route: "/membership/retirement",
    page: "Member Portal | Retirement Page | Access",
    component: "Retirement",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 4.0",
    route: "/invoices",
    page: "Member Portal | Invoices Summary Page | Access",
    component: "Invoices",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 5.0",
    route: "/queries",
    page: "Member Portal | All Queries Page | Access",
    component: "StudentService",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 5.1",
    route: "/queries/viewrequest",
    page: "Member Portal | Query Details Page | Access",
    component: "viewrequest",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.0",
    route: "/event/registration",
    page: "Member Portal | Event Registration Page | Access",
    component: "Events",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.1",
    route: "/event/details",
    page: "Member Portal | Event Registration Details Page | Access",
    component: "EventDetails",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.0",
    route: "/profile",
    page: "Member Portal | Profile Page | Access",
    component: "Profile",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.1",
    route: "/profile/aboutyou",
    page: "Member Portal | About You Page | Access",
    component: "AboutYou",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.2",
    route: "/profile/nationalities",
    page: "Member Portal | Nationality Page | Access",
    component: "Nationalities",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.3",
    route: "/profile/workaddress",
    page: "Member Portal | Work Address Page | Access",
    component: "WorkAddress",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.4",
    route: "/profile/personaladdress",
    page: "Member Portal | Personal Address Page | Access",
    component: "PersonalAddress",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.5",
    route: "/profile/postaladdress",
    page: "Member Portal | Postal Address Page | Access",
    component: "PostalAddress",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.6",
    route: "/preferences",
    page: "Member Portal | Preferences Page | Access",
    component: "Preferences",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.7",
    route: "/profile/upload-cv",
    page: "Member Portal | Upload CV Page | Access",
    component: "UploadCV",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.8",
    route: "/profile/update-email",
    page: "Member Portal | Update Email Page | Access",
    component: "EmailUpdate",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.9",
    route: "/profile/qualifications",
    page: "Member Portal | Qualification Page | Access",
    component: "Qualifications",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.10",
    route: "/profile/viewqualificationdetails",
    page: "Member Portal | Qualification Details Page | Access",
    component: "ViewQualificationDetails",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.11",
    route: "/profile/employmenthistory",
    page: "Member Portal | Employment History Page | Access",
    component: "EmploymentHistory",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.12",
    route: "/profile/viewemploymenthistorydetails",
    page: "Member Portal | Employment History Detail Page | Access",
    component: "ViewEmploymentHistoryDetails",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.13",
    route: "/profile/viewemaildetails",
    page: "Member Portal | Update Email Details Page | Access",
    component: "ViewEmailDetails",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.14",
    route: "/profile/update-phone",
    page: "Member Portal | Update Phone Page | Access",
    component: "PhoneUpdate",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.15",
    route: "/profile/viewphonedetails",
    page: "Member Portal | Update Phone Details Page | Access",
    component: "ViewPhoneDetails",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.16",
    route: "/profile/professionalorganisation",
    page: "Member Portal | Update Professional Organisation Page | Access",
    component: "ProfessionalOrganisation",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.17",
    route: "/profile/viewprofessionalorganisationdetails",
    page: "Member Portal | Update Professional Organisation Details Page | Access",
    component: "ViewProfessionalOrganisationDetails",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.18",
    route: "/profile/nextofkin",
    page: "Member Portal | Update Next Of Kin Page | Access",
    component: "NextOfKin",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.19",
    route: "/profile/membershipstatus",
    page: "Member Portal | Membership Status Page | Access",
    component: "Membershipstatus",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.20",
    route: "/profile/identity",
    page: "Member Portal | Identity Page | Access",
    component: "Identity",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.21",
    route: "/profile/divisions",
    page: "Member Portal | Divisions Page | Access",
    component: "Divisions",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.22",
    route: "/profile/branch",
    page: "Member Portal | Branch Page | Access",
    component: "Branch",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 8.0",
    route: "/application/prepayment",
    page: "Member Portal | Pre Payment Page | Access",
    component: "Prepayment",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 8.1",
    route: "/application/prepayment/payment-success",
    page: "Member Portal | Pre Payment Success Page | Access",
    component: "PaymentSuccessStatic",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 8.2",
    route: "/application/prepayment/sucess",
    page: "Member Portal | Payment Success Page  | Access",
    component: "Paymentsucess",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 8.5",
    route: "/application/success",
    page: "Member Portal | Payment Success Page 2 | Access",
    component: "PaymentSuccessStatic",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 8.3",
    route: "/application/prepayment/failure",
    page: "Member Portal | Payment Failure Page | Access",
    component: "Paymentfailure",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 8.4",
    route: "/application/prepayment/pay-later",
    page: "Member Portal | Payment Later Page | Access",
    component: "PayLater",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },

  ///////////////
  // {
  //   key: "Page 3.0",
  //   route: "/invoices",
  //   page: "Member Portal | Membership Page | Access",
  //   component: "Invoices",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 3.1",
  //   route: "/members/upgrade",
  //   page: "Member Portal | Upgrade Membership Page | Access",
  //   component: "Upgrade",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 3.1.1",
  //   route: "/application/upgradeTechnician",
  //   page: "Member Portal | Upgrade Membership Page AT To A | Access",
  //   component: "UpgradeTechnician",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 3.1.2",
  //   route: "/application/upgradeAssociate",
  //   page: "Member Portal | Upgrade Membership Page A To F | Access",
  //   component: "UpgradeAssociate",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 3.1.3",
  //   route: "application/upgradeFellow",
  //   page: "Member Portal | Upgrade Membership Page AT To F | Access",
  //   component: "UpgradeFellow",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 3.2",
  //   route: "/application/duplicateCertificate",
  //   page: "Member Portal | Duplicate Membership Page | Access",
  //   component: "DuplicateCertificate",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 3.3",
  //   route: "/members/letter",
  //   page: "Member Portal | Letter Of Good Standing | Access",
  //   component: "Letterofgood",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 3.4",
  //   route: "/application/clearanceCertificate",
  //   page: "Member Portal | Clearance Certificate Page | Access",
  //   component: "ClearanceApplication",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 3.5",
  //   route: "/membership/service-requests",
  //   page: "Member Portal | Service Request Page | Access",
  //   component: "StudentService",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 3.5.1",
  //   route: "/members/viewrequest",
  //   page: "Member Portal | Service Request Details Page | Access",
  //   component: "StudentService",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 4.0",
  //   route: "/invoices",
  //   page: "Member Portal | Invoices Summary Page | Access",
  //   component: "Invoices",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 5.1",
  //   route: "/exam/register",
  //   page: "Member Portal |  Exam Registration Page | Access",
  //   component: "ExamRegistration",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 5.1.1",
  //   route: "/exam-detail",
  //   page: "Member Portal | Exam Details Page | Access",
  //   component: "ViewEvent",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 5.2",
  //   route: "/exam/reports",
  //   page: "Member Portal | Exam Reports Page | Access",
  //   component: "ExamResult",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 6.0",
  //   route: "/profile",
  //   page: "Member Portal | Profile Page | Access",
  //   component: "profile",
  //   siteNav: "",
  //   RouteType: "PublicRoute",
  //   permissionsets: [],
  // },

  // {
  //   key: "Page 7.1",
  //   route: "/event/registration",
  //   page: "Member Portal | Event Registration Page | Access",
  //   component: "Events",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 7.1.1",
  //   route: "/event/details",
  //   page: "Member Portal | Event Details Page | Access",
  //   component: "EventDetails",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 8.1",
  //   route: "/cpd/declaration",
  //   page: "Member Portal | CPD Declaration Page | Access",
  //   component: "MemberDeclarations",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 8.1.1",
  //   route: "/exam/evidence",
  //   page: "Member Portal | CPD Uploads Page | Access",
  //   component: "EvidenceDetails",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 8.2",
  //   route: "/membership/student-training-agreement",
  //   page: "Member Portal | CPD Records Page | Access",
  //   component: "ContractualRecords",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 8.2.1",
  //   route: "/cpd/viewrecord",
  //   page: "Member Portal | CPD Record Details Page | Access",
  //   component: "ViewRecord",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 8.3",
  //   route: "/add-evidence",
  //   page: "Member Portal | CPD Add Evidence Page | Access",
  //   component: "AddEvidence",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },

  // {
  //   key: "Page 9.4",
  //   route: "/student-placement",
  //   page: "Member Portal | Student Placement Page | Access",
  //   component: "StudentPlacement",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
  // {
  //   key: "Page 9.5",
  //   route: "/placement-details",
  //   page: "Member Portal | Student Placement Details Page | Access",
  //   component: "PlacementDetails",
  //   siteNav: "",
  //   RouteType: "PrivateRoute",
  //   permissionsets: [],
  // },
];
