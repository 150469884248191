export const PAYMENT_STATUS_MAP = {
    "900001": "Call for Approval",
    "900002": "Card Expired",
    "900003": "InsufficientFunds",
    "900004": "Invalid Card Number",
    "900005": "Bank Interface Timeout Indicates a communications failure between the banks systems.",
    "900006": "Invalid Card",
    "900007": "Declined Funds",	
    "900009": "Lost Card",
    "900010": "Invalid Card Length",	
    "900011": "Suspected Fraud",	
    "900012": "Card Reported as Stolen",	
    "900013": "Restricted Card",	
    "900014": "Excessive Card Usage",	
    "900015": "Card Blacklisted",	
    "900207": "Declined; authentication failed	Indicates the cardholder did not enter their MasterCard SecureCode / Verified by Visa password correctly.",
    "990020": "Auth Declined",	
    "900210": "3D Secure Lookup Timeout",	
    "991001": "Invalid expiry date",	
    "991002": "Invalid Amount",
    "990022": "UnprocessedFunds",
    "990017": "Success",
    "990028": "Transaction cancelled",
    "900205": "Unexpected authentication result (phase 1)",	
    "900206": "Unexpected authentication result (phase 2)",	
    "990001": "Could not insert into Database",	
    "990022": "Bank not available",	
    "990053": "Error processing transaction"
};