import { appMenuItems } from "app/components/molecules/navbar/model/commonMenuData";

export const MEMBERSHIP_TYPE_MAP = {
  1: "Corporate Member",
  2: "Associate Member",
  3: "Student",
  4: "Fellow",
  5: "Participant",
  6: "Honorary Fellow",
};

export const getHomeTilesData = (serviceRequests) => [
  {
    title: "Your applications",
    items: appMenuItems[0].items,
  },
  {
    title: "Membership",
    items: appMenuItems[1].items,
  },
  {
    title: "Invoice & Payments",
    items: appMenuItems[2].items,
  },
  {
    title: "Service Requests",
    items: [...appMenuItems[3].items, ...serviceRequests],
  },
  // {
  //   title: "CPD",
  //   items: appMenuItems[4].items,
  // },
];

export const CARDS_DATA = [
  // {
  //   id: "1",
  //   title: "SAICE Academy",
  //   description:
  //     "Grow your professional development by viewing and booking the latest SAICE courses on offer",
  //   backgroundColor: "#1b9dd9",
  //   backgroundImageURL: "/brand/ellipse-light-blue.png",
  //   textColor: "white",
  //   linkUrl: "https://store.saice.org.za/courses",
  //   buttonText: "BROWSE CATALOGUE",
  // },
  // {
  //   id: "2",
  //   title: "Membership Subscription Fees",
  //   description: "Membership subscription and division fees",
  //   backgroundColor: "#BAA35F",
  //   textColor: "white",
  //   buttonText: "Know more",
  //   linkUrl: "https://saice.org.za/saice-membership-fees/",
  //   backgroundImageURL: "/brand/ellipse-gold.png",
  // },
  // {
  //   id: "3",
  //   title: "SAICE Membership Benefits",
  //   description:
  //     "Browse the range of opportunities and benefits of being a SAICE member.",
  //   backgroundColor: "#3C3C3C",
  //   textColor: "white",
  //   buttonText: "Find out more",
  //   linkUrl: "https://saice.org.za/membership/",
  //   backgroundImageURL: "/brand/ellipse-grey.png",
  // },
  // {
  //   id: "4",
  //   title: "SAICE Membership Application",
  //   description: "Apply to become a member of SAICE today!",
  //   backgroundColor: "#001C30",
  //   textColor: "white",
  //   buttonText: "Apply Now",
  //   linkUrl: "https://apply.saice.org.za/",
  //   backgroundImageURL: "/brand/ellipse-copy.png",
  // },
  // {
  //   id: "5",
  //   title: "SAICE Bookstore",
  //   description:
  //     " SAICE offers a platform for members to avail the book store facility",
  //   backgroundColor: "#BAA35F",
  //   textColor: "white",
  //   buttonText: "Know More",
  //   linkUrl: "https://store.saice.org.za/book-store",
  //   backgroundImageURL: "/brand/ellipse-gold.png",
  // },
  // {
  //   id: "6",
  //   title: "Mentor Application",
  //   description: "Apply to become a Mentor!",
  //   backgroundColor: "#001C30",
  //   textColor: "white",
  //   buttonText: "Apply Now",
  //   linkUrl: "https://saice.xenon.co.za/connect.php?type=3",
  //   backgroundImageURL: "/brand/ellipse-copy.png",
  // },
  // {
  //   id: "7",
  //   title: "Mentee Application",
  //   description: "Apply if you require guidance and mentorship",
  //   backgroundColor: "#1b9dd9",
  //   textColor: "white",
  //   buttonText: "Apply Now",
  //   linkUrl: "https://saice.xenon.co.za/connect.php?type=2",
  //   backgroundImageURL: "/brand/ellipse-copy.png",
  // },
  // {
  //   id: "8",
  //   title: "Employment Opportunity",
  //   description: "Apply if you are seeking employment",
  //   backgroundColor: "#BAA35F",
  //   textColor: "white",
  //   buttonText: "Apply Now",
  //   linkUrl: "https://saice.xenon.co.za/connect.php?type=1",
  //   backgroundImageURL: "/brand/ellipse-copy.png",
  // },
];
