export const updateDependentValues = (form, prevFormData) => {
  const formSchema = Object.values(form?.schema?.properties);
  const formData = JSON.parse(JSON.stringify(form.formData));

  formSchema.forEach((schema) => {
    if (schema.referValueFrom) {
      const newValue = formData?.hasOwnProperty(schema.referValueFrom)
        ? formData[schema.referValueFrom]
        : "";
      const oldValue = prevFormData?.hasOwnProperty(schema.referValueFrom)
        ? prevFormData[schema.referValueFrom]
        : "";

      if (newValue === oldValue) {
        return formData;
      }

      const dependentSchema = formSchema.find(
        (x) => x.schemaname === schema.referValueFrom
      );

      formData[schema.schemaname] = formData[dependentSchema.schemaname] || "";

      if (dependentSchema.enum) {
        const enumIndex = dependentSchema.enum.indexOf(
          formData[dependentSchema.schemaname]
        );
        if (enumIndex > -1)
          formData[schema.schemaname] =
            dependentSchema.enumNames[enumIndex] || "";
      }
    }
  });

  return formData;
};
