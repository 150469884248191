import React from "react";
import styled from "styled-components";
import { Button, Box, useMediaQuery } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { appData } from "data/appData";
import { globalTheme } from "style/globalTheme";
import { footerItems } from "app/components/molecules/navbar/model/sideNavData";
import Stack from "layout/Stack";
import FooterSections from "./components/footer-sections";
import FacebookIcon from "@material-ui/icons/Facebook"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import TwitterIcon from "@material-ui/icons/Twitter"
import YoutubeIcon from "@material-ui/icons/YouTube"
import InstagramIcon from "@material-ui/icons/Instagram"

const Ftr = styled(Stack)`
  max-width: 1200px;
  width: 100%;
  #tctext {
    display: flex;
    align-items: center;
    small {
      display: block;
      padding-right: 25px;
      font-size: 11px;
      color: rgba(255, 255, 255, 0.7);
    }
  }
`;

const FtrBox = styled.div`
  width: 100%;
  min-height: 20rem;
  color: white;
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background: #464c51;
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
    display: block;
    padding-bottom: 0.3rem;
    padding-left: 10px;
    color: rgba(255, 255, 255, 0.7);
  }
  a {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    &:hover,
    &:focus {
      color: ${globalTheme.palette.secondary.main};
    }
  }

  .copyRightText {
    padding-left: 1rem;
    font-size: 1rem;
    margin-bottom: 2rem;

    ${globalTheme.breakpoints.down("xs")} {
      padding-left: 1rem;
    }
  }
`;

export default function FooterComponent() {
  const matchesPrint = useMediaQuery("print");
  return (
    <ThemeProvider theme={globalTheme}>
      {!matchesPrint && (
        <FtrBox>
          <Ftr element="footer" gap="medium">
            <FooterSections sectionList={footerItems.verticalSections} />

            <Box
              display="flex"
              justifyContent="center"
              sx={{
                marginBottom: "-.8rem",
              }}
            >
              {getnavItems()}
            </Box>
            <Box display="flex" justifyContent="center" id="tctext">
              <small className="copyRightText">
                {appData[0].pageDetails.footer.copyRight}
              </small>
            </Box>
          </Ftr>
        </FtrBox>
      )}
    </ThemeProvider>
  );
}

const getIconBasedOnSocNet = iconUrl => {
  switch(iconUrl){
    case "Facebook": 
      return <FacebookIcon sx={{color: "white"}} fontSize="small" />
      break
    case "LinkedIn": 
      return <LinkedInIcon sx={{color: "white"}} fontSize="small" />
      break
    case "Twitter": 
      return <TwitterIcon sx={{color: "white"}} fontSize="small" />
      break
    case "Youtube": 
      return <YoutubeIcon sx={{color: "white"}} fontSize="small" />
      break
    case "Instagram": 
      return <InstagramIcon sx={{color: "white"}} fontSize="small" />
      break
  }
}

//Navigation list items
const getnavItems = () => {
  let list = footerItems.horizontalSection.items;
  return list.map(({ labelText, linkUrl, iconUrl }) => {
    return (
      <a
        {...{
          href: linkUrl,
          color: "inherit",
          style: { textDecoration: "none", fontSize: "10px" },
          key: labelText,
          target: "_blank",
        }}
      >
        <Button
          startIcon={getIconBasedOnSocNet(iconUrl)}
          color="inherit"
          style={{ fontSize: "15px", fontWeight: "bold" }}
        >
          {labelText}
        </Button>
      </a>
    );
  });
};
