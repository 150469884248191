import React, { useEffect, useState } from "react";
import { useStyles } from "../style";
import { config } from "utilities/config";
import axios from "axios";
import { Button } from "@material-ui/core";
import { msalConfig } from "constants/msalConfig";
function PayUsingDebitCard({ payDetails, payloads, email }) {
  const [htmlResponse, setHtmlResponse] = useState(null);
  const [payRequstId, setPayRequestId] = useState(null);
  const [checksum, setChecksum] = useState(null);
  const [reference, setReference] = useState(null);
  const classes = useStyles();
  const Authtoken = localStorage.getItem("token");

  const onPaymentBtnClick = () => {
    let data = {
      orderId: payloads?.orderId,
      authToken: Authtoken,
      returnUrl: config.payment.returnUrl,
      amount: payDetails?.invoiceTotal?.invoideTotal,
      currency: payDetails?.currencyCode,
      locale: "en-za",
      email: "rossan@cloudthing.com",
      orderNumber: payDetails?.invoiceNumber,
      country: "ZAF",
    };
    localStorage.setItem("amount", payDetails?.invoiceTotal?.invoideTotal);
    axios
      .post(config.payment.env + config.payment.payHandler, data)
      .then((res) => {
        localStorage.setItem("PAYGATE_ID", res.data.PAYGATE_ID);
        localStorage.setItem("PAY_REQUEST_ID", res.data.PAY_REQUEST_ID);
        localStorage.setItem("REFERENCE", res.data.REFERENCE);
        localStorage.setItem("CHECKSUM", res.data.CHECKSUM);
        setChecksum(res.data.CHECKSUM);
        setPayRequestId(res.data.PAY_REQUEST_ID);
        setReference(res.data.REFERENCE);
        let processData = {
          payRequestId: res.data.PAY_REQUEST_ID,
          reference: res.data.REFERENCE,
          authToken: Authtoken,
        };
        document.getElementById("processTransSyncForm").submit();
        // axios.post(config.payment.env + config.payment.processTransactionSync, processData)
        //   .then((resData) => {
        //     console.log("Res DATA from 2nd API ", resData)
        //     document.MyFrame.document.body.innerHTML = resData.data
        //   })
        //   .catch((err) => {
        //     console.log("Data fetch failed:", err);
        //   })
      })
      .catch((err) => {
        console.log("Data fetch failed:", err);
      })
      .finally(() => {
        //enableLoader(false);
      });
  };

  const createMarkup = () => {
    return { __html: htmlResponse };
  };

  return (
    <>
      <div className={classes.makePay}>
        {/* <form
          class={classes.makePayment}
          action={`${config.payment.env + config.payment.payHandler}`}
          method="post"
          id="paymentForm"
        > */}
        <input type="hidden" name="authToken" value={Authtoken} />
        <input
          type="hidden"
          name="amount"
          value={payDetails?.invoiceTotal?.invoideTotal}
        />
        <input type="hidden" name="orderId" value={payloads?.orderId} />
        <input type="hidden" name="description" value="description" />
        <input type="hidden" name="currency" value={payDetails?.currencyCode} />
        <input type="hidden" name="locale" value={"en-za"} />
        <input type="hidden" name="email" value={"rossan@cloudthing.com"} />
        <input
          type="hidden"
          name="orderNumber"
          value={payDetails?.invoiceNumber}
        />
        <input type="hidden" name="country" value={"ZAF"} />
        <Button
          disabled={!payDetails?.invoiceTotal}
          color="primary"
          variant="contained"
          onClick={onPaymentBtnClick}
          //type="submit"
        >
          Make Secure Payment Now
        </Button>
        {/* </form> */}
        <p className={classes.noteTxt}>
          Payment goes to a secure external site, you’ll be sent back to this
          site after payment
        </p>
      </div>
      {/* <form action={config.payment.env + config.payment.processTransactionSync} method="post" id="processTransSyncForm">
        <input type="hidden" name="payRequestId" value={payRequstId} />
        <input type="hidden" name="reference" value={reference} />
        <input type="hidden" name="authToken" value={Authtoken} />
      </form> */}
      <form
        action={config.payment.payGateProcessTransactionSync}
        method="post"
        id="processTransSyncForm"
      >
        <input type="hidden" name="PAY_REQUEST_ID" value={payRequstId} />
        <input type="hidden" name="CHECKSUM" value={checksum} />
      </form>
    </>
  );
}

export default PayUsingDebitCard;
