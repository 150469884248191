import React from "react";
import html2pdf from "html2pdf.js";
import { invoiceData } from "./model/invoicedata";

export default function Invoice(props) {
  const downloadPdf = () => {
    let orderNum = props.payDetails.invoiceNumber;
    const date = new Date();
    const formattedDate = date
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    let pdfFile = document.getElementById("renderInvoice").cloneNode(true);

    html2pdf()
      .set({
        filename: orderNum + "-" + formattedDate + ".pdf",
        margin: 10,
        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 192,
          letterRendering: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        image: { type: "jpeg", quality: 0.98 },
      })
      .from(pdfFile)
      .toPdf()
      .get("pdf")
      .then(function (pdfObject) {
        // get the number of pages in the pdf
        let totalPages = pdfObject.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          // We are telling our pdfObject that we are now working on this page and
          // setting font size of text and color of text
          pdfObject.setPage(i);
          pdfObject.setFontSize(10);
          pdfObject.setFont("Helvetica");
          pdfObject.setTextColor(100);
          var footerContent =
            invoiceData.footer.disclaimer1 +
            " " +
            "Jan " +
            new Date().getFullYear().toString() +
            " ." +
            invoiceData.footer.disclaimer2 +
            "\n" +
            invoiceData.footer.address +
            "\n" +
            invoiceData.footer.note +
            "\n" +
            invoiceData.footer.settlementDiscountNote;
          // showing page number on bottom of page
          var splitTitle = pdfObject.splitTextToSize(footerContent, 200);
          //pdfObject.text(105, 260, splitTitle, "center");
          pdfObject.internal.write(0, "Tw");
          pdfObject.text(
            pdfObject.internal.pageSize.getWidth() - 30,
            pdfObject.internal.pageSize.getHeight() - 4,
            "Page " + i + " of " + totalPages
          );
        }
      })
      .save();
  };
  React.useEffect(() => {
    props.downloadFunc.current = downloadPdf;
  }, []);

  return (
    <div>
      <div style={{ position: "absolute", overflow: "hidden", height: "0%" }}>
        <div
          id="renderInvoice"
          style={{ position: "relative", width: "100%", height: "100%" }}
        >
          <table width="100%" style={{ padding: "10px" }}>
            <tr>
              <td style={{ width: "6%", marginRight: "10px" }}>
                {" "}
                <img
                  src={"/brand/saice-pdf-logo.png"}
                  width="216"
                  height="145"
                  alt="logo"
                />
              </td>
              <td
                width="25%"
                style={{
                  fontSize: "11px",
                  textAlign: "left",
                }}
              >
                <div style={{ fontWeight: "bold" }}>
                  {invoiceData.header.addressHeader}
                </div>
                <div>{"Physical: " + invoiceData.header.address}</div>
                <div>{"Postal: " + invoiceData.header.postal}</div>
                <div>
                  Tel:&nbsp;{invoiceData.header.phone}, Fax:&nbsp;
                  {invoiceData.header.fax}
                </div>
                <div>e-mail:&nbsp;{invoiceData.header.email}</div>
                {/* <div>VAT NUMBER:&nbsp;{invoiceData.header.vat}</div> */}
              </td>
              <td
                width="20%"
                style={{
                  fontSize: "11px",
                  textAlign: "left",
                  paddingRight: "5px",
                }}
              >
                <div
                  style={{
                    border: "2px solid #000",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "20px",
                  }}
                >
                  {"Proforma Invoice"}
                </div>
                <div
                  style={{
                    border: "2px solid #000",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: "bold" }}>Number: </span>
                    {props.payDetails.invoiceNumber}
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Date: </span>
                    {new Date(
                      props.payDetails.invoiceDate
                    ).toLocaleDateString()}
                  </div>
                </div>
                <div style={{ fontWeight: "bold" }}>
                  {"VAT/BTW REG NO. " + invoiceData.header.vat}
                </div>
              </td>
            </tr>
          </table>
          <table width="100%" style={{ padding: "10px" }}>
            <tr>
              <td
                style={{
                  width: "50%",
                  textAlign: "left",
                  fontSize: "11px",
                  paddingRight: "5px",
                }}
              >
                <div style={{ border: "2px solid #000", textAlign: "center" }}>
                  INVOICE TO
                </div>
              </td>
              <td
                style={{
                  width: "50%",
                  textAlign: "left",
                  fontSize: "11px",
                  paddingLeft: "5px",
                }}
              >
                <div style={{ border: "2px solid #000", textAlign: "center" }}>
                  DELIVER TO
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%", paddingRight: "5px" }}>
                <div
                  style={{
                    border: "2px solid #000",
                    fontSize: "11px",
                    textAlign: "left",
                    paddingLeft: "20px",
                  }}
                >
                  {props.payDetails.title} {props.payDetails.firstName}
                  <br />
                  {props.payDetails.customerAddress}
                  <br />
                  <br />
                  {/* <span style={{ fontWeight: "bold"}}>Attn: </span>{props.payDetails.fromName} */}
                </div>
              </td>
              <td style={{ width: "50%", paddingLeft: "5px" }}>
                {props.payDetails.sponsorName &&
                props.payDetails.sponsorEmail &&
                props.payDetails.sponsorName.length > 0 &&
                props.payDetails.sponsorEmail.length > 0 ? (
                  <div
                    style={{
                      border: "2px solid #000",
                      height: "100%",
                      fontSize: "11px",
                      textAlign: "left",
                      paddingLeft: "20px",
                    }}
                  >
                    {props.payDetails.sponsorName}
                    <br />
                    {props.payDetails.sponsorEmail}
                    <br />
                    {props.payDetails.companyName}
                    <br />
                    <br />
                    {"Cust Vat No."}
                    <span>{props.payDetails.vatNumber}</span>
                  </div>
                ) : (
                  <div
                    style={{
                      border: "2px solid #000",
                      height: "50px",
                      fontSize: "11px",
                      textAlign: "left",
                      paddingLeft: "20px",
                    }}
                  >
                    {props.payDetails.billingAddress}
                    <br />
                    <br />
                  </div>
                )}
              </td>
            </tr>
          </table>
          <div style={{ padding: "10px", fontSize: "12px" }}>
            <table
              style={{
                width: "100%",
                border: "1px solid #000",
                borderCollapse: "collapse",
              }}
            >
              <tr>
                <th
                  style={{
                    border: "1px solid #000",
                    borderCollapse: "collapse",
                    textAlign: "left",
                    paddingLeft: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Description
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    borderCollapse: "collapse",
                    textAlign: "left",
                    paddingLeft: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Customer No.
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    borderCollapse: "collapse",
                    textAlign: "left",
                    paddingLeft: "5px",
                    fontWeight: "bold",
                  }}
                >
                  SAICE Contact Person
                </th>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px solid #000",
                    borderCollapse: "collapse",
                    fontSize: "11px",
                    paddingLeft: "5px",
                  }}
                ></td>
                <td
                  style={{
                    border: "1px solid #000",
                    borderCollapse: "collapse",
                    fontSize: "11px",
                    paddingLeft: "5px",
                  }}
                >
                  {props.payDetails.memberNumber}
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    borderCollapse: "collapse",
                    fontSize: "11px",
                    paddingLeft: "5px",
                  }}
                >
                  Erika Coetzer
                </td>
              </tr>
            </table>
          </div>
          <div style={{ padding: "10px", fontSize: "12px", height: "100%" }}>
            <table
              style={{
                width: "100%",
                height: "100%",
                border: "1px solid #000",
                borderCollapse: "collapse",
                pageBreakInside: "auto",
              }}
            >
              <tr>
                <th
                  style={{
                    border: "1px solid #000",
                    borderCollapse: "collapse",
                    textAlign: "left",
                    paddingLeft: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Item No.
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    borderCollapse: "collapse",
                    textAlign: "left",
                    paddingLeft: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Description
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    borderCollapse: "collapse",
                    textAlign: "left",
                    paddingLeft: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Unit Price
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    borderCollapse: "collapse",
                    textAlign: "left",
                    paddingLeft: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Quantity
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    borderCollapse: "collapse",
                    textAlign: "left",
                    paddingLeft: "5px",
                    fontWeight: "bold",
                  }}
                >
                  VAT
                </th>
                <th
                  style={{
                    border: "1px solid #000",
                    borderCollapse: "collapse",
                    textAlign: "left",
                    paddingLeft: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Amount
                </th>
              </tr>
              <>
                {props.payDetails?.products?.map((element, index) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        pageBreakInside: "avoid",
                        pageBreakAfter:
                          index != 0 && index % 10 == 0 ? "always" : "auto",
                      }}
                    >
                      <td
                        style={{
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          border: "1px solid #000",
                          borderCollapse: "collapse",
                          paddingLeft: "5px",
                        }}
                      >
                        {element.code}
                      </td>
                      <td
                        style={{
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          border: "1px solid #000",
                          borderCollapse: "collapse",
                          paddingLeft: "5px",
                        }}
                      >
                        {element.description}
                      </td>
                      <td
                        style={{
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          border: "1px solid #000",
                          borderCollapse: "collapse",
                          paddingLeft: "5px",
                        }}
                      >
                        {element.unit}
                      </td>
                      <td
                        style={{
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          border: "1px solid #000",
                          borderCollapse: "collapse",
                          paddingLeft: "5px",
                        }}
                      >
                        {element.quantity}
                      </td>
                      <td
                        style={{
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          border: "1px solid #000",
                          borderCollapse: "collapse",
                          paddingLeft: "5px",
                        }}
                      >
                        {props.payDetails?.currencyCode + " " + element.vat}
                      </td>
                      <td
                        style={{
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          border: "1px solid #000",
                          borderCollapse: "collapse",
                          paddingLeft: "5px",
                        }}
                      >
                        {props.payDetails?.currencyCode + " " + element.amount}
                      </td>
                    </tr>
                  );
                })}
              </>
            </table>
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              flexDirection: "row",
              fontSize: "12px",
              padding: "10px",
              pageBreakInside: "avoid",
            }}
          >
            <div style={{ width: "40%", float: "left" }}>
              <div style={{ fontWeight: "bold" }}>{"Remit To: "}</div>
              <div style={{ fontWeight: "bold", color: "#159CE8" }}>
                {"PLEASE NOTE NEW BANKING DETAILS FOR SAICE"}
              </div>
              <div style={{ fontWeight: "bold" }}>
                {invoiceData.header.addressHeader}
              </div>
              <div>{"First National Bank"}</div>
              <div>{"Mall of the South"}</div>
              <div>{"Branch: 210819"}</div>
              <div>{"Acc. No.: 62719150245"}</div>
              <br />
              <div style={{ fontStyle: "underline", fontWeight: "bold" }}>
                Please use the following as the reference on your payment -
                <br />
                {props.payDetails.invoiceNumber}
              </div>
            </div>
            <div
              style={{
                width: "50%",
                float: "right",
                textAlign: "-webkit-right",
                paddingRight: "5px",
              }}
            >
              <table>
                <tr>
                  <td colSpan="2" style={{ paddingTop: "30px" }}>
                    <table
                      width="95%"
                      align="right"
                      style={{ fontSize: "14px", borderCollapse: "collapse" }}
                    >
                      <tr>
                        <td colSpan="3"></td>
                        <td
                          colSpan="2"
                          style={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            borderTop: "1px solid black",
                            fontWeight: "bold",
                            fontStyle: "italic",
                          }}
                        >
                          Total excl. VAT
                        </td>
                        <td
                          style={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            borderTop: "1px solid black",
                            fontWeight: "bold",
                          }}
                        >
                          {props.payDetails?.currencyCode +
                            " " +
                            props.payDetails?.invoiceTotal?.total}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3"></td>
                        <td
                          colSpan="2"
                          style={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            fontWeight: "bold",
                            fontStyle: "italic",
                          }}
                        >
                          Value added tax
                        </td>
                        <td
                          style={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {props.payDetails?.currencyCode +
                            " " +
                            props.payDetails?.invoiceTotal?.valueTax}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3"></td>
                        <td
                          colSpan="2"
                          style={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            fontWeight: "bold",
                            fontStyle: "italic",
                          }}
                        >
                          Invoice total due
                        </td>
                        <td
                          style={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {props.payDetails?.currencyCode +
                              " " +
                              props.payDetails?.invoiceTotal?.invoideTotal}
                          </span>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
