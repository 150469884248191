import { CAPS_LOCK_WARNING } from "constants/settings";

export const getProfessionalOrganisationSchema = {
  title: "Add Professional Organisation Details ",
  logicalname: "Contact2",
  type: "object",
  properties: {
    _ctpt_organisationname_value: {
      title: "Organisation Name",
      type: "string",
      schemaname: "_ctpt_organisationname_value",
      datatype: "string",
      requiredCheck: true,
      isDynamic: true,
      apiURL: "/api/GetAllAsync",
      payload: {
        properties: [
          "ctpt_organisationname",
          "ctpt_organisationid",
          "ctpt_displayflag",
        ],
        filter: {
          globalfilter: "",
          globalAndOperator: false,
          fieldAndOperator: true,
          filterfields: [
            {
              field: "statecode",
              searchvalue: 0,
              type: "guid",
            },
          ],
        },
        Aggregate: {
          AggregateFields: [],
        },
        sortfield: {
          field: "ctpt_organisationname",
          sorttype: "asc",
        },
        recordtype: "ctpt_organisations",
        idfield: "ctpt_organisationid",
        continuationToken: "",
        pazesize: "1000",
      },
      enum: ["1"],
      enumNames: ["1"],
    },
    _ctpt_organisationcountry_value: {
      title: "Organisation Country",
      type: "string",
      schemaname: "_ctpt_organisationcountry_value",
      datatype: "string",
      requiredCheck: false,
      isDynamic: true,
      apiURL: "/api/GetAllAsync",
      payload: {
        properties: ["ctpt_name", "ctpt_countryid", "ctpt_displayflag"],
        filter: {
          globalfilter: "",
          globalAndOperator: false,
          fieldAndOperator: true,
          filterfields: [
            {
              field: "statecode",
              searchvalue: 0,
              type: "guid",
            },
          ],
        },
        Aggregate: {
          AggregateFields: [],
        },
        sortfield: {
          field: "ctpt_title",
          sorttype: "asc",
        },
        recordtype: "ctpt_countries",
        idfield: "ctpt_countryid",
        continuationToken: "",
        pazesize: "1000",
      },
      enum: ["1"],
      enumNames: ["1"],
    },
    _ctpt_registrationcategory_value: {
      title: "Registration Category",
      type: "string",
      schemaname: "ctpt_startdate",
      datatype: "string",
      requiredCheck: false,
      isDynamic: true,
      apiURL: "/api/GetAllAsync",
      payload: {
        properties: [
          "ctpt_name",
          "ctpt_registrationcategoryid",
          "ctpt_displayflag",
        ],
        filter: {
          globalfilter: "",
          globalAndOperator: false,
          fieldAndOperator: true,
          filterfields: [
            {
              field: "statecode",
              searchvalue: 0,
              type: "guid",
            },
          ],
        },
        Aggregate: {
          AggregateFields: [],
        },
        sortfield: {
          field: "ctpt_name",
          sorttype: "asc",
        },
        recordtype: "ctpt_registrationcategories",
        idfield: "ctpt_registrationcategoryid",
        continuationToken: "",
        pazesize: "1000",
      },
      enum: ["1"],
      enumNames: ["1"],
    },
    ctpt_yearofelection: {
      title: "Year of Election",
      type: "string",
      schemaname: "ctpt_enddate",
      datatype: "string",
      requiredCheck: false,
    },
    ctpt_registrationnumber: {
      title: "Registration Number",
      type: "string",
      schemaname: "ctpt_registrationnumber",
      datatype: "string",
      requiredCheck: false,
    },
  },
};

export const getEmailDetailsSchema = {
  title: "Update Email",
  logicalname: "employmenthistory",
  type: "object",
  additionalDescription: CAPS_LOCK_WARNING,
  properties: {
    ctpt_emailaddress: {
      title: "Email Address",
      type: "string",
      schemaname: "ctpt_emailaddress",
      datatype: "string",
      requiredCheck: true,
      pattern:
        "^[a-zA-Z0-9.\\-_]+(\\.[a-zA-Z0-9\\-_]+)*@[a-z0-9]+(\\-[a-z0-9]+)*(\\.[a-z0-9]+(\\-[a-z0-9]+)*)*\\.[a-z]{2,4}$",
      message: "Invalid Email Address",
    },
  },
};
