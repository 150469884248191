import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { globalTheme } from "style/globalTheme";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginRight: theme.spacing(2),
  },
  menuButton: {
    color: "black",
    height: "2rem",
    fontSize: "0.95rem",
    "&:hover": { background: globalTheme.palette.secondary.main },
  },
  menuLink: {
    textDecoration: "none",
    color: globalTheme.palette.grey[800],

    fontSize: ".9rem",
    height: "calc(1rem + 16px)",
    width: "100%",
  },
  menuItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export default function MenuListComposition({
  menuTitle = "menu",
  menuItems = [],
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((openState) => !openState);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open;
  // }, [open]);

  return (
    <>
      <div
        onMouseLeave={() => {
          setOpen(false);
        }}
      >
        <Button
          className={classes.menuButton}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          onMouseOver={() => {
            setOpen(true);
          }}
        >
          {menuTitle}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {menuItems.map((menuItem, index) => {
                      return (
                        <div key={menuItem.name}>
                          <MenuItem
                            className={classes.menuItem}
                            onClick={handleClose}
                          >
                            <Link
                              className={classes.menuLink}
                              to={menuItem.link}
                            >
                              {menuItem.name}
                            </Link>
                          </MenuItem>
                        </div>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  );
}
