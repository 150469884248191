export const config = {
  teleportal: {
    env: 'https://se-azgb-tp-prfun01.azurewebsites.net',
    file: {
      Create: '/api/CreateFileAsync',
      Delete: '/api/DeleteFileAsync',
      get: '/api/GetFileAsync',
      list: '/api/GetAllAsync',
      delete: '/api/DeleteRecordAsync'
    },
    lookup: {
      get: '/api/GetAllAsync'
    },
    submitForm: {
      Create: '/api/CreateRecordAsync',
      Update: '/api/UpdateRecordAsync',
      get: '/api/GetAllAsync',
      getId: '/api/GetRecordByIdAsync',
      delete: '/api/DeleteRecordAsync'
    },
    profile: {
      get: '/api/ProfileAsync'
    },
    sonicForm: {
      get: '/api/GetFormSchemaAsync'
    },
    preferences: {
      get: '/api/GetPreferencesAsync',
      update: '/api/UpdatePreferencesAsync'
    },
    letter: {
      create: '/api/CaseCreationAsync'
    },
    order: {
      create: '/api/OrderCreationAsync'
    },
    custom: {
      fetch: '/api/ApplicationFormListAsync'
    }
  },
  entitlement: {
    env: 'https://se-azgb-etl-prfun01.azurewebsites.net',
    entitle: {
      getContactEntitle:
        '/api/GetContactEntitlementAsync?',
      getEntitle:
        '/api/GetEntitlementAsync?'
    }
  },
  publicAPI: {
    firmDirectory:
      'https://se-azgb-tp-dvfun04.azurewebsites.net/api/GetPublicMemberFirmDirectoryAsync?code=YwPDfwlNMCti2u3CaGChUK8bIcsXlQWFWKzLD5coUJjM1WgT1m4PFA==&recordtype=account',
    memberDirectory:
      'https://se-azgb-tp-dvfun04.azurewebsites.net/api/GetPublicMemberFirmDirectoryAsync?code=YwPDfwlNMCti2u3CaGChUK8bIcsXlQWFWKzLD5coUJjM1WgT1m4PFA==&recordtype=contact'
  },
  sonicwebform: {
    env: 'https://se-azgb-swf-prfun02.azurewebsites.net',
    getFormschema: '/api/GetFormSchemaAsync',
    createFormsubmit: '/api/CreateFormSubmissionAsync',
    getFormsubmit: '/api/GetFormSubmissionAsync',
    cancelFormsubmit: '/api/CancelFormSubmissionAsync'
  },
  payment: {
    env: 'https://se-azgb-tp-prfun03.azurewebsites.net',
    orderDetails: '/api/OrderDetailsAsync',
    payHandler: '/api/InitiateTransactionAsync',
    processTransactionSync: '/api/ProcessTransationAsync',
    queryTransactionSync: '/api/QueryTransactionAsync',
    returnUrl: 'https://se-azgb-tp-prfun03.azurewebsites.net/api/Redirect',
    initiateTrans: 'https://secure.paygate.co.za/payweb3/initiate.trans',
    payGateProcessTransactionSync:
      'https://secure.paygate.co.za/payweb3/process.trans',
    payGateQueryTransaction: 'https://secure.paygate.co.za/payweb3/query.trans'
  },
  blob: {
    env: 'https://seazgbtpteasa02.blob.core.windows.net'
  }
}
