import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { workAddressSchema } from "app/components/mvp/formWidget/model/workAddressJson";
import { workAddressUiSchema } from "app/components/uiSchema/workAddressUiSchema";
import { WorkAddressPayload } from "app/services/payloadSubmit/workAddressPayload";
import { config } from "utilities/config";
import { post } from "app/services/api";
import FormWidget from "app/components/mvp/formWidget";

function WorkAddress(props) {
  const contactid = useSelector((state) => state.contactid);
  const [contactFormData, setContactFormData] = useState({});
  const userEntitlement = useSelector((state) => state.entitleMents);
  const userRole = useSelector((state) => state.role);
  let permissions =
    userEntitlement && userEntitlement.find((x) => x.route == props.match.path);

  const contact = {
    widgetType: "updateWorkAddress",
    formSchema: workAddressSchema,
    uiSchema: workAddressUiSchema,
    formData: contactFormData,
    apiURL: config.teleportal.submitForm.Update,
    payloadSchema: {},
    entityType: "ctpt_channels",
    formUischema: "",
    button1Label: "Save",
    button2Label: "Go Back",
    payloadSubmit: WorkAddressPayload,
    contactId: contactid,
    goBackPage: "/profile",
    historyUrl: "",
    recordId: "",
    button2GoBack: true,
  };

  const [contactOptions, setContactOptions] = useState(contact);

  useEffect(() => {
    if (contactid.length > 0) {
      getContactDetails();
    }
  }, [contactid]);

  const getContactDetails = async () => {
    let api;
    let payloads;
    const formData = {};
    api = config.teleportal.submitForm.get;

    payloads = {
      properties: [
        "ctpt_line1",
        "ctpt_line2",
        "ctpt_county",
        "ctpt_city",
        "ctpt_postalcode",
        "ctpt_country",
        "ctpt_addresstype",
        "ctpt_channelid",
      ],
      recordType: "ctpt_channels",
      formattedFields: ["ctpt_addresstype"],
      filter: {
        globalfilter: "",
        globalAndOperator: false,
        fieldAndOperator: true,
        filterfields: [
          {
            field: "_ctpt_customerid_value",
            searchvalue: contactid,
            type: "guid",
          },
          {
            field: "ctpt_addresstype",
            searchvalue: "727530002",
            type: "guid",
          },
        ],
      },
      Aggregate: {
        AggregateFields: [],
      },
      idfield: "ctpt_channelid",
      continuationToken: "",
      pazesize: "10",
    };

    const billingCompanyPayload = {
      properties: [
        "ctpt_companyname",
        "ctpt_billingcontactperson",
        "ctpt_vatnumber",
      ],
      recordType: "contacts",
      id: contactid,
    };
    const getBillingContactDetails = post(
      config.teleportal.submitForm.getId,
      billingCompanyPayload
    )
      .then((res) => {
        res.data.data.forEach((x) => {
          formData[x.name] = x.value || "";
        });
      })
      .catch(console.log);
    const getBillingAddressDetails = post(api, payloads)
      .then((res) => {
        res.data.data?.[0]?.data?.forEach((x) => {
          formData[x.name] = x.value || "";
        });
        contact.formData = formData;
        contact.initialFormData = formData;
        contact.recordId = res?.data?.data?.[0]?.id;
        if (!contact.recordId) {
          contact.apiURL = config.teleportal.submitForm.Create;
        }
      })
      .catch(console.log);

    await Promise.all([getBillingContactDetails, getBillingAddressDetails]);
    updateFormData({}, formData);
  };

  const updateFormData = (entityType, formData) => {
    // if (
    //   userRole.name.includes('Individual') &&
    //   permissions &&
    //   permissions.permissionsets != null &&
    //   permissions.permissionsets.length > 0 &&
    //   permissions.permissionsets[0] != null &&
    //   permissions.permissionsets[0].canwrite == '0' &&
    //   permissions.permissionsets[0].cancreate == '0'
    // ) {
    //   contact.formSchema.properties.ctpt_city.isReadonly = true
    //   contact.formSchema.properties.ctpt_county.isReadonly = true
    //   contact.formSchema.properties.ctpt_line1.isReadonly = true
    // }
    /* NOTE-This needs to be put back for entitlements */
    contact.formData = formData;
    contact.initialFormData = formData;
    setContactOptions(contact);
    setContactFormData(formData);
  };

  const getResponse = (res) => {
    console.log("RES:", res.data);
    if (res.data.id) {
      setContactOptions((data) => ({
        ...data,
        apiURL: config.teleportal.submitForm.Update,
        recordId: res.data.id,
      }));
      alert("Successfully submitted!");
    } else {
      alert("submission failed!");
    }
  };

  return (
    <Grid container spacing={0} justify="center">
      <Grid item xs={12} md={9} spacing={10}>
        <FormWidget
          options={contactOptions}
          updateFormData={updateFormData}
          successResponse={getResponse}
        />
      </Grid>
    </Grid>
  );
}

export default WorkAddress;
