export const getEventsPayload = {
  properties: [
    "msmedia_mediaeventid",
    "msmedia_name",
    "msmedia_startdate",
    "msmedia_enddate",
    "msmedia_directions",
    "msmedia_description",
    "msmedia_eventsubtitle",
    "msmedia_eventtopic",
    "msmedia_eventtimezone",
    "msmedia_eventtype",
    "statecode",
    "msmedia_registrationopenson",
    "msmedia_registrationcloseson",
    "ctpt_cancellationduedate",
    "_ctpt_examid_value",
  ],
  formattedFields: ["msmedia_totalcost"],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: true,
    filterfields: [
      {
        field: "msmedia_eventtype",
        searchvalue: "533910001",
        type: "Picklist",
      },
      { field: "statecode", searchvalue: 0 },
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  sortfield: {
    field: "msmedia_startdate",
    sorttype: "asc",
  },
  recordtype: "msmedia_mediaevents",
  idfield: "msmedia_mediaeventid",
  continuationToken: "",
  pazesize: "10",
};

export const getEventRegPayload = (contactID) => ({
  properties: [
    "_msmedia_contact_value",
    "_msmedia_mediaevent_value",
    "msmedia_mediaregistrationid",
    "msmedia_registrationstatus",
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: true,
    filterfields: [
      {
        field: "_msmedia_contact_value",
        searchvalue: contactID,
        type: "lookup",
      },
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  recordtype: "msmedia_mediaregistrations",
  idfield: "msmedia_mediaregistrationid",
  continuationToken: "",
  pazesize: "10",
});

export const getRegEventsPayload = (registeredEvents) => {
  console.log({ registeredEvents });
  const filterfields = registeredEvents.map((eventID) => ({
    field: "msmedia_mediaeventid",
    searchvalue: eventID,
    type: "guid",
  }));

  console.log({ filterfields });

  if (filterfields.length <= 0) {
    return null;
  }

  return {
    properties: [
      "msmedia_mediaeventid",
      "msmedia_name",
      "msmedia_startdate",
      "msmedia_enddate",
      "msmedia_directions",
      "msmedia_description",
      "msmedia_eventsubtitle",
      "msmedia_eventtopic",
      "msmedia_eventtimezone",
      "msmedia_eventtype",
      "statecode",
      "msmedia_registrationopenson",
      "msmedia_registrationcloseson",
      "ctpt_cancellationduedate",
      "_ctpt_examid_value",
    ],
    formattedFields: ["msmedia_totalcost"],
    filter: {
      globalfilter: "",
      globalAndOperator: false,
      fieldOrOperator: true,
      filterfields: filterfields,
    },
    Aggregate: {
      AggregateFields: [],
    },
    sortfield: {
      field: "msmedia_startdate",
      sorttype: "asc",
    },
    recordtype: "msmedia_mediaevents",
    idfield: "msmedia_mediaeventid",
    continuationToken: "",
    pazesize: "10",
  };
};

export const getTimeZoneDefsPayload = () => ({
  properties: ["timezonecode", "standardname"],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: false,
    filterfields: [],
  },
  Aggregate: {
    AggregateFields: [],
  },
  recordtype: "timezonedefinitions",
  idfield: "timezonecode",
  continuationToken: "",
  pazesize: "1000",
});
