export const getemploymentlistPayload = (contactid) => {
  var payload;
  payload = {
    properties: [
      "ctpt_company",
      "ctpt_jobtitle",
      "ctpt_startdate",
      "ctpt_enddate",
      "ctpt_typeofemployment",
      "ctpt_professionalhistoryid",
    ],
    formattedFields: [
      "ctpt_startdate",
      "ctpt_enddate",
      "ctpt_typeofemployment",
    ],
    filter: {
      globalfilter: "",
      globalAndOperator: true,
      fieldAndOperator: true,
      filterfields: [
        {
          field: "_ctpt_employeeid_value",
          searchvalue: contactid,
          type: "guid",
        },
        { field: "statecode", searchvalue: 0 },
      ],
    },
    recordtype: "ctpt_professionalhistories",
    idfield: "ctpt_professionalhistoryid",
    continuationToken: "",
    pazesize: "10",
  };
  return payload;
};

export const getEmploymentDetailsPayload = {
  properties: [
    //"ctpt_employmenthistory",
    // "ctpt_coursetype",
    // "ctpt_grade",
    // "ctpt_level",
    // "ctpt_type",
    // "ctpt_yearofaward",
    // "ctpt_jobtitle",
    // "ctpt_professionalhistoryid"
    //"ctpt_jobdescription",
    "ctpt_company",
    "ctpt_jobtitle",
    "ctpt_startdate",
    "ctpt_enddate",
    "ctpt_typeofemployment",
    "ctpt_professionalhistoryid",
  ],
  formattedFields: [],
  recordType: "ctpt_professionalhistories",
  // id: contactid,

  FieldAndOperator: "true",
  idfield: "contactid",
};

export const employmentDetailsPayload = (JsonData) => {
  const details = JsonData.data;
  var payload;
  payload = {
    id: JsonData.recordId,
    recordtype: JsonData.recordtype,
    data: [
      // {
      //   name: "ctpt_EmployeeId",
      //   value: JsonData.contactid,
      //   type: "lookup",
      //   relatedentity: "contacts",
      // },
      // {
      //   name: "ctpt_jobdescription",
      //   value: details[0].value,
      //   type: "string",
      // },
      {
        name: "ctpt_jobtitle",
        value: details[0].value,
        type: "string",
      },
      {
        name: "ctpt_company",
        value: details[1].value,
        type: "string",
      },
      {
        name: "ctpt_typeofemployment",
        value: details[2].value,
        type: "picklist",
      },
      {
        name: "ctpt_startdate",
        value: details[3].value,
        type: "string",
      },
      {
        name: "ctpt_enddate",
        value: details[4].value,
        type: "string",
      },
    ],
  };
  return payload;
};
