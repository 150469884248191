import { CAPS_LOCK_WARNING } from "constants/settings";
import { config } from "utilities/config";
export const getStudentSchema = {
  title: "Service Request",
  logicalname: "Contact2",
  type: "object",
  description:CAPS_LOCK_WARNING,
  properties: {

    ctpt_title: {
      title: "Case Name",
      type: "string",
      schemaname: "ctpt_title",
      datatype: "string",
      isReadonly:true
    },
    ctpt_casenumber: {
      title: "Case Number",
      type: "string",
      schemaname: "ctpt_casenumber",
      datatype: "string",
      isReadonly:true
    },
    ctpt_details: {
      title: "Details",
      type: "string",
      schemaname: "ctpt_details",
      datatype: "string",
      ismultiline:true,
      multilineRow:4,
      isReadonly:true
    },
    createdon: {
      title: "Case created on",
      type: "string",
      schemaname: "createdon",
      datatype: "string",
      isReadonly:true
    },
    modifiedon: {
      title: "Last Updated",
      type: "string",
      schemaname: "modifiedon",
      datatype: "string",
      isReadonly:true
    },
    ctpt_caseresolutiondetails: {
      title: "Resolutions",
      type: "string",
      schemaname: "ctpt_caseresolutiondetails",
      datatype: "string",
      isReadonly:true
    },
    ctpt_documenturl: {
      title: "Attachment",
      type: "string",
      schemaname: "ctpt_documenturl",
      datatype: "string",
      isReadonly:true,
      labelText:"Download",
      isDynamic:false,
      apiUrl:"/api/GetFileAsync",
      payload:{}
    }
  }
};