import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { globalTheme } from "style/globalTheme";
import { Grid, Button } from "@material-ui/core";

//Import Material-UI Library
import { ThemeProvider } from "@material-ui/core/styles";
import { useStyles } from "./style";

import ListComponent from "app/components/mvp/listWidget";
import { config } from "utilities/config";
import { getlistPayload } from "./model/payload";
import { getStudentSchema } from "./model/studentSchema";
import { getStudentUiSchema } from "./model/UiSchema";
import {
  CreaterequestPayload,
  CreatedocPayload,
  createDocAttachmentPayload,
} from "./model/createPayload";
import { stubTrue } from "lodash";

function ContractualRecords(props) {
  const classes = useStyles();
  const getcontactId = useSelector((state) => state.contactid);
  const getcontactName = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);
  const [open, setOpen] = React.useState(false);
  const [updatedKey, setupdatedKey] = React.useState(false);
  const viewRecord = (params) => {
    window.location.href =
      "/cpd/viewrecord?id=" + params.row.ctpt_studenttrainingagreementid;
  };
  const columns = [
    { field: "_ctpt_student_value", flex: 1, headerName: "", hide: true },
    {
      field: "ctpt_name",
      flex: 1,
      headerName: "Student Name",
    },
    {
      field: "ctpt_startdate",
      flex: 1,
      headerName: "Start Date",
      renderCell: (params) => (
        <div>
          {params.row.ctpt_startdate !== null
            ? new Date(params.row.ctpt_startdate).toISOString().substring(0, 10)
            : ""}
        </div>
      ),
    },
    {
      field: "ctpt_enddate",
      flex: 1,
      headerName: "End Date",
      renderCell: (params) => (
        <div>
          {params.row.ctpt_enddate !== null
            ? new Date(params.row.ctpt_enddate).toISOString().substring(0, 10)
            : ""}
        </div>
      ),
    },
    { field: "ctpt_duration", flex: 1, headerName: "Duration" },
    {
      field: "ctpt_studenttrainingagreementid",
      flex: 1,
      headerName: " ",
      renderCell: (params) => (
        <div>
          <Button
            variant="outlined"
            color="primary"
            className="primaryBtn"
            onClick={() => {
              viewRecord(params);
            }}
          >
            Update Record
          </Button>
        </div>
      ),
    },
  ];
  const recordOptions = {
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: columns,
    searchPayload: "searchFilter",
    getlistPayload: getlistPayload,
    deleteListPayload: {},
    listHeading: "Students Training Agreement",
    formwidgetOptions: {
      widgetType: "add",
      formSchema: getStudentSchema,
      uiSchema: getStudentUiSchema,
      apiURL: config.teleportal.submitForm.Create,
      entityType: "ctpt_studenttrainingagreements",
      formUischema: "",
      button1Label: "Create",
      button2Label: "Cancel",
      button3Label: "Clear",
      payloadSubmit: CreaterequestPayload(getcontactId),
      payloadSecondform: CreatedocPayload,
      columns: [],
      contactId: getcontactId,
      contactName: getcontactName.name,
      historyUrl: "",
      hasMultiplecall: true,
      hasModel: true,
    },
    nonRecord: false,
    role: role,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: false,
    filterField: "",
    filterValue: [],
    aggregateColumns: [],
    aggregateField: "",
    isCustomFetch: false,
    hideSearch: true,
    pageSize: 6,
    hasPayloadfilter: true,
    hideAdd: false,
    controlLists: true,
  };
  const handleClickOpen = (params) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <div className={classes.container}>
            {getcontactId !== "" ? (
              <ListComponent options={recordOptions} />
            ) : (
              ""
            )}
            </div>
          </Grid>
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
   
  );
}

export default ContractualRecords;
