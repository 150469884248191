import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import FormWidget from "app/components/mvp/formWidget";
import { viewQualificationDetailsUiSchema } from "./model/UiSchema";
import { getQualificationDetailsPayload, qualificationDetailsPayload } from "./model/payload";
import { config } from "utilities/config";
import { useLocation, useHistory } from "react-router-dom";
import { post } from "app/services/api";
import { getQualificationDetailsSchema } from "./model/schema";
import { useProfileStyles } from "./style";
import { usePreLoader } from "app/services/hooks/usePreLoader";
import clsx from "clsx";
import { useStyles } from "./style";

const StyledFormWidget = styled(FormWidget)(
  () => css`
    div[class="button-group"] {
      margin-top: 2rem;
    }
  `
);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ViewQualificationDetails(props) {
  const [recordId, setrecordId] = useState("");
  const [contact2FormData, setContact2FormData] = useState({});
  const getcontactId = useSelector((state) => state.contactid);
  let query = useQuery();
  const dispatch = useDispatch();
  const userEntitlement = useSelector((state) => state.entitleMents);
  const userRole = useSelector((state) => state.role);
  let permissions =
    userEntitlement && userEntitlement.find((x) => x.route == props.match.path);

  let formDataa = {};
  const { enableLoader } = usePreLoader();

  const classes = useStyles();
  const history = useHistory();

  const options = {
    widgetType: "update",
    formSchema: getQualificationDetailsSchema,
    uiSchema: viewQualificationDetailsUiSchema,
    entityType: "mshied_previouseducations",
    button1Label: "Save",
    button2Label: "Go Back",
    apiURL: config.teleportal.submitForm.Update,
    payloadSubmit: qualificationDetailsPayload,
    goBackPage: "/profile/qualifications",
    historyUrl: "",
    button2GoBack: true,
    columns: [],
    contactId: getcontactId,
    formData: contact2FormData,
    hasModel: false,
    recordId: recordId
  };

  const [contactOptions, setContactOptions] = useState(options);

  useEffect(() => {
    if (window.location.href.indexOf("?") > -1) {
      setrecordId(query.get("id"));
    }
  }, []);

  useEffect(() => {
    let payload = {
      ...getQualificationDetailsPayload,
      filters: [
        {
          field: "mshied_previouseducationid",
          searchvalue: recordId,
          type: "guid"
        }
      ]
    };

    getRecordDetails(payload);
  }, [recordId]);

  const updateFormData = (entityType, formData) => {
    formData.mshied_graduationdate = formData.mshied_graduationdate == "1900-01-01" ? "" : formData.mshied_graduationdate;
    options.formData = formData;
    options.initialFormData = formData;
    setContactOptions(options);
    setContact2FormData(formData);
  };

  const getRecordDetails = (payload) => {
    let api = config.teleportal.submitForm.getId;
    if (recordId !== "") {
      enableLoader(true, "Loading Qualifications Details");
      post(api, payload)
        .then((res) => {
          res.data.data.forEach((item) => {
            console.log(item);
            if (item.name == "_mshied_degreeid__value") {
              formDataa["degreeConditional"] = {
                "_mshied_degreeid__value": item.value,
                "ctpt_otherdegreeid_": res.data.data[4].value
              }
            }
            else if (item.name == "_ctpt_discipline_value") {
              formDataa["disciplineConditional"] = {
                "_ctpt_discipline_value": item.value,
                "ctpt_otherdiscipline": res.data.data[6].value
              }
            }
            else if (item.name == "_ctpt_institutionname_value") {
              formDataa["institutionConditional"] = {
                "_ctpt_institutionname_value": item.value,
                "ctpt_otherinstitutionname": res.data.data[11].value
              }
            }
            else { formDataa[item.name] = item.id!=undefined ? item.id : item.value; }   

          });
          updateFormData({}, formDataa);
        })
        .catch(console.log)
        .finally(() => {
          enableLoader(false);
        });
    }
  };
  //console.log("CCONS:", contact2FormData);



  const getResponse = (res) => {
    if (res.data.id) {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Your Qualifications Updated successfully",
          code: "",
          display: "show",
          severity: "success",
        },
      });
    } else {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Qualifications update failed",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    }
  };


  return (
    <Grid container spacing={0} justify="center">
      <Grid item xs={12} md={9} spacing={10}>
        <StyledFormWidget
          options={contactOptions}
          updateFormData={updateFormData}
          successResponse={getResponse}
        />
      </Grid>
    </Grid>
  );
}

export default ViewQualificationDetails;
