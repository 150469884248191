import CustomTextBox from "app/components/atoms/customTextBox";
import CustomDate from "app/components/atoms/customDate";
import CustomRadio from "app/components/atoms/customRadio";
import CustomSelect from "app/components/atoms/customSelect";
import MultipleSelect from "../atoms/multiDropdown/MultipleSelect.mui";

export const contactUiSchema = {
  "ui:title": "",
  ctpt_title: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  firstname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  lastname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  fullname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  middlename: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_specialists: {
    "ui:widget": (props) => {
      return <MultipleSelect getProps={props} />;
    },
  },
  birthdate: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  // fax: {
  //   "ui:widget": (props) => {
  //     return <CustomTextBox getProps={props} />;
  //   },
  // },
  ctpt_areyouacitizenofsouthafrica: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  ctpt_profiledetailsconfirmedaccurateon: {
    "ui:widget": "hidden",
  },
};

export const kinContactUiSchema = {
  "ui:title": "",
  ctpt_nextofkinsurname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_nextofkinfirstname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_nextofkinsecondname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_nextofkinemail: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_nextofkinmobilenumber: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_nextofkinrelationship: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  relationshipConditional: {
    ctpt_nextofkinrelationship: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    ctpt_nextofkinother: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
};
