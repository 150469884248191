export const ContactPayload = (JsonData) => {
  const details = JsonData.data;
  var payload;
  payload = {
    id: JsonData.id,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "emailaddress1",
        value: details[0].value,
        type: "string",
      },
      {
        name: "emailaddress2",
        value: details[1].value,
        type: "string",
      },
    ],
  };
  return payload;
};

export const BranchPayload = (JsonData) => {
  const details = JsonData.data;
  var payload;
  payload = {
    id: JsonData.id,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "ctpt_branchid",
        value: details[0].value,
        type: "lookup",
        relatedentity: "ctpt_branchs",
      },
    ],
  };
  return payload;
};

export const getSupportingDocPayload = (contactID) => ({
  properties: [
    "ctpt_name",
    "ctpt_supportingdocumentid",
    "ctpt_documenturl",
    "ctpt_documenttype",
    "ctpt_documentdate",
    "ctpt_description",
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: false,
    filterfields: [
      {
        field: "_ctpt_customerid_value",
        searchvalue: contactID,
        type: "guid",
      },
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  recordtype: "ctpt_supportingdocuments",
  idfield: "ctpt_supportingdocumentid",
  continuationToken: "",
  pazesize: "10",
});

export const uploadSupportingDocPayload = (docURL, docName, contactID) => ({
  recordtype: "ctpt_supportingdocuments",
  data: [
    {
      name: "ctpt_name",
      value: (docName || "File"),
      type: "string",
    },
    {
      name: "ctpt_documenttype",
      value: 100000001,
      type: "picklist",
    },
    // {
    //   name: "ctpt_customer",
    //   value: contactID,
    //   type: "lookup",
    //   relatedentity: "contacts",
    // },
    {
      name: "ctpt_CustomerId_contact",
      value: contactID,
      type: "lookup",
      relatedentity: "contacts",
    },
    {
      name: "ctpt_documenturl",
      value: docURL,
      type: "string",
    },
  ],
});

export const updateSupportingDocPayload = (docID, docName, docURL) => {
  return {
    id: docID,
    recordtype: "ctpt_supportingdocuments",
    data: [
      {
        name: "ctpt_name",
        value: docName || "file",
        type: "string",
      },
      {
        name: "ctpt_documenturl",
        value: docURL,
        type: "string",
      },
    ],
  };
};

export const deleteSupportingDocPayload = (docID) => ({
  recordtype: "ctpt_supportingdocuments",
  id: docID,
});
