import React from "react";
import { Typography, Button } from "@material-ui/core";
import { globalTheme } from "style/globalTheme";
import styled, { css } from "styled-components";

const Card = styled.div(
  (props) => css`
    box-sizing: border-box;
    margin: 8px 8px 8px 8px;
    background-color: ${props.$backgroundColor};
    background-image: url(${props.$backgroundImageURL});
    background-position: 32px -107px;
    background-repeat: no-repeat;
    background-size: 77% auto;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    text-align: center;
    padding: 35px;
    padding-top: 60px;
    padding-bottom: 60px;
    max-width: 260px;
    min-width: 260px;
  `
);

export default function MediaCard(props) {
  const {
    id,
    title,
    linkUrl,
    description,
    backgroundColor,
    textColor,
    buttonText,
    backgroundImageURL,
  } = props;

  const onBtnClick = () => {
    window.open(linkUrl, "_blank").focus();
  }

  return (
    <Card
      $backgroundColor={backgroundColor}
      $backgroundImageURL={backgroundImageURL}
      key={id}
    >
      <Typography
        variant="h2"
        component="div"
        style={{
          color: "white",
          fontWeight: "600",
          lineHeight: "42px",
          fontSize: globalTheme.typography.h3,
          fontSize: "23px",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        component="div"
        style={{
          marginBottom: "15px",
          lineHeight: "27px",
          fontSize: "14px",
          color: textColor,
        }}
      >
        {description}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={onBtnClick}
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          fontWeight: "700",
          color: backgroundColor,
          padding: "10px 20px",
          fontSize: "13px",
          lineHeight: "13px",
          textTransform: "uppercase",
        }}
      >
        {buttonText}
      </Button>
    </Card>
  );
}
