import moment from "moment";

export const getListPayload = (declarationID) => ({
  properties: [
    "ctpt_evidenceid",
    "ctpt_evidenceurl",
    "ctpt_howmanyoftheseunitscouldyouverifywithsup",
    "ctpt_howmanyunitsareyourclaimingforthisactivit",
    "ctpt_name",
    "ctpt_whatdidyoulearnandhowdidwillyouapplyit",
    "ctpt_whendidthisactivitytakeplace",
    "ctpt_whydidyouchoosethisactivityandhowisitre",
    "_ctpt_memberdeclaration_value",
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: true,
    filterfields: [
      {
        field: "_ctpt_memberdeclaration_value",
        searchvalue: declarationID,
        type: "lookup",
      },
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  recordtype: "ctpt_evidences",
  idfield: "ctpt_evidenceid",
  continuationToken: "",
  pazesize: "10",
});

export const deleteListPayload = {
  recordtype: "ctpt_evidences",
};

export const updateRecordStatusPayload = (recordID, status) => {
  let fields = [
    {
      name: "statuscode",
      value: status,
      type: "number",
    },
  ];
  if (status === 533910004) {
    fields.push({
      name: "ctpt_declartationdate",
      value: moment().format("YYYY-MM-DD"),
      type: "string",
    });
  }
  return {
    id: recordID,
    recordtype: "ctpt_memberdeclarations",
    data: fields,
  };
};

export const getDeclarationCompliancePayload = (id) => ({
  properties: [
    "ctpt_cpdcomplied",
    "ctpt_cpdnoncomplied",
    "ctpt_compliedbicacodeofethics",
    "ctpt_notcompliedbicacodeofethics",
    "ctpt_undertakencpdbybica",
    "ctpt_undertakencpdbyifac",
    "ctpt_certifiedauditor",
    "ctpt_responsibleforfinancialstatements",
    "ctpt_responsibleforcompanysecretarialwork",
    "ctpt_anaccountingofficerofacompany",
    "ctpt_otherspecify",
    "ctpt_ownorandworkfornonauditingmemberfirm",
    "ctpt_pleadedguilty",
    "ctpt_submittedkyc",
  ],
  formattedFields: [],
  recordType: "ctpt_memberdeclarations",
  id: id,
});

export const updateCompliancePayload = (JsonData) => {
  const details = JsonData.data;
  var payload;
  payload = {
    id: JsonData.id,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "ctpt_cpdcomplied",
        value: details[0].value,
        type: "string",
      },
      {
        name: "ctpt_cpdnoncomplied",
        value: details[0].value,
        type: "string",
      },
      {
        name: "ctpt_compliedbicacodeofethics",
        value: details[1].value,
        type: "string",
      },
      {
        name: "ctpt_notcompliedbicacodeofethics",
        value: details[1].value,
        type: "string",
      },
      {
        name: "ctpt_undertakencpdbybica",
        value: details[2].value,
        type: "string",
      },
      {
        name: "ctpt_undertakencpdbyifac",
        value: details[2].value,
        type: "string",
      },
      {
        name: "ctpt_certifiedauditor",
        value: details[3].value,
        type: "string",
      },
      {
        name: "ctpt_responsibleforfinancialstatements",
        value: details[4].value,
        type: "string",
      },
      {
        name: "ctpt_responsibleforcompanysecretarialwork",
        value: details[5].value,
        type: "string",
      },
      {
        name: "ctpt_anaccountingofficerofacompany",
        value: details[6].value,
        type: "string",
      },
      {
        name: "ctpt_otherspecify",
        value: details[7].value,
        type: "string",
      },
      {
        name: "ctpt_ownorandworkfornonauditingmemberfirm",
        value: details[8].value,
        type: "string",
      },
      {
        name: "ctpt_pleadedguilty",
        value: details[9].value,
        type: "string",
      },
      {
        name: "ctpt_submittedkyc",
        value: details[10].value,
        type: "string",
      },
    ],
  };
  return payload;
};
