import { makeStyles } from "@material-ui/core/styles";
import { brandPalette } from "brand/theme/brandTheme";

const useProfileStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflow: "visible",
    //backgroundColor: "red",
    margin: "2rem auto",
    marginTop: 0,
  },
  profileBox: {
    width: "40vh",
    margin: theme.spacing(10, "auto"),
    backgroundColor: theme.palette.lightBg.main,
    height: "40vh",
    [theme.breakpoints.down("xs")]: {
      height: "60vh",
    },
  },
  background1: {
    backgroundColor: "#0055a5",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "23vh",
    zIndex: 0,
  },
  container: {
    minHeight: "55rem",
    padding: "2rem 2rem",
    "& div[class^=MuiTabs-flexContainer]": {
      margin: "0 5rem 3rem 5rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "3rem 0",
      "& div[class^=MuiTabs-flexContainer]": {
        margin: 0,
      },
    },
  },
  listSection: {
    // maxWidth: "65.6rem",
    margin: "0 auto",
    "& h5": {
      marginBottom: "3rem",
      marginLeft: "1.5rem",
    },
  },
  paidInvoiceList: {
    maxWidth: "68.4rem",
    margin: "0 auto",
  },
  textBox: {
    margin: theme.spacing(10, "auto"),
  },
  textBoxSmall: {
    margin: theme.spacing(5, "auto"),
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    color: theme.palette.primary.main,
    justifyContent: "center",
    margin: theme.spacing(5, "auto"),
    backgroundColor: theme.palette.midlightBg.main,
    fontSize: "40px",
  },
  dividerBar: {
    margin: theme.spacing(5, 0),
    width: "30vw",
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
      margin: theme.spacing(5, "auto"),
    },
  },
  editBtn: {
    marginLeft: "auto",
  },
  textBlock: {
    display: "inline-flex",
    VerticalAlign: "text-bottom",
    BoxSizing: "inherit",
    textAlign: "center",
    AlignItems: "center",
    marginTop: "30px",
  },
  iconImg: {
    marginLeft: "10px",
    cursor: "pointer",
  },
  tileCardContainer: {
    backgroundColor: theme.palette.midlight,
    display: "flex",
  },
  lastTileCard: {
    display: "flex",
    flex: 0,
  },
  link: {
    //borderBottom: "1px solid grey",
    cursor: "pointer",
    //padding: "3px 1.5rem",
    //backgroundColor: "grey",
    color: theme.palette.primary.main,
    //borderRadius: "15px",
    // transition: "all .2s",
    "&:hover, &:active, &:focus": {
      color: theme.palette.primary.main,
      //border: `2px solid ${theme.palette.secondary.main}`,
      //backgroundColor: theme.palette.secondary.main,
    },
  },
}));

export { useProfileStyles };
