import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { config } from "utilities/config";
import {
  getFormschemapayload,
  createFormsubmitpayload,
  getFormsubmitpayload,
} from "data/payload";
import Sonicwebform from "app/components/mvp/sonicwebform";
import { uiSchema } from "./model/uiSchema";
import styled from "styled-components";
import { Button, Grid } from "@material-ui/core";
import CreateOrder from "brand/components/order";
import { break_addressbar } from "utilities/splitUrl";
import { customValidate } from "./model/validations";
import { useSelector } from "react-redux";
import { checkOpenApp } from "utilities/checkOpenApp";
import { CAPS_LOCK_WARNING } from "constants/settings";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 10rem;
    max-width: 65%;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
    max-width: 100%;
  }
`;

function UpgradeStudent(props) {
  const [isStarted, setStartApplication] = useState(0);
  const [isNew, setisNew] = useState(true);
  const [urlParam, seturlParam] = useState({
    formName: "upgradeStudentApplication",
    subId: "",
  });
  const options = {
    formTitle: "Membership Upgrade Application",
    getFormschemaURL: config.sonicwebform.getFormschema,
    createFormsubmitURL: config.sonicwebform.createFormsubmit,
    getFormsubmitURL: config.sonicwebform.getFormsubmit,
    getFormschemapayload: getFormschemapayload,
    createFormsubmitpayload: createFormsubmitpayload,
    getFormsubmitpayload: getFormsubmitpayload,
    WebFormName: urlParam.formName,
    uiSchema: uiSchema,
    submissionId: urlParam.subId,
    stepCount: 0,
    formschema: {},
    swfdata: {},
    swfId: "",
    createForm: isNew,
    unAuthenticate: false,
    handleChange: props.handleChange,
    emptyOrder: false,
    hasPayment: false,
    validate: customValidate,
    defaultSectionAdditionalDesc: CAPS_LOCK_WARNING,
  };
  const getcontactId = useSelector((state) => state.contactid);
  const [isOpen, setOpenApplication] = useState(0);

  const startApp = () => {
    setStartApplication(1);
  };
  useEffect(() => {
    const fetchDetail = async () => {
      const isCheck = await checkOpenApp("upgradeStudentApplication", getcontactId);
      setOpenApplication(isCheck);
    };
    localStorage.setItem("oid", "");
    console.log("onload " + window.location.href.indexOf("?"));
    if (window.location.href.indexOf("?") > -1) {
      seturlParam(break_addressbar(window.location.href));
      console.log(break_addressbar(window.location.href));
      setisNew(false);
      setOpenApplication(2);
    } else {
      setisNew(true);
      fetchDetail().catch(console.log);
    }
  }, []);

  useEffect(() => {
    console.log("url parameters" + JSON.stringify(urlParam));
  }, [urlParam]);

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div id="cTTP_sonicForm">
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              {/* <Sonicwebform options={options} /> */}
              {isOpen == 2 && (
                <div>
                  {isStarted === 0 ? (
                    <StartPage>
                      <h1>
                        Membership Application - Upgrade from Student Member to
                        Associate Member
                      </h1>
                      <p
                        style={{
                          maxWidth: "70%",
                          fontWeight: 600,
                          marginTop: "2rem",
                        }}
                      >
                        An Associate Member is a person who:
                      </p>
                      <ul>
                        <li>
                          Does not satisfy the requirements for admission as a
                          Member but who has achieved a qualification in a
                          profession which is comparable to that of a Member of
                          the Institution and is connected with civil
                          engineering to the extent that admission to membership
                          in the opinion of the Executive Board will be in the
                          interest of the Institution;
                        </li>
                        <li>
                          Is not eligible to be elected or admitted as a
                          Corporate Member but who at the time of admission of
                          an Associate Member is actively engaged in civil
                          engineering and holds such academic qualifications as
                          may be acceptable to Council, or has entered for and
                          passed an ad hoc or other examination set by a panel
                          of examiners nominated by Council; or
                        </li>
                        <li>
                          Holds an academic qualification from a tertiary
                          educational institution recognised for this purpose by
                          Council.
                        </li>
                      </ul>
                      <p
                        style={{
                          maxWidth: "70%",
                          fontWeight: 600,
                          marginTop: "2rem",
                        }}
                      >
                        Kindly NOTE that you will be required to attach the
                        following documents during the application.
                      </p>
                      <ul style={{ maxWidth: "70%", paddingLeft: "1rem" }}>
                        <li style={{ margin: 0, padding: 0 }}>
                          Copy of your ID/Passport.
                        </li>
                        <li>Copy of your Qualifications.</li>
                        <li>
                          Proof of registration with other Professional
                          Organisations (If any)
                        </li>
                      </ul>
                      <br />
                      <br />
                      <div style={{ marginLeft: "1.8rem" }}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={startApp}
                        >
                          {isNew ? "Start Application" : "Resume Application"}
                        </Button>
                      </div>
                    </StartPage>
                  ) : (
                    <div>
                      <Sonicwebform options={options} />
                    </div>
                  )}
                </div>
              )}
              {isOpen == 1 && (
                <StartPage>
                  <h3>
                    Please go to{" "}
                    <a href="/applications">Manage Existing Applications</a> and
                    continue with your existing application.
                  </h3>
                </StartPage>
              )}
            </Grid>
          </Grid>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default CreateOrder(UpgradeStudent);
