import moment from "moment";

const thisDate = moment(new Date()).format("YYYY-MM-DD");

export const ContactPayload = (JsonData) => {
  const details = JsonData.data.reduce((acc, { name, value }) => {
    acc[name] = value;
    return acc;
  }, {});
  var payload;
  let manyToManyArr = [];
  let specialistsArr = details["ctpt_specialists"] || [];
  specialistsArr.length > 0 &&
    specialistsArr.filter(Boolean).map((specialist, index) => {
      manyToManyArr.push({
        parentId: JsonData.id,
        parentEntityName: "contacts",
        childId: specialist,
        childEntityName: "ctpt_specialists",
        type: "add",
      });
    });
  payload = {
    id: JsonData.id,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "ctpt_title",
        value: details["ctpt_title"],
        type: "string",
      },
      {
        name: "firstname",
        value: details["firstname"],
        type: "string",
      },
      {
        name: "lastname",
        value: details["lastname"],
        type: "string",
      },
      {
        name: "fullname",
        value: details["fullname"],
        type: "string",
      },
      {
        name: "middlename",
        value: details["middlename"],
        type: "string",
      },
      // {
      //   name: "ctpt_specialists",
      //   value: details[5].value,
      //   type: "array",
      // },
      {
        name: "ctpt_Specialist_Contact_Contact",
        manyToManyValues: manyToManyArr,
        relatedentity: "ctpt_Specialist_Contact_Contact",
        getRelatedEntity: "ctpt_specialist_contactset",
        type: "manytomany",
      },
      {
        name: "birthdate",
        value: details["birthdate"]
          ? moment(new Date(details["birthdate"])).format("YYYY-MM-DD")
          : undefined,
        type: "string",
      },
      // {
      //   name: "fax",
      //   value: details[7].value,
      //   type: "string",
      // },
      {
        name: "ctpt_areyouacitizenofsouthafrica",
        value: details["ctpt_areyouacitizenofsouthafrica"],
        type: "boolean",
      },
      {
        name: "ctpt_profiledetailsconfirmedaccurateon",
        value: thisDate,
        type: "string",
      },
    ],
  };
  return payload;
};

export const KinContactPayload = (JsonData) => {
  const details = JsonData.data;
  var payload;
  //let manyToManyArr = []
  // let specialistsArr = details[5].value || []
  // specialistsArr.length>0 && specialistsArr.map((specialist, index) => {
  //   manyToManyArr.push({
  //     "parentId": "5e4ef90a-d902-ed11-82e5-000d3a25577d",
  //     "parentEntityName": "contacts",
  //     "childId": specialist,
  //     "childEntityName": "ctpt_specialists",
  //     "type": "add"
  //   })
  // })
  payload = {
    id: JsonData.id,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "ctpt_nextofkinsurname",
        value: details[0].value,
        type: "string",
      },
      {
        name: "ctpt_nextofkinfirstname",
        value: details[1].value,
        type: "string",
      },
      {
        name: "ctpt_nextofkinsecondname",
        value: details[2].value,
        type: "string",
      },
      {
        name: "ctpt_nextofkinemail",
        value: details[3].value,
        type: "string",
      },
      {
        name: "ctpt_nextofkinmobilenumber",
        value: details[4].value,
        type: "string",
      },
      {
        name: "ctpt_nextofkinrelationship",
        value: details[5].value.ctpt_nextofkinrelationship,
        type: "string",
      },
      {
        name: "ctpt_nextofkinother",
        value: details[5].value.ctpt_nextofkinother || "",
        type: "string",
      },
    ],
  };
  return payload;
};
