import PayLater from "./PayLater";
import React from "react";
import PayThroughSponsor from "./PayThroughSponsor";
import PayUsingDebitCard from "./PayUsingDebitCard";
import UploadProofOfPayment from "./UploadProofOfPayment";
function PaymentMethods({
  paymentMethod,
  payDetails,
  payloads,
  cancelPayment,
  finishPayment,
  fullName,
  email,
  companyName,
  vatNumber,
  addressLine1,
  addressLine2,
  suburb,
  City,
  postalCode,
  //country,
  emailRegex,
  emailError,
  handleInputChange,
}) {
  if (paymentMethod === "1") {
    return (
      <PayUsingDebitCard
        payDetails={payDetails}
        payloads={payloads}
        email={email}
      />
    );
  }
  if (paymentMethod === "2") {
    return (
      <UploadProofOfPayment
        cancelPayment={cancelPayment}
        finishPayment={finishPayment}
      />
    );
  }
  if (paymentMethod === "3") {
    return (
      <PayThroughSponsor
        cancelPayment={cancelPayment}
        finishPayment={finishPayment}
        fullName={fullName}
        companyName={companyName}
        vatNumber={vatNumber}
        addressLine1={addressLine1}
        addressLine2={addressLine2}
        suburb={suburb}
        City={City}
        postalCode={postalCode}
        // country={country}
        email={email}
        emailRegex={emailRegex}
        emailError={emailError}
        handleInputChange={handleInputChange}
      />
    );
  }
  if (paymentMethod === "4") {
    return <PayLater payDetails={payDetails} />;
  }
  return <div />;
}

export default PaymentMethods;
