import { data } from "app/components/mvp/formWidget/model/listData";
import { post } from "app/services/api";
import { config } from "utilities/config";

export const CreaterequestPayload = (JsonData) => {
  const details = JsonData.data;
  const data = [
    {
      name: "mshied_StudentId",
      value: JsonData.contactid || "",
      type: "lookup",
      relatedentity: "contacts",
    },
    // {
    //   name: "mshied_name",
    //   value: details[0].value || "",
    //   type: "string",
    // },
    {
      name: "ctpt_Country",
      value: details[3].value || "",
      type: "lookup",
      relatedentity: "ctpt_countries",
    },
    {
      name: "mshied_DegreeId_",
      value: details[0].value.mshied_DegreeId_ || "",
      type: "lookup",
      relatedentity: "mshied_programlevels",
    },
    {
      name: "ctpt_otherdegreeid_",
      value:
        details[0].value.mshied_DegreeId_ ==
        "fe67ad1e-b5fb-ec11-82e5-00224881f7ec"
          ? details[0].value.ctpt_otherdegreeid_
          : "",
      type: "string",
    },
    {
      name: "ctpt_Discipline",
      value: details[1].value.ctpt_Discipline || "",
      type: "lookup",
      relatedentity: "ctpt_disciplines",
    },
    {
      name: "ctpt_otherdiscipline",
      value:
        details[1].value.ctpt_Discipline ==
        "5db0d663-bafb-ec11-82e5-00224881f7ec"
          ? details[1].value.ctpt_otherdiscipline
          : "",
      type: "string",
    },
    // {
    //   name: "ctpt_EducationTypeId",
    //   value: details[4].value,
    //   type: "lookup",
    //   relatedentity: "ctpt_categories"
    // },
    {
      name: "ctpt_InstitutionName",
      value: details[2].value.ctpt_InstitutionName || "",
      type: "lookup",
      relatedentity: "ctpt_institutionnames",
    },
    {
      name: "ctpt_otherinstitutionname",
      value:
        details[2].value.ctpt_InstitutionName ==
        "e47b482f-a7fb-ec11-82e5-00224881f1d4"
          ? details[2].value.ctpt_otherinstitutionname
          : "",
      type: "string",
    },
  ];
  if (details[4] && details[4].value != undefined) {
    data.push({
      name: "mshied_graduationdate",
      value: details[4].value
        ? new Date(details[4].value).toISOString().substring(0, 10)
        : "",
      type: "string",
    });
  }
  if (details[5] && details[5].value != undefined) {
    data.push({
      name: "ctpt_yearofqualification",
      value: details[5].value
        ? new Date(details[5].value).toISOString().substring(0, 4)
        : "",
      type: "string",
    });
  }
  // if (details[6] && details[6].value != undefined) {
  //   data.push({
  //     name: "ctpt_enddate",
  //     value: details[6].value
  //       ? new Date(details[6].value).toISOString().substring(0, 10)
  //       : "",
  //     type: "string",
  //   });
  // }
  return {
    recordtype: "mshied_previouseducations",
    data: data,
  };
};
