import React, { useState } from "react";

import styled, { css } from "styled-components";
import { globalTheme } from "style/globalTheme";

import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";

const HelpSection = styled.div(() => css``);

const HelpBtn = styled.div(
  () => css`
    top: -0.5rem;
    position: absolute;
    right: -4rem;
    display: "flex";
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    
    button {
      transition: ${globalTheme.transitions.create("transform", {
        duration: globalTheme.transitions.duration.shortest,
      })};
        
      border: 1px solid transparent;
      padding: 2px;
      margin-left: 5px;
        &:focus {
          border: 1px solid ${globalTheme.palette.primary.main};
        }
      }
      &.expanded{
        button{
          transform: rotate(180deg);
        }
  `
);

export default function FieldHelp(props) {
  const [expanded, setExpanded] = useState(false);
  const handleToggleButton = () => {
    setExpanded(!expanded);
  };
  if (props.helpButtonText) {
    return (
      <HelpSection className="fieldHelp">
        <HelpBtn className={expanded ? "expanded" : "unexpanded"}>
          {!expanded ? props.helpButtonText : "Close"}
          <IconButton
            onClick={handleToggleButton}
            aria-expanded={expanded}
            aria-label="show more"
            disableRipple
          >
            <ExpandMoreIcon />
          </IconButton>
        </HelpBtn>
        <Collapse in={expanded}>
          <span>{props.helpSectionText}</span>
        </Collapse>
      </HelpSection>
    );
  } else {
    return null;
  }
}
