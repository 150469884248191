import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import FormWidget from "app/components/mvp/formWidget";
import { viewEmploymentHistoryDetailsUiSchema } from "./model/UiSchema";
import {
  getEmploymentDetailsPayload,
  employmentDetailsPayload,
} from "./model/payload";
import { config } from "utilities/config";
import { useLocation, useHistory } from "react-router-dom";
import { post } from "app/services/api";
import { getEmploymentHistoryDetailsSchema } from "./model/schema";
import { useStyles } from "./style";
import { usePreLoader } from "app/services/hooks/usePreLoader";
import clsx from "clsx";
import { customValidate } from "./model/validation";

const StyledFormWidget = styled(FormWidget)(
  () => css`
    div[class="button-group"] {
      margin-top: 2rem;
    }
  `
);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ViewEmploymentHistoryDetails(props) {
  const [recordId, setrecordId] = useState("");
  const [contact2FormData, setContact2FormData] = useState({});
  const getcontactId = useSelector((state) => state.contactid);
  let query = useQuery();
  const dispatch = useDispatch();
  const userEntitlement = useSelector((state) => state.entitleMents);

  let formDataa = {};
  const { enableLoader } = usePreLoader();

  const classes = useStyles();
  const history = useHistory();

  const options = {
    widgetType: "update",
    formSchema: getEmploymentHistoryDetailsSchema,
    uiSchema: viewEmploymentHistoryDetailsUiSchema,
    entityType: "ctpt_professionalhistories",
    button1Label: "Save",
    button2Label: "Go Back",
    apiURL: config.teleportal.submitForm.Update,
    payloadSubmit: employmentDetailsPayload,
    goBackPage: "/profile/employmenthistory",
    historyUrl: "",
    button2GoBack: true,
    columns: [],
    contactId: getcontactId,
    formData: contact2FormData,
    hasModel: false,
    recordId: recordId,
    validate: customValidate,
  };

  const [contactOptions, setContactOptions] = useState(options);

  useEffect(() => {
    if (window.location.href.indexOf("?") > -1) {
      setrecordId(query.get("id"));
    }
  }, []);

  useEffect(() => {
    let payload = {
      ...getEmploymentDetailsPayload,
      filters: [
        {
          field: "ctpt_professionalhistoryid",
          searchvalue: recordId,
          type: "guid",
        },
      ],
    };

    getRecordDetails(payload);
  }, [recordId]);

  const updateFormData = (entityType, formData) => {
    options.formData = formData;
    options.initialFormData = formData;
    setContactOptions(options);
    setContact2FormData(formData);
  };

  const getRecordDetails = (payload) => {
    let api = config.teleportal.submitForm.getId;
    if (recordId !== "") {
      enableLoader(true, "Loading Employment History");
      post(api, payload)
        .then((res) => {
          res.data.data.forEach((item) => {
            console.log(item);
            formDataa[item.name] = item.value;
          });
          updateFormData({}, formDataa);
        })
        .catch(console.log)
        .finally(() => {
          enableLoader(false);
        });
    }
  };
  //console.log("CCONS:", contact2FormData);

  const getResponse = (res) => {
    if (res.data.id) {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Your Employment History Updated successfully",
          code: "",
          display: "show",
          severity: "success",
        },
      });
    } else {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Employment update failed",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    }
  };

  return (
    <Grid container spacing={0} justify="center">
      <Grid item xs={12} md={9} spacing={10}>
        <StyledFormWidget
          options={contactOptions}
          updateFormData={updateFormData}
          successResponse={getResponse}
        />
      </Grid>
    </Grid>
  );
}

export default ViewEmploymentHistoryDetails;
