export const IdentityUpdatePayload = (JsonData) => {
    const details = JsonData.data;
    var payload;
    payload = {
      id: JsonData.id,
      recordtype: JsonData.recordtype,
      data: [
        {
          name: "ctpt_identitynumber",
          value: details[0].value,
          type: "string",
        },
        {
            name: "ctpt_ContactId",
            value: JsonData.contactid,
            type: "lookup",
            relatedentity: "contacts"
            
          },
        {
          name: "ctpt_type",
          value: details[1].value,
          type: "string",
        },
      ],
    };
    return payload;
  };
