export const getorganisationlistPayload = (contactid) => {
  var payload;
  payload = {
    properties: [
      // "_ctpt_employeeid_value",
      // "_ctpt_employerid_value",
      // "ctpt_jobdescription",
      // "ctpt_jobtitle",
      // "ctpt_startdate",
      // "ctpt_enddate",
      // "ctpt_typeofemployment",
      // "ctpt_professionalhistoryid"
      "_ctpt_organisationname_value",
      "ctpt_otherorganisationname",
      "_ctpt_organisationcountry_value",
      "_ctpt_registrationcategory_value",
      "ctpt_otherregistrationcategory",
      "ctpt_registrationnumber",
      "ctpt_organisationhistoryid",
      "ctpt_name",
    ],
    formattedFields: [
      "_ctpt_organisationname_value",
      "_ctpt_organisationcountry_value",
      "_ctpt_registrationcategory_value",
    ],
    filter: {
      globalfilter: "",
      globalAndOperator: false,
      fieldAndOperator: false,
      filterfields: [
        {
          field: "_ctpt_applicant_value",
          searchvalue: contactid,
          type: "guid",
        },
      ],
    },
    recordtype: "ctpt_organisationhistories",
    idfield: "ctpt_organisationhistoryid",
    continuationToken: "",
    pazesize: "10",
  };
  return payload;
};

export const getProfessionalOrganisationPayload = {
  properties: [
    "ctpt_organisationhistoryid",
    "_ctpt_organisationname_value",
    "ctpt_otherorganisationname",
    "_ctpt_organisationcountry_value",
    "_ctpt_registrationcategory_value",
    "ctpt_otherregistrationcategory",
    "ctpt_yearofelection",
    "ctpt_registrationnumber",
    "_ctpt_applicant_value",
  ],
  formattedFields: [],
  recordType: "ctpt_organisationhistories",
  // id: contactid,

  FieldAndOperator: "true",
  idfield: "contactid",
};

export const organisationDetailsPayload = (JsonData) => {
  const details = JsonData.data;
  var payload;
  payload = {
    id: JsonData.recordId,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "ctpt_Applicant",
        value: JsonData.contactid,
        type: "lookup",
        relatedentity: "contacts",
      },
      {
        name: "ctpt_OrganisationName",
        value: details[0].value._ctpt_organisationname_value || "",
        type: "lookup",
        relatedentity: "ctpt_organisations",
      },
      {
        name: "ctpt_otherorganisationname",
        value:
          details[0].value._ctpt_organisationname_value ==
          "342136ea-c8fb-ec11-82e5-00224881f1d4"
            ? details[0].value.ctpt_otherorganisationname
            : "",
        type: "string",
      },
      {
        name: "ctpt_OrganisationCountry",
        value: details[1].value || "",
        type: "lookup",
        relatedentity: "ctpt_countries",
      },
      {
        name: "ctpt_RegistrationCategory",
        value: details[2].value._ctpt_registrationcategory_value || "",
        type: "lookup",
        relatedentity: "ctpt_registrationcategories",
      },
      {
        name: "ctpt_otherregistrationcategory",
        value:
          details[2].value._ctpt_registrationcategory_value ==
          "959698ba-c9fb-ec11-82e5-00224881f7ec"
            ? details[2].value.ctpt_otherregistrationcategory
            : "",
        type: "string",
      },
      {
        name: "ctpt_yearofelection",
        value: details[3].value || "",
        type: "string",
      },
      {
        name: "ctpt_registrationnumber",
        value: details[4].value || "",
        type: "string",
      },
    ],
  };
  return payload;
};
