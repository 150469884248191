const { isValidSouthAfricanId } = require("../../../../utilities/validation");

const IS_STRING = /^[A-Za-z' '][a-zA-Z]*$/;
const IS_CAPITALIZED = /^[A-Z]/;
const CONTAIN_CAPITAL_LETTER = /[A-Z]/;
const southAfricanIdDropdownValue = "100000001";

const fieldsNeedNameValidation = [
  { containerName: "personalDetails", fieldName: "title" },
  { containerName: "personalDetails", fieldName: "surname" },
  { containerName: "personalDetails", fieldName: "firstName" },
  { containerName: "personalDetails", fieldName: "secondName" },
  { containerName: "nextOfKin", fieldName: "surname" },
  { containerName: "nextOfKin", fieldName: "firstName" },
  { containerName: "nextOfKin", fieldName: "secondName" },
  { containerName: "contactDetails", fieldName: "mobileNumber" },
  { containerName: "contactDetails", fieldName: "altContactNumber" },
];

const validateName = (fieldContainer, fieldName, errors) => {
  const containMultipleCapitals = CONTAIN_CAPITAL_LETTER.test(
    fieldContainer?.[fieldName]?.substring(1)
  );
  if (
    fieldContainer?.[fieldName] &&
    errors?.[fieldName] &&
    IS_STRING.test(fieldContainer[fieldName]) &&
    (!IS_CAPITALIZED.test(fieldContainer[fieldName]) || containMultipleCapitals)
  ) {
    errors[fieldName].addError(
      containMultipleCapitals
        ? "Only first letter should be capital"
        : "First letter of string must be capital letter"
    );
  }
};

const validateIfContactNumbersAreSame = (
  fieldContainer,
  fieldNames,
  errors
) => {
  if (!fieldContainer) {
    return errors;
  }
  if (
    fieldContainer[fieldNames[0]] &&
    fieldContainer[fieldNames[0]] === fieldContainer[fieldNames[1]]
  ) {
    return errors[fieldNames[1]].addError(
      "The alternate phone number cannot be the same as applicant phone/mobile number"
    );
  }
};

const validateNextOfKinEmailWithContactDetails = (formData, errors) => {
  if (formData["nextOfKin"]) {
    localStorage.setItem("nextOfKin", JSON.stringify(formData["nextOfKin"]));
    return;
  }

  if (formData["contactDetails"]) {
    let kinMobileNumber = localStorage.getItem("nextOfKin")
      ? JSON.parse(localStorage.getItem("nextOfKin")).mobileNumber
      : "";
    let kinEmail = localStorage.getItem("nextOfKin")
      ? JSON.parse(localStorage.getItem("nextOfKin")).email
      : "";
    if (formData["contactDetails"].mobileNumber == kinMobileNumber) {
      errors["contactDetails"]["mobileNumber"].addError(
        "The mobile number cannot be the same as next of kin's contact number"
      );
    }
    if (formData["contactDetails"].email === kinEmail) {
      errors["contactDetails"]["email"].addError(
        "The email address cannot be the same as next of kin's email address"
      );
    }
    return errors;
  }
};

const validateSouthAfricanId = (formData, errors) => {
  const idType = formData?.["typeOfIdentity"];
  const idNumber = formData?.["citizenid"];

  // If the south african id is not selected don't apply validation
  if (idType !== southAfricanIdDropdownValue) return errors;

  const error = isValidSouthAfricanId(idNumber);
  if (error) {
    errors?.["citizenid"].addError(error);
  }

  return errors;
};



export const customValidate = (formData, errors) => {
  if (errors.nextOfKin && errors.personalDetails) {
    fieldsNeedNameValidation.forEach(({ containerName, fieldName }) => {
      validateName(formData[containerName], fieldName, errors[containerName]);
    });
  }

  validateIfContactNumbersAreSame(
    formData["contactDetails"],
    ["mobileNumber", "altContactNumber"],
    errors["contactDetails"]
  );
  validateNextOfKinEmailWithContactDetails(formData, errors);
  
  validateSouthAfricanId(
    formData?.["citizenofsaConditional"],
    errors?.["citizenofsaConditional"]
  );

  return errors;
};
