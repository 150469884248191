import Upload from "app/components/atoms/upload";
import CustomTextBox from "app/components/atoms/customTextBox";
import CustomCheckBox from "app/components/atoms/customCheckBox";
import CustomRadio from "app/components/atoms/customRadio";
import CustomSelect from "app/components/atoms/customSelect";

const componentType = {
  upload: {
    "ui:widget": (props) => {
      return (
        <Upload
          recordType={props.schema.containerName}
          recordPath={props.schema.recordPath}
          getProps={props}
        />
      );
    },
  },
};

export const uiSchema_nonaudit = {
  "ui:title": "",
  fullName: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  physicalAddress: {
    phyLine1: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    phyLine2: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    phycity: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    phydistrict: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    phyPostalCode: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    phyCountry: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    }
  },
  postalAddress: {
    line1: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    line2: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    city: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    district: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    postalCode: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    country: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    }
  },
  complianceOfficer: {
    coFirstName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    coLastName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    coEmailAddress: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    coPhoneNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    cellPhoneNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  partnerInAccountancy: {
    firstName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    lastName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    membershipNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    addAnotherPartnerInAccountancy: {
      items: {
        firstName: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        lastName: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        membershipNumber: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
      },
    },
  },
  partnerNotInAccountancy: {
    firstName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    lastName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    idNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    uploadId: componentType.upload,
    addAnotherPartnerNotInAccountancy: {
      items: {
        firstName: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        lastName: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        idNumber: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        uploadId: componentType.upload,
      },
    },
  },
  beneficialOwnerConditional: {
    beneficialOwnerRadio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    boFirstName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    boLastName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    boIdNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    boUploadId: componentType.upload,
    addAnotherBeneficialOwner: {
      items: {
        boFirstName: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        boLastName: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        boIdNumber: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        boUploadId: componentType.upload,
      },
    },
  },
  professionalAccounting: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  isSecretarialServices: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  fitQuestionaire: componentType.upload,
  professionalIndemnity: componentType.upload,
  disciplinaryProvisions: componentType.upload,
  passport: componentType.upload,
  policeClearance: componentType.upload,
  incorporationCertificate: componentType.upload,
  authorisingSignature: componentType.upload,
  shareHolderCertificate: componentType.upload,
  cv: componentType.upload,
  qualityControlManual: componentType.upload,
  trainingProgram: componentType.upload,
  fia: componentType.upload,
  acknowledgementCheckBox: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  fnp1Conditional: {
    fnp1Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp1Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp2Conditional: {
    fnp2Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp2Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp3: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  fnp4Conditional: {
    fnp4Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp4Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp5Conditional: {
    fnp5Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp5Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp6Conditional: {
    fnp6Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp6Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp7Conditional: {
    fnp7Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp7Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp8Conditional: {
    fnp8Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp8Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp9Conditional: {
    fnp9Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp9Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp10Conditional: {
    fnp10Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp10Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp11Conditional: {
    fnp11Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp11Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp12Conditional: {
    fnp12Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp12Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp13Conditional: {
    fnp13Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp13Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
};
