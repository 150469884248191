//Please note new menu structure MUST BE ONLY ONE LEVEL!

console.log(process.env.NODE_ENV);

export const appMenuItems = [
  {
    name: "Applications",
    items: [
      { name: "Manage Existing Applications", link: "/applications" },

      {
        name: "New Student Application​",
        link: "/application/studentApplication",
      },
      {
        name: "Associate Member Application",
        link: "/application/associateMemberApplication",
      },
      {
        name: "Corporate Member Application",
        link: "/application/corporateMemberApplication",
      },
    ],
  },
  {
    name: "Membership",
    items: [
      { name: "Upgrade", link: "/members/upgrade" },
      // {
      //   name: "Request for Member Certificate​",
      //   link: "/application/duplicateCertificate",
      // },
      // {
      //   name: "Request a Duplicate Certificate​",
      //   link: "/application/duplicateCertificate",
      // },
      // { name: "Request a Letter of Good Standing​", link: "/members/letter" },
      // {
      //   name: "Apply for Clearance",
      //   link: "/application/clearanceCertificate",
      // },
      // {
      //   name: "Request a Support Service​",
      //   link: "/membership/service-requests",
      // },
      // {
      //   name: "Training Agreement",
      //   link: "/membership/student-training-agreement",
      // },
      {
        name: "Resignation",
        link: "/membership/resignationApplication",
      },
      {
        name: "Fee Exemption",
        link: "/membership/feeExemption",
      },
      {
        name: "Fee Reduction",
        link: "/membership/feeReduction",
      },
      {
        name: "Retirement",
        link: "/membership/retirement",
      },
    ],
  },
  {
    name: "Account",
    items: [
      {
        name: "Invoices & Payments",
        link: "/invoices",
      },
      // {
      //   name: "Student Placement",
      //   link: "/student-placement",
      // },
    ],
  },
  {
    name: "Service Requests",
    items: [
      {
        name: "All Service Requests",
        link: "/queries",
      }
    //   // {
    //   //   name: "Student Placement",
    //   //   link: "/student-placement",
    //   // },
    ],
  },
  // {
  //   name: "Registration",
  //   items: [
  //     {
  //       name: "Event Registrations​",
  //       link: "/event/registration",
  //     },
  //     // { name: "Exam Registration​", link: "/exam/register" },
  //     // {
  //     //   name: "Exam Reports​",
  //     //   link: "/exam/reports",
  //     // },
  //   ],
  // },
  // {
  //   name: "Development (CPD)​",
  //   items: [{ name: "Manage Declarations​", link: "/cpd/declaration" }],
  // },
];
