import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { config } from "utilities/config";
import {
  getFormschemapayload,
  createFormsubmitpayload,
  getFormsubmitpayload,
} from "data/payload";
import Sonicwebform from "app/components/mvp/sonicwebform";
import { uiSchema } from "./model/uiSchema";
import styled from "styled-components";
import { Button, Grid } from "@material-ui/core";
import CreateOrder from "brand/components/order";
import { break_addressbar } from "utilities/splitUrl";
import { customValidate } from "./model/validations";
import { useSelector } from "react-redux";
import { checkOpenApp } from "utilities/checkOpenApp";
import { CAPS_LOCK_WARNING } from "constants/settings";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 10rem;
    max-width: 65%;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
    max-width: 100%;
  }
`;

function UpgradeAssociate(props) {
  const [isStarted, setStartApplication] = useState(0);
  const [isNew, setisNew] = useState(true);
  const [urlParam, seturlParam] = useState({
    formName: "upgradeAssociateApplication",
    subId: "",
  });
  const options = {
    formTitle: "Membership Upgrade Application",
    getFormschemaURL: config.sonicwebform.getFormschema,
    createFormsubmitURL: config.sonicwebform.createFormsubmit,
    getFormsubmitURL: config.sonicwebform.getFormsubmit,
    getFormschemapayload: getFormschemapayload,
    createFormsubmitpayload: createFormsubmitpayload,
    getFormsubmitpayload: getFormsubmitpayload,
    WebFormName: urlParam.formName,
    uiSchema: uiSchema,
    submissionId: urlParam.subId,
    stepCount: 0,
    formschema: {},
    swfdata: {},
    swfId: "",
    createForm: isNew,
    unAuthenticate: false,
    handleChange: props.handleChange,
    emptyOrder: false,
    hasPayment: false,
    validate: customValidate,
    defaultSectionAdditionalDesc: CAPS_LOCK_WARNING,
  };
  const getcontactId = useSelector((state) => state.contactid);
  const [isOpen, setOpenApplication] = useState(0);

  const startApp = () => {
    setStartApplication(1);
  };
  useEffect(() => {
    const fetchDetail = async () => {
      const isCheck = await checkOpenApp("upgradeAssociateApplication", getcontactId);
      setOpenApplication(isCheck);
    };
    localStorage.setItem("oid", "");
    console.log("onload " + window.location.href.indexOf("?"));
    if (window.location.href.indexOf("?") > -1) {
      seturlParam(break_addressbar(window.location.href));
      console.log(break_addressbar(window.location.href));
      setisNew(false);
      setOpenApplication(2);
    } else {
      setisNew(true);
      fetchDetail().catch(console.log);
    }
  }, []);

  useEffect(() => {
    console.log("url parameters" + JSON.stringify(urlParam));
  }, [urlParam]);

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div id="cTTP_sonicForm">
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              {/* <Sonicwebform options={options} /> */}
              {isOpen == 2 && (
                <div>
                  {isStarted === 0 ? (
                    <StartPage>
                      <h1>
                        Membership application upgrade from Associate Member to
                        Corporate Member
                      </h1>
                      <p
                        style={{
                          maxWidth: "70%",
                          fontWeight: 600,
                          marginTop: "2rem",
                        }}
                      >
                        A Corporate Member is a person who at the time of
                        admission:
                      </p>
                      <ul>
                        <li>Is actively engaged in civil engineering;</li>
                        <li>
                          Either holds an academic qualification from a tertiary
                          educational institution recognised for this purpose by
                          Council or is a corporate member of an engineering
                          institution or society recognised for this purpose by
                          Council; and
                        </li>
                        <li>
                          Is professionally registered with a statutory council
                          or international body recognised for this purpose by
                          Council.
                        </li>
                        <ol>
                          <h4>
                            For an applicant to apply for grade of Member,
                            he/she must be professionally registered in terms of
                            one of the following:
                          </h4>
                          <li>
                            Engineering Profession Act, 2000 (Act No. 46 of
                            2000), ECSA
                          </li>
                          <li>
                            Project and Construction Management Act, (no. 48 of
                            2000), SACPCMP
                          </li>
                          <li>
                            Professional and Technical Surveyors Act, (No. 44 of
                            1984), PLATO
                          </li>
                          <li>
                            South African Council for the Natural Scientific
                            Professions Act, (No. 27 of 2004), SACNASP
                          </li>
                          <li>
                            South African Council for Planners in terms of the
                            Planning Profession Act, (No. 36 of 2002) SACPLAN
                          </li>
                          <li>Engineering Council (UK), ICE</li>
                          <li>
                            Washington, Sydney, Dublin accord recognised
                            institutions.
                          </li>
                          <li>
                            Organisations holding provisional status that have
                            been identified as having qualification
                            accreditation or recognition procedures that are
                            potentially suitable for the purposes of the Accord.
                          </li>
                        </ol>
                      </ul>
                      <p
                        style={{
                          maxWidth: "70%",
                          fontWeight: 600,
                          marginTop: "2rem",
                        }}
                      >
                        Kindly NOTE that you will be required to attach the
                        following documents during the application.
                      </p>
                      <ul style={{ maxWidth: "70%", paddingLeft: "1rem" }}>
                        <li style={{ margin: 0, padding: 0 }}>
                          Copy of your ID/Passport.
                        </li>
                        <li>Copy of your Qualifications.</li>
                        <li>
                          Proof of registration with Professional Organisation.
                        </li>
                      </ul>
                      <br />
                      <br />
                      <div style={{ marginLeft: "1.8rem" }}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={startApp}
                        >
                          {isNew ? "Start Application" : "Resume Application"}
                        </Button>
                      </div>
                    </StartPage>
                  ) : (
                    <div>
                      <Sonicwebform options={options} />
                    </div>
                  )}
                </div>
              )}
              {isOpen == 1 && (
                <StartPage>
                  <h3>
                    Please go to{" "}
                    <a href="/applications">Manage Existing Applications</a> and
                    continue with your existing application.
                  </h3>
                </StartPage>
              )}
            </Grid>
          </Grid>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default CreateOrder(UpgradeAssociate);
