import React from "react";
import styled from "styled-components";
import Cluster from "layout/Cluster";
import { globalTheme } from "style/globalTheme";
import PhoneIcon from "@material-ui/icons/Phone";
import PrintIcon from "@material-ui/icons/Print";
import EmailIcon from "@material-ui/icons/Email";
import DraftsIcon from "@material-ui/icons/Drafts";
import FacebookIcon from "@material-ui/icons/Facebook"

const Container = styled.div`
  width: auto;
  margin-top: 2rem;
  ul {
    text-decoration: none;
    margin: 0;
    padding: 0;
  }
  li {
    text-decoration: none;
    margin: 0;
    padding: 0;
  }
  .center {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
  }

  ${globalTheme.breakpoints.down("sm")} {
    flex-basis: 40%;
  }

  .ftrHeader {
    font-family: "poppins";
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
`;

const FooterSections = ({ sectionList }) => (
  <Cluster justify="evenly">
    {sectionList.map(({ title, items }) => (
      <Container key={title}>
        <p className="ftrHeader">{title}</p>
        <ul>
          {items.map(({ labelText, linkUrl }) => (
            <li key={labelText}>
              {linkUrl ? (
                <>
                  <a href={linkUrl} target="_blank" key={labelText} rel="noreferrer">
                    {labelText}
                  </a>
                </>
              ) : (
                labelText
              )}
            </li>
          ))}
        </ul>
      </Container>
    ))}
    <Container>
      <p className="ftrHeader">Contact Us</p>
      <ul>
        <li className="center" key="telephone">
          <PhoneIcon sx={{color: "white"}} fontSize="small" />
          <span style={{ paddingLeft: "5px" }}>T: (011) 805-5947/48/53</span>
        </li>
        <li className="center" key="email">
          <EmailIcon sx={{color: "white"}} fontSize="small" />
          <span style={{ paddingLeft: "5px" }}>E: civilinfo@saice.org.za</span>
        </li>
        <li className="center" key="address">
          <DraftsIcon sx={{color: "white"}} fontSize="small" />
          <span style={{ paddingLeft: "5px" }}>
            Private Bag X200, Halfway House, 1685
          </span>
        </li>
      </ul>
    </Container>
    <Container>
      <p className="ftrHeader">Visit Us</p>
      <ul>
        <li key="time">
          Open 8am to 5pm, Monday <br />
          to Friday
        </li>
        <li key="add">
          SAICE House, Block 19, <br />
          Thornhill Office Park, <br />
          84 Bekker Road, Vorna Valley,
          <br />
          Midrand, 1686
        </li>
      </ul>
    </Container>
  </Cluster>
);

export default FooterSections;
