import { Button } from "@material-ui/core";
import { InputLabel, TextField } from "@material-ui/core";
import SWFField from "app/components/atoms/swfField/SWFField.mui";
import React from "react";
import { useStyles } from "../style";
function PayThroughSponsor({
  fullName,
  email,
  companyName,
  vatNumber,
  addressLine1,
  addressLine2,
  suburb,
  City,
  postalCode,
  //country,
  emailRegex,
  emailError,
  finishPayment,
  cancelPayment,
  handleInputChange,
}) {
  const classes = useStyles();

  return (
    <div class={classes.makePayment}>
      <p>
        If you are being sponsored, please provide contact details for your
        sponsor using the boxes below. This will be reviewed by our membership
        team before your application is approved.
      </p>
      <p>
        <b style={{ fontWeight: 700, color: "red" }}>PLEASE NOTE</b> that if you
        have paid through sponsorship your invoices may show as due until we
        have verified your payment.
      </p>
      <SWFField gap="small">
        <InputLabel>Company Name :</InputLabel>
        <TextField
          onChange={(event) => handleInputChange(event)}
          value={companyName}
          id="companyName"
        />
        <InputLabel>Billing Contact Person :</InputLabel>
        <TextField
          onChange={(event) => handleInputChange(event)}
          value={fullName}
          id="fullName"
        />
        <InputLabel>VAT Number :</InputLabel>
        <TextField
          onChange={(event) => handleInputChange(event)}
          value={vatNumber}
          id="vatNumber"
        />
        <InputLabel>Billing Email Address :</InputLabel>
        <TextField
          onChange={(event) => handleInputChange(event)}
          value={email}
          id="email"
          error={
            email?.length && email.length > 0
              ? email.match(emailRegex)
                ? false
                : true
              : false
          }
          helperText={emailError}
        />
        <InputLabel>Address Line 1 :</InputLabel>
        <TextField
          onChange={(event) => handleInputChange(event)}
          value={addressLine1}
          id="addressLine1"
        />
        <InputLabel>Address Line 2 :</InputLabel>
        <TextField
          onChange={(event) => handleInputChange(event)}
          value={addressLine2}
          id="addressLine2"
        />
        <InputLabel>Suburb :</InputLabel>
        <TextField
          onChange={(event) => handleInputChange(event)}
          value={suburb}
          id="suburb"
        />
        <InputLabel>City :</InputLabel>
        <TextField
          onChange={(event) => handleInputChange(event)}
          value={City}
          id="City"
        />
        <InputLabel>Postal Code :</InputLabel>
        <TextField
          onChange={(event) => handleInputChange(event)}
          value={postalCode}
          id="postalCode"
        />
        {/* <InputLabel>Country :</InputLabel>
        <TextField
          onChange={(event) => handleInputChange(event)}
          value={country}
          id="country"
        /> */}
      </SWFField>
      <div className={classes.buttonSection}>
        <Button
          color="primary"
          variant="outlined"
          onClick={finishPayment}
          className={classes.finishBtn}
          disabled={
            fullName === "" ||
            email === "" ||
            emailError !== "" ||
            companyName === "" ||
            vatNumber === "" ||
            addressLine1 === "" ||
            City === "" ||
            postalCode === ""
          }
        >
          Continue to next step
        </Button>
        <Button color="primary" variant="outlined" onClick={cancelPayment}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default PayThroughSponsor;
