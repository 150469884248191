import Upload from "app/components/atoms/upload";
import CustomTextBox from "app/components/atoms/customTextBox";
import CustomRadio from "app/components/atoms/customRadio";
import CustomDate from "app/components/atoms/customDate";
import CustomSelect from "app/components/atoms/customSelect";

const CustomUpload = ({ getProps }) => {
  return (
    <Upload
      recordType={getProps.schema.containerName}
      recordPath={getProps.schema.recordPath}
      getProps={getProps}
    />
  );
};

export const uiSchema = {
  "ui:title": "",
  institutionName: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  physicalAddress: {
    line1: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    line2: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    city: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    district: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    postalCode: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    country: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  contactInformation: {
    telephone: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    emailAddress: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    fax: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    web: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  branchPhysicalAddress: {
    branchName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    line1: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    line2: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    city: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    district: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    postalCode: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    country: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  branchPostaladdress: {
    line1: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    line2: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    city: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    district: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    postalCode: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    country: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  primaryContact: {
    firstName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    lastName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    telephone: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  addAnotherBranch: {
    items: {
      physicalAddress: {
        branchName: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        line1: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        line2: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        city: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        district: {
          "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
          },
        },
        postalCode: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        country: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
      },
      postaladdress: {
        line1: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        line2: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        city: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        district: {
          "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
          },
        },
        postalCode: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        country: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
      },
      primaryContact: {
        firstName: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        lastName: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        telephone: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
      },
    },
  },
  institutionCategory: {
    institutionCategory: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    institutionCategoryName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    name: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    addAnotherName: {
      items: {
        name: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
      },
    },
  },
  ministryName: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  dateOfRegistration: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  registrationCertificate: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  regulatoryAuthorities: {
    registrationCertificate: {
      "ui:widget": (props) => {
        return <CustomUpload getProps={props} />;
      },
    },
    dateOfRegistration: {
      "ui:widget": (props) => {
        return <CustomDate getProps={props} />;
      },
    },
  },
  addAnotherRegistration: {
    items: {
      registrationCertificate: {
        "ui:widget": (props) => {
          return <CustomUpload getProps={props} />;
        },
      },
      dateOfRegistration: {
        "ui:widget": (props) => {
          return <CustomDate getProps={props} />;
        },
      },
    },
  },
  nameOfHeadOfInstitution: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  headOfInstituteAcademic: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  docOrgStructure: {
    docOrgStructure: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    orgStructureDocAttach: {
      "ui:widget": (props) => {
        return <CustomUpload getProps={props} />;
      },
    },
  },
  orgStructureExplained: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  cityName: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  addAnotherCityName: {
    items: {
      cityName: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
    },
  },
  responsibilityDocumented: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  designatedAcademicOfficer: {
    designatedAcademicOfficer: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    brieflyExplain: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  evidenceOfCredsAndDegrees: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  governanceDocumented: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  department: {
    departmentName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    permanentStaffCount: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    partTimeStaffCount: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    totalStaff: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    addAnotherDeptInfo: {
      items: {
        departmentName: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        permanentStaffCount: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        partTimeStaffCount: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        totalStaff: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
      },
    },
    qualificationExperiance: {
      "ui:widget": (props) => {
        return <CustomUpload getProps={props} />;
      },
    },
    academicFreedom: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    attractOrRetainGoodEmployee: {
      attractOrRetainGoodEmployee: {
        "ui:widget": (props) => {
          return <CustomRadio getProps={props} />;
        },
      },
      brieflyExplain: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
    },
    empInAdministrativeDecision: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    grievancePolicies: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    brieflyExplain: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  independentFinanceAudit: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  financeStamentsMaintained: {
    financeStamentsMaintained: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    auditedFinanceStatements: {
      "ui:widget": (props) => {
        return <CustomUpload getProps={props} />;
      },
    },
  },
  annualBudgetsMonitored: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  institutionHasDeficitYears: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  financialStability: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  admissionPolicyDisclosed: {
    admissionPolicyDisclosed: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    copyOfRecruitmentProcedure: {
      "ui:widget": (props) => {
        return <CustomUpload getProps={props} />;
      },
    },
  },
  admissionRecordsMaintained: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  recruitmentComplianceWithBICA: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  admissionDecisionsKnowledgeable: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  financialRecordsClear: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  isFeesToStudentsConsistent: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  refundPolicyStated: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  trainers: {
    staffQualification: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    addAnotherTrainer: {
      items: {
        staffQualification: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
      },
    },
    instructorEvaluated: {
      instructorEvaluated: {
        "ui:widget": (props) => {
          return <CustomRadio getProps={props} />;
        },
      },
      copyOfEvaluationForm: {
        "ui:widget": (props) => {
          return <CustomUpload getProps={props} />;
        },
      },
    },
    classObservationUsed: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    feedbackProvidedToTrainers: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    generalEffectivenessOfTrainersEvaluation: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  instructions: {
    instructionalMethodsAppropriate: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    instructionalMeterialsAppropriate: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    instructionalMaterialProportionate: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    instructorsHaveAcademicCreds: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
  },
  studentLearningOutcomes: {
    systemToEvaluateStudent: {
      systemToEvaluateStudent: {
        "ui:widget": (props) => {
          return <CustomRadio getProps={props} />;
        },
      },
      brieflyExplain: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
    },
    assignmentsCompulsoryForAllStudents: {
      assignmentsCompulsoryForAllStudents: {
        "ui:widget": (props) => {
          return <CustomRadio getProps={props} />;
        },
      },
      howAssessmentsEnforced: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
    },
    howDataCollected: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    howStaffInvolvedInProcess: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    providingQualityEducation: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
  },
  personInchargeOfStudentServices: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  studentLeadershipInitiative: {
    studentLeadershipInitiative: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    howStudentLeaderShipAchieved: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  guidenceProgram: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  careerAdvisoryServices: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  handleStudenComplaints: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  orientationProgram: {
    orientationProgram: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    howIsThisDone: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  recordsAndReportsHoused: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  studEvalPerfOfStaff: {
    studEvalPerfOfStaff: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    howFrequently: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    evaluationForm: {
      "ui:widget": (props) => {
        return <CustomUpload getProps={props} />;
      },
    },
  },
  currentAndPermanentRecord: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  supportToStudentsWithDisabilities: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  buildingsRented: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  attachEvidence: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  technologicalResourcesRented: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  conditionsConductiveForClassroom: {
    conditionsConductiveForClassroom: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    howTheyAreConductive: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  accessToTechnologicalResources: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  disasterRecoveryForTechnology: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  classrooms: {
    numberOfRooms: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    sizeInSqareMeters: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    seatingCapacity: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  library: {
    numberOfRooms: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    sizeInSqareMeters: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    seatingCapacity: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  computingRooms: {
    numberOfRooms: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    sizeInSqareMeters: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    seatingCapacity: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  lectureHalls: {
    numberOfRooms: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    sizeInSqareMeters: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    seatingCapacity: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  staffCommonRoom: {
    numberOfRooms: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    sizeInSqareMeters: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    seatingCapacity: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  libraryMeetStudentNeeds: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  libraryQualityAppropriate: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  libraryHasAppropriateMaterials: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  resourcesOrganised: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  librarianCompetent: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  libraryHasDevelopmentPlan: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  annualBudgetForLibrary: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  libraryProvideStudyFacility: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  equipmentForBICACurriculum: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  resourcesUptodate: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  additionalComments: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
};
