import { CAPS_LOCK_WARNING } from "constants/settings";

export const getProfessionalOrganisationSchema = {
  title: "Add Professional Organisation Details ",
  logicalname: "Contact2",
  type: "object",
  description: CAPS_LOCK_WARNING,
  properties: {
    organisationNameConditional: {
      $ref: "#/definitions/organisationNameCond",
    },
    _ctpt_organisationcountry_value: {
      title: "Organisation Country",
      type: "string",
      schemaname: "_ctpt_organisationcountry_value",
      datatype: "string",
      requiredCheck: true,
      isDynamic: true,
      apiURL: "/api/GetAllAsync",
      payload: {
        properties: ["ctpt_name", "ctpt_countryid", "ctpt_displayflag"],
        filter: {
          globalfilter: "",
          globalAndOperator: false,
          fieldAndOperator: true,
          filterfields: [
            {
              field: "statecode",
              searchvalue: 0,
              type: "guid",
            },
          ],
        },
        Aggregate: {
          AggregateFields: [],
        },
        sortfield: {
          field: "ctpt_name",
          sorttype: "asc",
        },
        recordtype: "ctpt_countries",
        idfield: "ctpt_countryid",
        continuationToken: "",
        pazesize: "1000",
      },
      enum: ["1"],
      enumNames: ["1"],
    },
    registrationCategoryConditional: {
      $ref: "#/definitions/registrationCategoryCond",
    },
    ctpt_yearofelection: {
      title: "Year of Election",
      type: "string",
      schemaname: "ctpt_enddate",
      datatype: "string",
      requiredCheck: true,
      pattern: "^[0-9]{4}$",
      message: "Enter correct year",
    },
    ctpt_registrationnumber: {
      title: "Registration Number",
      type: "string",
      schemaname: "ctpt_enddate",
      datatype: "string",
      requiredCheck: true,
    },
  },
  definitions: {
    organisationNameCond: {
      title: "",
      type: "object",
      properties: {
        _ctpt_organisationname_value: {
          title: "Organisation Name",
          type: "string",
          datatype: "array",
          requiredCheck: false,
          tootltipText:
            "Inline help text can appear in association with a field. It expands to show on selecting ?Help? and can be collapsed by selecting ?Close?",
          helpText: "",
          isDynamic: true,
          apiURL: "/api/GetAllAsync",
          payload: {
            properties: [
              "ctpt_organisationname",
              "ctpt_organisationid",
              "ctpt_displayflag",
            ],
            filter: {
              globalfilter: "",
              globalAndOperator: false,
              fieldAndOperator: true,
              filterfields: [
                {
                  field: "statecode",
                  searchvalue: 0,
                  type: "guid",
                },
              ],
            },
            Aggregate: {
              AggregateFields: [],
            },
            sortfield: {
              field: "ctpt_organisationname",
              sorttype: "asc",
            },
            recordtype: "ctpt_organisations",
            idfield: "ctpt_organisationid",
            continuationToken: "",
            pazesize: "1000",
          },
          enum: ["1"],
          enumNames: ["1"],
        },
      },
      dependencies: {
        _ctpt_organisationname_value: {
          oneOf: [
            {
              properties: {
                _ctpt_organisationname_value: {
                  enum: ["342136ea-c8fb-ec11-82e5-00224881f1d4"],
                },
                ctpt_otherorganisationname: {
                  title: "Specify",
                  type: "string",
                  requiredCheck: false,
                  readOnly: false,
                  makeReadOnly: true,
                  datatype: "string",
                  helpText: "",
                  defaultValue: "",
                  pattern: "",
                  message: "",
                },
              },
            },
          ],
        },
      },
    },
    registrationCategoryCond: {
      title: "",
      type: "object",
      properties: {
        _ctpt_registrationcategory_value: {
          title: "Registration Category",
          type: "string",
          datatype: "array",
          requiredCheck: false,
          tootltipText:
            "Inline help text can appear in association with a field. It expands to show on selecting ?Help? and can be collapsed by selecting ?Close?",
          helpText: "",
          isDynamic: true,
          apiURL: "/api/GetAllAsync",
          payload: {
            properties: [
              "ctpt_name",
              "ctpt_registrationcategoryid",
              "ctpt_displayflag",
            ],
            filter: {
              globalfilter: "",
              globalAndOperator: false,
              fieldAndOperator: true,
              filterfields: [
                {
                  field: "statecode",
                  searchvalue: 0,
                  type: "guid",
                },
              ],
            },
            Aggregate: {
              AggregateFields: [],
            },
            sortfield: {
              field: "ctpt_name",
              sorttype: "asc",
            },
            recordtype: "ctpt_registrationcategories",
            idfield: "ctpt_registrationcategoryid",
            continuationToken: "",
            pazesize: "1000",
          },
          enum: ["1"],
          enumNames: ["1"],
        },
      },
      dependencies: {
        _ctpt_registrationcategory_value: {
          oneOf: [
            {
              properties: {
                _ctpt_registrationcategory_value: {
                  enum: ["959698ba-c9fb-ec11-82e5-00224881f7ec"],
                },
                ctpt_otherregistrationcategory: {
                  title: "Specify",
                  type: "string",
                  requiredCheck: false,
                  readOnly: false,
                  makeReadOnly: true,
                  datatype: "string",
                  helpText: "",
                  defaultValue: "",
                  pattern: "",
                  message: "",
                },
              },
            },
          ],
        },
      },
    },
  },
};

export const getProfessionalOrganisationDetailsSchema = {
  title: "Edit Organisation Details",
  logicalname: "employmenthistory",
  type: "object",
  additionalDescription: CAPS_LOCK_WARNING,
  properties: {
    organisationNameConditional: {
      $ref: "#/definitions/organisationNameCond",
    },
    _ctpt_organisationcountry_value: {
      title: "Organisation Country",
      type: "string",
      schemaname: "_ctpt_organisationcountry_value",
      datatype: "string",
      requiredCheck: true,
      isDynamic: true,
      apiURL: "/api/GetAllAsync",
      payload: {
        properties: ["ctpt_name", "ctpt_countryid", "ctpt_displayflag"],
        filter: {
          globalfilter: "",
          globalAndOperator: false,
          fieldAndOperator: true,
          filterfields: [
            {
              field: "statecode",
              searchvalue: 0,
              type: "guid",
            },
          ],
        },
        Aggregate: {
          AggregateFields: [],
        },
        sortfield: {
          field: "ctpt_name",
          sorttype: "asc",
        },
        recordtype: "ctpt_countries",
        idfield: "ctpt_countryid",
        continuationToken: "",
        pazesize: "1000",
      },
      enum: ["1"],
      enumNames: ["1"],
    },
    registrationCategoryConditional: {
      $ref: "#/definitions/registrationCategoryCond",
    },
    ctpt_yearofelection: {
      title: "Year of Election",
      type: "string",
      schemaname: "ctpt_enddate",
      datatype: "string",
      requiredCheck: true,
      pattern: "^[0-9]{4}$",
      message: "Enter correct year",
    },
    ctpt_registrationnumber: {
      title: "Registration Number",
      type: "string",
      schemaname: "ctpt_enddate",
      datatype: "string",
      requiredCheck: true,
    },
  },
  definitions: {
    organisationNameCond: {
      title: "",
      type: "object",
      properties: {
        _ctpt_organisationname_value: {
          title: "Organisation Name",
          type: "string",
          datatype: "array",
          requiredCheck: false,
          tootltipText:
            "Inline help text can appear in association with a field. It expands to show on selecting ?Help? and can be collapsed by selecting ?Close?",
          helpText: "",
          isDynamic: true,
          apiURL: "/api/GetAllAsync",
          payload: {
            properties: [
              "ctpt_organisationname",
              "ctpt_organisationid",
              "ctpt_displayflag",
            ],
            filter: {
              globalfilter: "",
              globalAndOperator: false,
              fieldAndOperator: true,
              filterfields: [
                {
                  field: "statecode",
                  searchvalue: 0,
                  type: "guid",
                },
              ],
            },
            Aggregate: {
              AggregateFields: [],
            },
            sortfield: {
              field: "ctpt_organisationname",
              sorttype: "asc",
            },
            recordtype: "ctpt_organisations",
            idfield: "ctpt_organisationid",
            continuationToken: "",
            pazesize: "1000",
          },
          enum: ["1"],
          enumNames: ["1"],
        },
      },
      dependencies: {
        _ctpt_organisationname_value: {
          oneOf: [
            {
              properties: {
                _ctpt_organisationname_value: {
                  enum: ["342136ea-c8fb-ec11-82e5-00224881f1d4"],
                },
                ctpt_otherorganisationname: {
                  title: "Specify",
                  type: "string",
                  requiredCheck: false,
                  readOnly: false,
                  makeReadOnly: true,
                  datatype: "string",
                  helpText: "",
                  defaultValue: "",
                  pattern: "",
                  message: "",
                },
              },
            },
          ],
        },
      },
    },
    registrationCategoryCond: {
      title: "",
      type: "object",
      properties: {
        _ctpt_registrationcategory_value: {
          title: "Registration Category",
          type: "string",
          datatype: "array",
          requiredCheck: false,
          tootltipText:
            "Inline help text can appear in association with a field. It expands to show on selecting ?Help? and can be collapsed by selecting ?Close?",
          helpText: "",
          isDynamic: true,
          apiURL: "/api/GetAllAsync",
          payload: {
            properties: [
              "ctpt_name",
              "ctpt_registrationcategoryid",
              "ctpt_displayflag",
            ],
            filter: {
              globalfilter: "",
              globalAndOperator: false,
              fieldAndOperator: true,
              filterfields: [
                {
                  field: "statecode",
                  searchvalue: 0,
                  type: "guid",
                },
              ],
            },
            Aggregate: {
              AggregateFields: [],
            },
            sortfield: {
              field: "ctpt_name",
              sorttype: "asc",
            },
            recordtype: "ctpt_registrationcategories",
            idfield: "ctpt_registrationcategoryid",
            continuationToken: "",
            pazesize: "1000",
          },
          enum: ["1"],
          enumNames: ["1"],
        },
      },
      dependencies: {
        _ctpt_registrationcategory_value: {
          oneOf: [
            {
              properties: {
                _ctpt_registrationcategory_value: {
                  enum: ["959698ba-c9fb-ec11-82e5-00224881f7ec"],
                },
                ctpt_otherregistrationcategory: {
                  title: "Specify",
                  type: "string",
                  requiredCheck: false,
                  readOnly: false,
                  makeReadOnly: true,
                  datatype: "string",
                  helpText: "",
                  defaultValue: "",
                  pattern: "",
                  message: "",
                },
              },
            },
          ],
        },
      },
    },
  },
};
