import CustomTextBox from "app/components/atoms/customTextBox";
import CustomDate from "app/components/atoms/customDate";
import CustomRadio from "app/components/atoms/customRadio";
import { useStyles } from "./styles";

const CustomUpload = ({ getProps }) => {
  const classes = useStyles();
  return getProps.value ? (
    <a
      href={getProps.value}
      className={classes.docLink}
      target="_blank"
      rel="noreferrer"
    >
      View CV &rarr;
    </a>
  ) : (
    <h3>No CV uploaded</h3>
  );
};

export const studentUiSchema = {
  "ui:title": "",
  salutation: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
    "ui:readonly": true,
  },
  firstname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  lastname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  fullname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  middlename: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  birthdate: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  fax: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_isresidentofbotswana: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  cv: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
};
