import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { Grid } from "@material-ui/core";
import { config } from "utilities/config";
import {
  getFormschemapayload,
  createFormsubmitpayload,
  getFormsubmitpayload,
} from "data/payload";
import Sonicwebform from "app/components/mvp/sonicwebform";
import { uiSchema } from "app/pages/corporatemember/model/uiSchema";
import styled from "styled-components";
import Button from "app/components/atoms/Button/RoundCornerButton";
import { break_addressbar } from "utilities/splitUrl";
import CreateOrder from "brand/components/order";
import CustomCheckBox from "app/components/atoms/customCheckBox/CustomCheckBox.mui";
import { customValidate } from "./model/validations";
import { useSelector } from "react-redux";
import { checkOpenApp } from "utilities/checkOpenApp";
import { CAPS_LOCK_WARNING } from "constants/settings";

const StartPage = styled.section`
  h1 {
    text-transform: uppercase;
  }
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 150px;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function Corporate(props) {
  const [isStarted, setStartApplication] = useState(0);
  const [isNew, setisNew] = useState(true);
  const [acceptCondition, setAcceptCondition] = useState(false);
  const [urlParam, seturlParam] = useState({
    formName: "corporateMemberApplication",
    subId: "",
  });
  const options = {
    formTitle: "Corporate member Application",
    getFormschemaURL: config.sonicwebform.getFormschema,
    createFormsubmitURL: config.sonicwebform.createFormsubmit,
    getFormsubmitURL: config.sonicwebform.getFormsubmit,
    getFormschemapayload: getFormschemapayload,
    createFormsubmitpayload: createFormsubmitpayload,
    getFormsubmitpayload: getFormsubmitpayload,
    WebFormName: urlParam.formName,
    uiSchema: uiSchema,
    submissionId: urlParam.subId,
    stepCount: 0,
    formschema: {},
    swfdata: {},
    swfId: "",
    createForm: isNew,
    unAuthenticate: false,
    handleChange: props.handleChange,
    emptyOrder: false,
    hasPayment: true,
    validate: customValidate,
    defaultSectionAdditionalDesc: CAPS_LOCK_WARNING,
  };
  const getcontactId = useSelector((state) => state.contactid);
  const [isOpen, setOpenApplication] = useState(0);

  const startApp = () => {
    setStartApplication(1);
  };
  useEffect(() => {
    const fetchDetail = async () => {
      const isCheck = await checkOpenApp("", getcontactId);
      setOpenApplication(isCheck);
    };
    localStorage.setItem("oid", "");
    console.log("onload " + window.location.href.indexOf("?"));
    if (window.location.href.indexOf("?") > -1) {
      seturlParam(break_addressbar(window.location.href));
      console.log(break_addressbar(window.location.href));
      setisNew(false);
      setOpenApplication(2);
    } else {
      setisNew(true);
      fetchDetail().catch(console.log);
    }
  }, []);

  useEffect(() => {
    //console.log("url parameters"+JSON.stringify(urlParam))
  }, [urlParam]);

  const onChangeCheckBoxState = (value) => {
    setAcceptCondition(value);
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div id="cTTP_sonicForm">
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              {/* <Sonicwebform options={options} /> */}
              {isOpen == 2 && (
                <div>
                  {isStarted == 0 ? (
                    <StartPage>
                      <h1>About the application</h1>
                      <br />

                      <h3>
                        PLEASE READ THE FOLLOWING CAREFULLY BEFORE COMPLETING
                        THIS APPLICATION FOR MEMBERSHIP:
                      </h3>
                      <p
                        style={{
                          maxWidth: "70%",
                          fontWeight: 600,
                          marginTop: "2rem",
                        }}
                      >
                        A Corporate Member is a person who at the time of
                        admission:
                      </p>
                      <ul>
                        <li>Is actively engaged in civil engineering;</li>
                        <li>
                          Either holds an academic qualification from a tertiary
                          educational institution recognised for this purpose by
                          Council or is a corporate member of an engineering
                          institution or society recognised for this purpose by
                          Council; and
                        </li>
                        <li>
                          Is professionally registered with a statutory council
                          or international body recognised for this purpose by
                          Council.
                        </li>
                      </ul>
                      <p
                        style={{
                          maxWidth: "70%",
                          fontWeight: 600,
                          marginTop: "2rem",
                        }}
                      >
                        For an applicant to apply for grade of Member, he/she
                        must be professionally registered in terms of one of the
                        following:
                      </p>
                      <ul>
                        <li>
                          Engineering Profession Act, 2000 (Act No. 46 of 2000),
                          ECSA
                        </li>
                        <li>
                          Project and Construction Management Act, (no. 48 of
                          2000), SACPCMP
                        </li>
                        <li>
                          Professional and Technical Surveyors Act, (No. 44 of
                          1984), PLATO
                        </li>
                        <li>
                          South African Council for the Natural Scientific
                          Professions Act, (No. 27 of 2004), SACNASP
                        </li>
                        <li>
                          South African Council for Planners in terms of the
                          Planning Profession Act, (No. 36 of 2002) SACPLAN
                        </li>
                        <li>Engineering Council (UK), ICE</li>
                        <li>
                          Washington, Sydney, Dublin accord recognised
                          institutions.
                        </li>
                        <li>
                          Organisations holding provisional status that have
                          been identified as having qualification accreditation
                          or recognition procedures that are potentially
                          suitable for the purposes of the Accord.
                        </li>
                      </ul>
                      <p
                        style={{
                          maxWidth: "70%",
                          fontWeight: 600,
                          marginTop: "2rem",
                        }}
                      >
                        Kindly NOTE that you will be required to attach the
                        following documents during the application.
                      </p>
                      <ul>
                        <li>
                          Certified copy of your ID/Passport (date of
                          certification must not exceed 12 months)
                        </li>
                        <li>
                          Certified copy of your qualifications (date of
                          certification must not exceed 12 months)
                        </li>
                        <li>
                          Proof of registration with Professional Organisations.
                        </li>
                      </ul>
                      <br />
                      <br />

                      <CustomCheckBox
                        getProps={{
                          id: "acceptCondition",
                          label:
                            "I have read and understand the conditions mentioned above",
                          value: acceptCondition,
                          onChange: onChangeCheckBoxState,
                          schema: { requiredCheck: false },
                        }}
                      />

                      <br />

                      <br />

                      <Button
                        color="primary"
                        variant="contained"
                        disabled={!acceptCondition}
                        onClick={startApp}
                      >
                        Start Application
                      </Button>
                    </StartPage>
                  ) : (
                    <div>
                      <Sonicwebform options={options} />
                    </div>
                  )}
                </div>
              )}
              {isOpen == 1 && (
                <StartPage>
                  <h3>
                    Please go to{" "}
                    <a href="/applications">Manage Existing Applications</a> and
                    continue with your existing application.
                  </h3>
                </StartPage>
              )}
            </Grid>
          </Grid>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default CreateOrder(Corporate);
