import { post } from "app/services/api";
import { config } from "utilities/config";

export const CreaterequestPayload = (JsonData) => {
  const details = JsonData.data;
  return {
    recordtype: "ctpt_professionalhistories",
    data: [
      {
        name: "ctpt_EmployeeId",
        value: JsonData.contactid,
        type: "lookup",
        relatedentity: "contacts",
      },
      // {
      //   name: "ctpt_jobdescription",
      //   value: details[0].value,
      //   type: "string",
      // },
      {
        name: "ctpt_jobtitle",
        value: details[0].value,
        type: "string",
      },
      {
        name: "ctpt_company",
        value: details[1].value,
        type: "string",
      },
      {
        name: "ctpt_typeofemployment",
        value: details[2].value,
        type: "picklist",
      },
      {
        name: "ctpt_startdate",
        value: details[3].value,
        type: "string",
      },
      {
        name: "ctpt_enddate",
        value: details[4].value,
        type: "string",
      },
    ],
  };
  //return payload
};
