import CustomTextBox from "app/components/atoms/customTextBox";
import CustomSelect from "app/components/atoms/customSelect";

export const postalAddressUiSchema = {
    "ui:title": "",
    ctpt_line1: {
        "ui:widget": props => {
            return <CustomTextBox getProps={props} />;
        }
    },
    ctpt_line2: {
        "ui:widget": props => {
            return <CustomTextBox getProps={props} />;
        }
    },
    ctpt_county: {
        "ui:widget": props => {
            return <CustomTextBox getProps={props} />;
        }
    },
    ctpt_city: {
        "ui:widget": props => {
            return <CustomTextBox getProps={props} />;
        }
    },
    ctpt_postalcode: {
        "ui:widget": props => {
            return <CustomTextBox getProps={props} />;
        }
    },
    ctpt_country: {
        "ui:widget": props => {
            return <CustomSelect getProps={props} />;
        }
    }

};
