//Import React Library
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { AuthenticatedTemplate } from "@azure/msal-react";

//Import Material-UI Library
import { ThemeProvider } from "@material-ui/core/styles";

//Loading Data and Style..
import { globalTheme } from "style/globalTheme";
import { useStyles } from "./style";
//Loading components
import { config } from "utilities/config";

import ListComponent from "app/components/mvp/listWidget";
// import { aggregatecolumns } from "./model/column";
// import { getcolumns } from "./model/column";
import { getemaillistPayload } from "./model/payload";
//import { getProfessionalOrganisationSchema } from "./model/schema";
//import { getProfessionalOrganisationUiSchema } from "./model/UiSchema";
//import { CreaterequestPayload } from "./model/createPayload";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { EMAIL_TYPE_MAP } from "./model/constants";
//import { customValidate } from "./model/validation";

function EmailUpdate(props) {
  const classes = useStyles();
  const getcontactId = useSelector((state) => state.contactid);
  const history = useHistory();
  const getcontactName = useSelector((state) => state.user);

  const onClickViewDetails = (params) => {
    window.location.href =
      "/profile/viewemaildetails?id=" + params.row.ctpt_channelid;
  };

  const columns = [
    { field: "ctpt_emailaddress", flex: 2, headerName: "Email Id" },
    {
      field: "ctpt_mapemailto",
      flex: 1,
      headerName: "Type",
      renderCell: (params) => (
        <p>
          {params.row.ctpt_mapemailto
            ? EMAIL_TYPE_MAP[params.row.ctpt_mapemailto]
            : ""}
        </p>
      ),
    },
    {
      field: "action",
      headerName: <p class={classes.paidListHeader}>Action</p>,
      flex: 1,
      renderCell: (params) => {
        return (
          <div class={classes.actionsButtons}>
            <Button
              variant="outlined"
              color="primary"
              className="primaryBtn"
              onClick={() => {
                onClickViewDetails(params);
              }}
              disabled={false}
            >
              View Details
            </Button>
          </div>
        );
      },
    },
  ];
  const options = {
    apiURL: config.teleportal.submitForm.get,
    //apiUrlDel: config.teleportal.file.delete,
    columns: columns,
    searchPayload: "searchFilter",
    getlistPayload: getemaillistPayload(getcontactId),
    // formwidgetOptions: {
    //   widgetType: "add",
    //   formSchema: getProfessionalOrganisationSchema,
    //   uiSchema: getProfessionalOrganisationUiSchema,
    //   apiURL: config.teleportal.submitForm.Create,
    //   entityType: "ctpt_organisationhistories",
    //   formUischema: "",
    //   button1Label: "Add",
    //   button2Label: "Cancel",
    //   button3Label: "Clear",
    //   payloadSubmit: CreaterequestPayload,
    //   columns: [],
    //   contactId: getcontactId,
    //   contactName: getcontactName.name,
    //   historyUrl: "",
    //   hasMultiplecall: true,
    //   hasModel: true
    //   //validate: customValidate,
    // },
    deleteListPayload: {},
    listHeading: "Email Id's",
    formWidget: {},
    nonRecord: false,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: false,
    filterField: "",
    filterValue: [],
    aggregateField: "",
    hideSearch: true,
    pageSize: 10,
    hasPayloadfilter: false,
    payloadModifyForbidden: true,
    hideAdd: true,
    controlLists: false,
  };

  ////////////
  // const options = {
  //   listDescription:
  //     "Employment History",
  //   apiURL: config.teleportal.submitForm.get,
  //   columns: getcolumns(),
  //   searchPayload: "searchFilter",
  //   getlistPayload: getlistPayload,

  //   deleteListPayload: {},
  //   listHeading: "",
  //   formWidget: {},
  //   nonRecord: false,
  //   role: role,
  //   rows: "",
  //   localDataStore: false,
  //   hideDelete: true,
  //   hasAggregate: false,
  //   staticTable: false,
  //   staticRow: [],
  //   hiddenColumn: {},
  //   contactId: getcontactId,
  //   filteredValue: "",
  //   hasFilter: false,
  //   filterField: "",
  //   filterValue: [],
  //   aggregateColumns: aggregatecolumns,
  //   aggregateField: "",
  //   hideSearch: true,
  //   pageSize: 10,
  //   hasPayloadfilter: true,
  //   hideAdd: false,
  //   controlLists: false,
  // };

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div id="cTTP_applicationList" class={classes.container}>
          <div class={classes.listSection}>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              onClick={() => {
                history.push("/profile");
              }}
              style={{ marginLeft: "1.5rem" }}
            >
              Go Back
            </Button>
            <ListComponent options={options} />
          </div>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default EmailUpdate;
