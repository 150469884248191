import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { branchSchema } from "./model/branchSchema.js";
import { branchUiSchema } from "./model/branchUiSchema.js";
import { BranchPayload } from "./model/payload.js";
import { config } from "utilities/config";
import { post } from "app/services/api";
import FormWidget from "app/components/mvp/formWidget";
import styled, { css } from "styled-components";

const StyledFormWidget = styled(FormWidget)(
  () => css`
    div[class="button-group"] {
      margin-top: 2rem;
    }
  `
);

function BranchUpdate(props) {
  const contactid = useSelector((state) => state.contactid);
  const [contactFormData, setContactFormData] = useState({});
  const dispatch = useDispatch();
  const userEntitlement = useSelector((state) => state.entitleMents);
  const userRole = useSelector((state) => state.role);
  let permissions =
    userEntitlement && userEntitlement.find((x) => x.route == props.match.path);

  const contact = {
    widgetType: "updateBranch",
    formSchema: branchSchema,
    uiSchema: branchUiSchema,
    formData: contactFormData,
    apiURL: config.teleportal.submitForm.Update,
    payloadSchema: {},
    entityType: "contacts",
    formUischema: "",
    button1Label: "Save",
    button2Label: "Go Back",
    payloadSubmit: BranchPayload,
    contactId: contactid,
    goBackPage: "/profile",
    historyUrl: "",
    recordId: "",
    button2GoBack: true,
  };

  const [contactOptions, setContactOptions] = useState(contact);

  useEffect(() => {
    if (contactid.length > 0) {
      getContactDetails();
    }
  }, [contactid]);

  const updateFormData = (entityType, formData) => {
    // if (
    //   userRole.name && userRole.name.includes('Individual') &&
    //   permissions &&
    //   permissions.permissionsets != null &&
    //   permissions.permissionsets.length > 0 &&
    //   permissions.permissionsets[0] != null &&
    //   permissions.permissionsets[0].canwrite == '0' &&
    //   permissions.permissionsets[0].cancreate == '0'
    // ) {
    //   contact.formSchema.properties.emailaddress1.isReadonly = true
    //   contact.formSchema.properties.emailaddress2.isReadonly = true
    // }
    contact.formData = formData;
    contact.initialFormData = formData;
    setContactOptions(contact);
    setContactFormData(formData);
  };

  const getContactDetails = () => {
    let api;
    let payloads;
    const formData = {};
    api = config.teleportal.submitForm.getId;

    payloads = {
      properties: ["_ctpt_branchid_value"],
      formattedFields: ["_ctpt_branchid_value"],
      recordType: "contacts",
      filters: [
        {
          field: "contactid",
          searchvalue: contactid,
          type: "guid",
        },
      ],
      FieldAndOperator: "true",
      idfield: "_ctpt_branchid_value",
    };

    post(api, payloads)
      .then((res) => {
        res.data.data.forEach((x) => {
          formData[x.name] = x.id;
        });
        updateFormData({}, formData);
      })
      .catch(console.log);
  };

  const getResponse = (res) => {
    if (res.data.id) {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Your Branch is Updated successfully",
          code: "",
          display: "show",
          severity: "success",
        },
      });
    } else {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Branch update failed",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    }
  };

  return (
    <Grid container spacing={0} justify="center">
      <Grid item xs={12} md={9} spacing={10}>
        <StyledFormWidget
          options={contactOptions}
          updateFormData={updateFormData}
          successResponse={getResponse}
        />
      </Grid>
    </Grid>
  );
}

export default BranchUpdate;
